import { createAsyncThunk } from "@reduxjs/toolkit";
import api from '../services/api'
import { baseUrlDexCalendar } from "../../constants";

export const calendarDetails = createAsyncThunk(
  "calendarDetails", 
  async (data:any, { rejectWithValue }) => {    
    try {

      const usergroup_url = baseUrlDexCalendar+`/api/v1/calendar/user-groups`    
      const eventtype_url = baseUrlDexCalendar+`/api/v1/calendar/event-type`;
      const location_url = baseUrlDexCalendar+`/api/v1/calendar/location/list`;
      try {
        const user_response = await api.get(usergroup_url);
        const event_response = await api.get(eventtype_url);
        const location_response = await api.get(location_url);

        const result = {userGroups :user_response.data,eventType :event_response.data ,listOfCountries : location_response.data};
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }    
    }
    catch(er){
      console.log(er)
      return rejectWithValue(er);
    }
    
  }
 );

//dropdown for eventtype
export const eventType = createAsyncThunk(
    "eventType",
    async (data: any, { rejectWithValue }) => {
      console.log("get api called")
      try {
        const url = baseUrlDexCalendar+`/api/v1/calendar/event-type`;
  
        let response: any;
        response = await api.get(url, {});
        const result = response.data;
        console.log("response", result);
        return { ...result };
      } catch (er) {
        console.log(er);
        return rejectWithValue(er);
      }
    }
  );
  

  //List of countries
  export const listOfCountries = createAsyncThunk(
    "listOfCountries",
    async (data: any, { rejectWithValue }) => {
      try {
        const url = baseUrlDexCalendar+`/api/v1/calendar/location/list`;
        try {
          let response: any;
          response = await api.get(url);
          const result = response?.data;
          console.log("response", result);
          return { ...result };
        } catch (error) {
          return rejectWithValue(error);
        }
      } catch (er) {
        console.log(er);
        return rejectWithValue(er);
      }
    }
  );