import React from "react";

export const ExpandIcon = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={25}
    fill="none"
  >
    <path
      fill="#0642B5"
      d="M11.472 15.7a.951.951 0 0 1-.71-.31l-4.561-4.76a.778.778 0 0 1 0-1.06.702.702 0 0 1 1.015 0l4.256 4.44 4.312-4.5a.702.702 0 0 1 1.016 0c.278.29.278.77 0 1.06l-4.62 4.82a.951.951 0 0 1-.708.31Z"
    />
  </svg>
  );
};
