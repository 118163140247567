import React, { useState } from "react";
import "./SubmittedGoal.css";
import { Rate } from "antd";

import UpArrow from "../../../themes/assets/images/svg/UpArrow.svg";
import DownArrow from "../../../themes/assets/images/svg/DownArrow.svg";
import WaitingASPImage from "../../../themes/assets/images/ASPWaiting.png";
import AvatarProfile from "../../Avatar/AvatarProfile";
import EvaluationCard from "../ASPForm/GoalEvaluationCard";
import { EMPTY_REVIEW, WAITING_ASP_TEXT } from "../../../constants";

import StarOutline from "../../../themes/assets/images/svg/startOutline.svg";
import StarFilled from "../../../themes/assets/images/svg/StarFilled.svg";
import EmptyReviewImg from "../../../themes/assets/images/empty-review.png";

type TestProps = {
  value?: number;
  aditionProps?: any;
};

export const RateComponent = (props: TestProps) => {
  const getCharacter = (value) => {
    if (value?.index >= value?.value) return <img src={StarOutline} />;
    return <img src={StarFilled} />;
  };

  return (
    <Rate
      className={"custom-rating"}
      defaultValue={props?.value ?? 0}
      character={getCharacter}
      disabled={true}
      {...props?.aditionProps}
    />
  );
};

export const WaitingASP = () => {
  return (
    <div className="review-comment-card center-contents-waiting-card">
      <img
        alt="waiting-for-manager"
        src={WaitingASPImage}
        className="waiting-asp-image"
      />
      <div className="waiting-asp-review-text">{WAITING_ASP_TEXT}</div>
    </div>
  );
};

export const EmptyReview = () => {
  return (
    <div className="review-comment-card center-contents-waiting-card">
      <img
        alt="waiting-for-manager"
        src={EmptyReviewImg}
        className="waiting-asp-image"
      />
      <div className="waiting-asp-review-text">{EMPTY_REVIEW}</div>
    </div>
  );
};

const SubmittedGoal = ({ data, profileData, otherProfileData }) => {
  const [showGoalDetails, setShowGoalDetails] = useState(false);

  const toggleGoalDetails = () => {
    setShowGoalDetails(!showGoalDetails);
  };

  return (
    <div className="submitted-goal-asp-form">
      <div className="submitted-goal-header">
        <div>
          <div className="goal-type">{data?.category}</div>

          <div className="goal-title">{data?.goalName}</div>
        </div>

        <div onClick={toggleGoalDetails} className="toogle-goal-details">
          <div className="text-toggle">
            {showGoalDetails ? "Hide Goal Details" : "Show Goal Details"}
          </div>

          <img
            className="toggle-icon"
            src={showGoalDetails ? UpArrow : DownArrow}
          />
        </div>
      </div>
      <div className="feedback-asp-view">
        <div className="review-comment-card">
          <div
            className="comment-desc"
            dangerouslySetInnerHTML={{
              __html: data?.selfAssessment?.assessmentComment?.comment,
            }}
          />
          <div className="rating-container-launch">
            <RateComponent value={data?.selfAssessment?.rateScale?.rating} />
            <div className="profile-checkin">
              <div className="avatar-container">
                <AvatarProfile
                  name={profileData?.userName}
                  size={32}
                  profilePic={
                    profileData?.profilePictureImage &&
                    `data:image/png;base64,${profileData?.profilePictureImage}`
                  }
                />
              </div>

              <div className="userData-checkin">
                <div className="name-checkin">{profileData?.userName}</div>
                <div className="designation-checkin">
                  {profileData?.designation}
                </div>
              </div>
            </div>
          </div>
        </div>
        {data?.otherAssessment ? (
          <div className="review-comment-card">
            <div
              className="comment-desc"
              dangerouslySetInnerHTML={{
                __html: data?.otherAssessment?.assessmentComment?.comment,
              }}
            />
            <div className="rating-container-launch">
              <RateComponent value={data?.otherAssessment?.rateScale?.rating} />
              <div className="profile-checkin">
                <div className="avatar-container">
                  <AvatarProfile
                    name={otherProfileData?.userName}
                    size={32}
                    profilePic={
                      otherProfileData?.profilePictureImage &&
                      `data:image/png;base64,${otherProfileData?.profilePictureImage}`
                    }
                  />
                </div>

                <div className="userData-checkin">
                  <div className="name-checkin">
                    {otherProfileData?.userName}
                  </div>
                  <div className="designation-checkin">
                    {otherProfileData?.designation}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <WaitingASP />
        )}
      </div>
      {showGoalDetails && (
        <div className="goal-evalution-list-asp-waiting ">
          <EvaluationCard title={"Goal Name"} value={data?.goalName} />
          <EvaluationCard title={"Metric"} value={data?.metric} />
          <EvaluationCard title={"Start Date"} value={data?.startDate} />
          <EvaluationCard title={"Due Date"} value={data?.endDate} />
          <EvaluationCard title={"Status"} value={data?.goalStatus} />
          <EvaluationCard title={"Weight"} value={data?.weight + " " + "%"} />
          <EvaluationCard
            title={"Goal Description"}
            value={data?.goalDescription}
          />
        </div>
      )}
    </div>
  );
};

export default SubmittedGoal;
