import React from "react";
import "./contribute.css";
import profileOne from "../../../themes/assets/images/contributor/profile-one.png";
import profileTwo from "../../../themes/assets/images/contributor/profile-two.png";
import profileThree from "../../../themes/assets/images/contributor/profile-three.png";
import { Avatar, Button, Tooltip } from "antd";
import rightArrow from "../../../themes/assets/images/contributor/right_btn.svg";
import { useNavigate } from "react-router-dom";
import n from "../../navigation/Route.Names";
import { UserOutlined } from "@ant-design/icons";
import AvatarProfile from "../../Avatar/AvatarProfile";
import { VIEW_GOAL_URL } from "../../../constants";
import amplitude from "amplitude-js";

const ContributeCard = ({ contributor }) => {
  const navigate = useNavigate();
  const contributorImage = contributor?.contributors;
  return (
    <div className="goals_text">
      <div className="goals_text-title">{contributor?.goalName}</div>
      <div className="goals_text-designation">
        {contributor?.owner?.designation} -{" "}
        <span>{contributor?.owner?.userName}</span>
      </div>
      <div className="contribut-pf-otr">
        <div className="contributor-profile">
          {contributorImage && contributorImage?.length > 0 ? (
            <div>
              <span className="contribute_title">Contributors</span>
              <div>
                {contributorImage
                  ?.slice(0, 3)
                  ?.map((item) =>
                    item?.profilePictureImage ? (
                      <img
                        src={`data:image/jpeg;base64,${item?.profilePictureImage}`}
                        key={item?.id}
                        alt="Profile Picture"
                      />
                    ) : (
                      <AvatarProfile size={40} name={item?.userName} />
                    )
                  )}
                {contributorImage?.length > 3 && (
                  <Tooltip
                    overlayClassName="tooltip-profile-otr"
                    title={
                      <div className="tooltip-profile">
                        {contributorImage
                          ?.slice(3)
                          ?.map((item) =>
                            item?.profilePictureImage ? (
                              <img
                                src={`data:image/jpeg;base64,${item?.profilePictureImage}`}
                                key={item?.id}
                                alt="Profile Picture"
                              />
                            ) : (
                              <AvatarProfile size={40} name={item?.userName} />
                            )
                          )}
                      </div>
                    }
                  >
                    <div className="avatar-with-count">
                      +{contributorImage?.length - 3}
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          ) : null}
        </div>
        <Button
          onClick={() => {
            amplitude.getInstance().logEvent("Selected Goal To Contribute");
            navigate(
              `/${n.PERFORMANCE_URL}/${VIEW_GOAL_URL}/2024/public-${contributor?.publicGoalId}`
            );
          }}
        >
          Contribute
          <img src={rightArrow} />
        </Button>
      </div>
    </div>
  );
};
export default ContributeCard;
