import React, { useEffect, useState } from "react";
import { Button, Modal, Radio, Spin } from "antd";
import {
  AnnualYear,
  getCopyGoalPlanYears,
} from "../../../redux/actions/performance";
import "./GoalPlanSelection.css";
import {
  CANCEL,
  MY_SPACE,
  NEXT,
  NO_GOLA_PLAN_DEC,
  SELECT_GOAL_PLAN,
  SELECT_GOAL_PLAN_DEC,
} from "../../../constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import { useNavigate } from "react-router-dom";
import NoGoalPlanImg from "../../../themes/assets/images/performance/NoGoalPlan.png";

const GoalPlanSelection = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    isLoadingCopyGoals: isLoading,
    configuration,
    copyGoalPlanYear,
  } = useAppSelector((state: any) => state?.performance);
  const [selectedYearPlan, setSelectedYearPlan] = useState<any>("");

  useEffect(() => {
    dispatch(getCopyGoalPlanYears({}));
  }, []);

  const onClickGoal = (type) => {
    props?.onClickGoal(type);
  };

  const onClickNext = () => {
    navigate(`/web/my_space/performance/goalPlans/${selectedYearPlan?.year}`);
  };

  return (
    <>
      <Modal
        open={props?.onClickOpen}
        style={{ top: "30vh", justifyContent: "center" }}
        closeIcon={false}
        footer={false}
        onCancel={props?.onClickCancel}
        maskClosable={true}
        className="goalplan-selection-container"
      >
        <div className="header-goal-selection">{SELECT_GOAL_PLAN}</div>
        <div className="sub-header-goal-selection">{SELECT_GOAL_PLAN_DEC}</div>
        <div style={{ flexDirection: "row" }}>
          <Spin spinning={isLoading}>
            {copyGoalPlanYear?.appraisalYears ? (
              !isLoading &&
              Object?.keys(copyGoalPlanYear?.appraisalYears).map(
                (item, index) => {
                  return (
                    <div className="sub-header-goal-selection">
                      <Radio
                        key={index}
                        checked={selectedYearPlan?.index === index}
                        onChange={(e) =>
                          setSelectedYearPlan({ index: index, year: item })
                        }
                      >
                        {copyGoalPlanYear?.appraisalYears?.[item]}
                      </Radio>
                    </div>
                  );
                }
              )
            ) : (
              <div className="nogoal-img-container">
                <img className="nogoal-plan-img" src={NoGoalPlanImg} />
                <div className="nogoal-text">{NO_GOLA_PLAN_DEC}</div>
              </div>
            )}
          </Spin>
          <div
            className="goalplan-button-container"
            style={{
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Button className={"outline-button"} onClick={props?.onClickCancel}>
              {CANCEL}
            </Button>
            <Button
              onClick={onClickNext}
              className={
                selectedYearPlan
                  ? "outline-button fillbtn-next"
                  : "outline-button .fillbtn-next-inactive"
              }
              disabled={selectedYearPlan ? false : true}
            >
              {NEXT}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GoalPlanSelection;
