import { Modal, Radio, Steps } from "antd";
import { useState } from "react";
import "./SmartModal.css";
import {
  CLOSE,
  PLAY_AGAIN,
  SELECT_SMART_GOAL,
  ERROR_MSG,
  OOPS,
  CONGRATULATIONS,
} from "../../../constants";
import Error from "../../../themes/assets/images/something-went-wrong.png";
import {
  Wizard,
  WizardSuccessImg,
  WizardThumbsDown,
  WizardThumbsUp,
} from "./images";

export const SmartGoalsModal = (props: any) => {
  const {
    currentQuestion,
    totalItems,
    noOfCorrectAnswers,
    handlePlayAgain,
    openModal,
    closeModal,
    nextQuestion,
    error,
  } = props;
  const { questionDTO } = props?.data || {};
  const { optionList } = questionDTO || [];
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [wizImgInd, setWizImgInd] = useState(0);

  const onClickOption = (index: number) => {
    setSelectedOption(index);
    setWizardImg(index);
    setTimeout(() => {
      nextQuestion(
        optionList?.[index]?.optionValue?.isCorrect,
        currentQuestion
      );
      setSelectedOption(null);
      setWizImgInd(0);
    }, 1000);
  };

  const handleSelection = (index, selectedOption) => {
    if (!selectedOption) {
      onClickOption(index);
    }
  };
  const setWizardImg = (index: number) => {
    if (optionList?.[index]?.optionValue?.isCorrect === "true") {
      setWizImgInd(1);
    } else if (optionList?.[index]?.optionValue?.isCorrect === "false") {
      setWizImgInd(2);
    }
  };

  const stepsData = totalItems?.map((item: any, index) => {
    const getIcon = () => {
      if (currentQuestion < index) {
        return null;
      } else if (currentQuestion === index) {
        return (
          <div className="in-progress-step">
            <div className="inprogress-step-inner" />
          </div>
        );
      }
    };
    return {
      title: `Q${index + 1}`,
      icon: getIcon(),
    };
  });
  const SmartGameFinal = () => {
    const isTestPassed = noOfCorrectAnswers > totalItems?.length / 2;
    const getMsg = () => {
      return isTestPassed
        ? ` You gave ${noOfCorrectAnswers} correct answers. Now you have a
      pretty good understanding of SMART goals. You’re going to ace
      your goal setting!`
        : ` You answered ${noOfCorrectAnswers} questions correctly. Perhaps,
      go through the SMART framework once more and reattempt this
      test. Good luck!`;
    };
    return (
      <Modal
        open={openModal}
        onCancel={closeModal}
        title={isTestPassed ? CONGRATULATIONS : OOPS}
        footer={false}
        className="smart-goals-modal completed"
      >
        <div className="smart-goals-modal-completed">
          <div className="smart-goal-completion">{getMsg()}</div>
          <div className="smart-goal-completion-image">
            <WizardSuccessImg />
          </div>
          <div className="smart-goal-completion-buttons">
            <button
              onClick={handlePlayAgain}
              className="smart-goal-completion-playagain"
            >
              {PLAY_AGAIN}
            </button>
            <button
              onClick={props.closeModal}
              className="smart-goal-completion-close"
            >
              {CLOSE}
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  return (
    <div className="smart-goals-modal">
      {error ? (
        <Modal
          open={openModal}
          onCancel={closeModal}
          footer={false}
          className={`smart-goals-error-modal`}
        >
          <img src={Error} />
          <div style={{ fontSize: 18 }}>{ERROR_MSG}</div>
        </Modal>
      ) : currentQuestion < totalItems?.length ? (
        <Modal
          open={openModal}
          onCancel={closeModal}
          title={questionDTO?.question}
          footer={false}
          className={`smart-goals-modal ${
            currentQuestion > totalItems?.length ? "completed" : ""
          }`}
        >
          <div className="smart-goals-modal-items">
            <div className="smart-goals-modal-user-input">
              <div className="smart-goals-input">
                <div className="smart-titles">{SELECT_SMART_GOAL}</div>
                <Radio.Group value={selectedOption}>
                  {optionList?.map((item: any, index: number) => {
                    return (
                      <div
                        className="smart-goals-input-box"
                        key={index}
                        onClick={() => handleSelection(index, selectedOption)}
                        style={{
                          backgroundColor:
                            selectedOption === index
                              ? item?.optionValue?.backgroundColor
                              : "#fff",
                          border: `1px solid ${
                            selectedOption === index
                              ? item?.optionValue?.borderColor
                              : "#dbdbdb"
                          }`,
                          cursor: selectedOption ? "progress" : "pointer",
                        }}
                      >
                        <Radio
                          value={index}
                          disabled={
                            selectedOption !== null && selectedOption !== index
                          }
                        >
                          {item?.optionValue?.value}
                        </Radio>
                      </div>
                    );
                  })}
                </Radio.Group>
              </div>
              {wizImgInd === 0 && <Wizard className="smart-goals-wizard" />}
              {wizImgInd === 1 && (
                <WizardThumbsUp className="smart-goals-wizard" />
              )}
              {wizImgInd === 2 && (
                <WizardThumbsDown className="smart-goals-wizard" />
              )}
            </div>
          </div>
          <div className="ant-steps-smart">
            <Steps
              current={currentQuestion}
              labelPlacement="vertical"
              items={stepsData}
              style={{ display: "flex", justifyContent: "space-between" }}
            />
          </div>
        </Modal>
      ) : (
        <SmartGameFinal />
      )}
    </div>
  );
};
