import AuthService from "../services/auth";
import {createAsyncThunk } from '@reduxjs/toolkit'

export const callLoginSAMLApi = createAsyncThunk<any>(
    "employeeProfile/callLoginSAMLApi",   //action name
    async () => {
        const response: any = await AuthService.callSAMLAPI();
        return response;
    }
);

export const getJWTTokenForLoggedinUser = createAsyncThunk('employeeProfile/login', async (samlId: string,
    { rejectWithValue }) => {
    const response: any = await AuthService.getJWTTokenForUser(samlId);
    return response;
})