import Calendar from "react-calendar";
import Left from "../../../themes/assets/images/svg/leave_management/ChevronLeft.svg";
import Right from "../../../themes/assets/images/svg/leave_management/ChevronRight.svg";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import "./index.css";

const StaticCalendar = ({ calendarData }) => {
  function dateMatch(date: string) {
    return calendarData
      ? calendarData.find((item: any) => item?.dateList?.includes(date))
          ?.workflowSteps?.colorCode
      : false;
  }
  return (
    <div>
      <Calendar
        maxDetail="month"
        tileContent={({ activeStartDate, date, view }) => {
          const colorCode = dateMatch(
            moment(date.toLocaleDateString()).format("YYYY-MM-DD")
          );
          return view === "month" && colorCode ? (
            <div
              className="leave-request-card-legend-color"
              style={{ backgroundColor: colorCode }}
            ></div>
          ) : null;
        }}
        minDetail="month"
        next2Label={null}
        prev2Label={null}
        nextLabel={<img src={Right} />}
        prevLabel={<img src={Left} />}
        tileDisabled={({ activeStartDate, date, view }) =>
          date.getDay() === 6 || date.getDay() === 0
        }
      />
    </div>
  );
};

export default StaticCalendar;
