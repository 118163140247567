import "./profile.css";
import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";
import { getUserCardData } from "../../../../../redux/actions/gamification";
import BadgesEmpty from "../../../../../themes/assets/images/svg/badges-nothing.svg";
import GameBadgeOne from "../../../../../themes/assets/images/svg/badge-one.svg";
import GameBadgeTwo from "../../../../../themes/assets/images/svg/badge-two.svg";
import GameBadgeThree from "../../../../../themes/assets/images/svg/badge-three.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { formatBadgeName } from "../../../../utils/util";
import { formatBadgesDate } from "../../../../utils/util";
import {
  NO_BADGES_ACHEIVED,
  SLICE_ARRAY_END,
  SLICE_ARRAY_START,
} from "../../../../../constants";

const ProfileBadge = ({ badgesData }) => {
  const achievedBadgesLength = () => {
    if (badgesData?.length > 0)
      return badgesData?.slice(SLICE_ARRAY_START, SLICE_ARRAY_END);
  };

  return (
    <div className="badges_cont-otr">
      {achievedBadgesLength() ? (
        badgesData
          ?.slice(SLICE_ARRAY_START, SLICE_ARRAY_END)
          ?.map((item, index) => (
            <div className="badges_list" key={item?.id}>
              <div className="badge_image">
                <LazyLoadImage
                  className="badge_img"
                  src={item?.badgeurl}
                  alt=""
                  effect="blur"
                />
              </div>
              <span>{item.badgeName}</span>
              <h4>{formatBadgeName(item?.gameName)}</h4>
              <span className="date">
                {formatBadgesDate(item?.acquiredDate)}
              </span>
            </div>
          ))
      ) : (
        <div className="empty-badges-profile">
          <img src={BadgesEmpty} alt="" />
          <div>{NO_BADGES_ACHEIVED}</div>
        </div>
      )}
    </div>
  );
};

export default ProfileBadge;
