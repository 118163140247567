import React, { useEffect } from "react";
import "./index.css";
import { OPPORTUNITIES } from "../../constants";
import { useNavigate } from "react-router-dom";
import Location from "../../themes/assets/images/Location.png";
import Job from "../../themes/assets/images/Job.png";
import Time from "../../themes/assets/images/Time.png";
import NoJobs from "../../themes/assets/images/noJobs.png";
import { Spin } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
import { getJobOpportunitiesList } from "../../redux/actions/opportunities";
import { saveActiveJob } from "../../redux/reducers/opportunities";
import { getTimeAgo } from "../utils/util";

export const InfoCard = ({ icon, title, bgColor = "" }) => {
  return (
    <div
      className="info-card"
      style={{ backgroundColor: bgColor ? bgColor : "" }}
    >
      <img src={icon} alt="" className="info-icon" />
      <div className="info-title">{title}</div>
    </div>
  );
};

const OpportunityCard = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClickJob = () => {
    dispatch(saveActiveJob(data));
    navigate("/web/my_work/opportunities/detail");
  };

  return (
    <div onClick={onClickJob} className="opportunity-card">
      <div className="info-container">
        <div className="logo-container">
          <img src={data?.account_icon_url} alt="" className="logo-company" />
        </div>
        <div className="job-details">
          <div className="job-title">{data?.job_title}</div>
          <div className="job-desc">{data?.capability_centre}</div>
        </div>
      </div>
      <div className="info-container info-cards-gap">
        <InfoCard icon={Location} title={data?.job_location} />
        <InfoCard icon={Job} title={data?.account} />
        <InfoCard icon={Time} title={getTimeAgo(data?.job_posting_date)} />
      </div>
    </div>
  );
};

const EmptyData = () => {
  return (
    <div className="empty-data-card-holder">
      <div className="empty-data-opportunity">
        <img src={NoJobs} alt="" className="empty-data-img" />
        <div className="empty-data-title">No Jobs To Show</div>
        <div className="empty-data-desc">
          Please upload your resume to find matching job opportunities.
        </div>
      </div>
    </div>
  );
};

const Opportunities = () => {
  const dispatch = useAppDispatch();
  const { loading, error, jobsData } = useAppSelector(
    (state: any) => state?.opportunities
  );

  useEffect(() => {
    dispatch(getJobOpportunitiesList({}));
  }, []);

  return (
    <div className="opportunities-page">
      <div className="opportunities-title">{OPPORTUNITIES}</div>
      <Spin spinning={loading}>
        <div
          className={
            loading
              ? "ooprtunities-list spinner-opportunity"
              : "ooprtunities-list"
          }
        >
          {jobsData?.map((item, index) => {
            return <OpportunityCard data={item} />;
          })}
          {jobsData?.length <= 0 && <EmptyData />}
        </div>
      </Spin>
    </div>
  );
};

export default Opportunities;
