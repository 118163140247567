import { DONE_STATUS } from "../../../constants";
import AvatarProfile from "../../Avatar/AvatarProfile";
import { RateComponent, WaitingASP } from "./SubmittedGoal";
import "./SubmittedGoal.css";
import React from "react";

const SignatureCard = ({ data }) => {
  const userSignCompleted =
    data?.userSignatureStatus?.status === DONE_STATUS ? true : false;
  const otherUserSignCompleted =
    data?.otherUserSignatureStatus?.status === DONE_STATUS ? true : false;
  return (
    <div className="submitted-goal-asp-form">
      <div className="goal-title">{data?.title}</div>
      <div className="feedback-asp-view">
        <div
          className={
            data?.userSignatureStatus?.status === DONE_STATUS
              ? "review-comment-card-signed"
              : "review-comment-card-signature"
          }
        >
          <div
            className={
              data?.otherUserSignatureStatus?.status === DONE_STATUS
                ? "comment-desc"
                : "signature-pending"
            }
          >
            {userSignCompleted
              ? `${data?.userSignatureStatus?.signedBy
                  ?.split(" ")
                  ?.slice(0, 1)
                  ?.join(" ")} signed on ${
                  data?.userSignatureStatus?.signedDate
                } `
              : data?.userSignatureStatus?.placeHolder}
          </div>
          <div className="profile-checkin">
            <div className="avatar-container">
              <AvatarProfile
                name={data?.userInfo?.userName}
                size={32}
                profilePic={
                  data?.userInfo?.profilePictureImage &&
                  `data:image/png;base64,${data?.userInfo?.profilePictureImage}`
                }
              />
            </div>
            <div className="userData-checkin">
              <div className="name-checkin">{data?.userName}</div>
              <div className="designation-checkin">{data?.designation}</div>
              <div className="name-checkin">{data?.userInfo?.userName}</div>
              <div className="designation-checkin">
                {data?.userInfo?.designation}
              </div>
            </div>
          </div>
        </div>
        {data?.otherUserSignatureStatus ? (
          <div
            className={
              data?.otherUserSignatureStatus?.status === DONE_STATUS
                ? "review-comment-card-signed"
                : "review-comment-card-signature"
            }
          >
            <div
              className={
                data?.otherUserSignatureStatus?.status === DONE_STATUS
                  ? "comment-desc"
                  : "signature-pending"
              }
            >
              {otherUserSignCompleted
                ? `${data?.otherUserSignatureStatus?.signedBy
                    ?.split(" ")
                    ?.slice(0, 1)
                    ?.join(" ")} signed on ${
                    data?.otherUserSignatureStatus?.signedDate
                  } `
                : data?.otherUserSignatureStatus?.placeHolder || ""}
            </div>
            <div className="profile-checkin">
              <div className="avatar-container">
                <AvatarProfile
                  name={data?.otherUserInfo?.userName}
                  size={32}
                  profilePic={
                    data?.otherUserInfo?.profilePictureImage &&
                    `data:image/png;base64,${data?.otherUserInfo?.profilePictureImage}`
                  }
                />
              </div>
              <div className="userData-checkin">
                <div className="name-checkin">{data?.userName}</div>
                <div className="designation-checkin">{data?.designation}</div>
                <div className="name-checkin">
                  {data?.otherUserInfo?.userName}
                </div>
                <div className="designation-checkin">
                  {data?.otherUserInfo?.designation}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <WaitingASP />
        )}
      </div>
    </div>
  );
};

export default SignatureCard;
