import "./profile.css";
import GameTrophy from "../../../../../themes/assets/images/svg/game-trophy.svg";
import GameNoData from "../../../../../themes/assets/images/svg/game_no-data.svg";
import GameReward from "../../../../../themes/assets/images/svg/game-reward.svg";

const ProfileRewards = ({ RewardsData }) => {
  return (
    <div className="game_reward-pts">
      <div className="game_reward-list">
        <img src={GameTrophy} alt="gametrophy" />
        <span>{RewardsData?.globalRank}</span>
      </div>
      <div className="game_reward-list">
        <div className="profile_gameclub_size">
          <img
            className="profile_club_leaderboard"
            src={RewardsData?.clubimg ? RewardsData?.clubimg : GameNoData}
            alt="gameemptydata"
          />
        </div>
        <span className="profile_clubname_leaderboard">
          {RewardsData?.clubname}
        </span>
      </div>
      <div className="game_reward-list">
        <img src={GameReward} alt="gamereward" />
        <span>
          <b>{RewardsData?.displayPoints}</b> pts
        </span>
      </div>
    </div>
  );
};

export default ProfileRewards;
