import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import n from "../../../navigation/Route.Names";
import EmptyGoal from "../../../../themes/assets/images/svg/empty-goals.svg";
import AaccessTime from "../../../../themes/assets/images/svg/front/active_access_time.svg";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  Button,
  Dropdown,
  Menu,
  Space,
  Spin,
  Table,
  TableColumnsType,
} from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import { ExpandableConfig } from "rc-table/lib/interface";
import MenuIcon from "../../../../themes/assets/images/svg/menu-icon.svg";
import ExpandLess from "../../../../themes/assets/images/svg/Expand_less.svg";
import ExpandMore from "../../../../themes/assets/images/svg/Expand_more-black.svg";
import {
  GOAL_ACTIVITY_SUB_TITLE,
  GOAL_MAIN_PAGE_SUBTITLE1,
  GOAL_MAIN_PAGE_SUBTITLE2,
  GOAL_MAIN_PAGE_NOGOAL,
  VIEW_GOAL,
  PREVIEW_COMMENT_TITLE,
  TOTAL_WEIGHT,
  MILESTONE_DESCRIPTION_LABEL,
  DUE_DATE_LABEL,
  ACTUAL_DATE_LABEL,
  PERFORMANCE,
  COMMENTS_MANAGER,
  COMMENTS_MANAGER_TITLE,
  REMAINING_TXT,
  CLOSE,
  SEND,
  TEAM_PERFORMANCE,
  MANAGER_CMNT_ADDED_TOAST,
} from "../../../../constants";
import { setGoalData } from "../../../../redux/reducers/performance";
import CommentDialog from "../../components/CommentDialog";
import { constant, isEmpty } from "lodash";
import TextArea from "antd/es/input/TextArea";
import {
  previewAction,
  viewState,
} from "../../../../redux/reducers/performance";
import { saveGoalComment } from "../../../../redux/actions/performance";
import amplitude from "amplitude-js";

interface DataType {
  goalId: number;
  goalName: any;
  goalDescription: string;
  startDate: string;
  endDate: string;
  outcome: string;
  weight: string;
  goalStatus: any;
}

interface ExpandedDataType {
  key: React.Key;
  target_date: string;
  actual_date: string;
  name: string;
}

const renderStatusButton = (goalStatus, record) => {
  const { goalStatusColor, goalTextColor } = record || {};
  return (
    <Button style={{ backgroundColor: goalStatusColor, color: goalTextColor }}>
      {goalStatus}
    </Button>
  );
};

const renderActionsMenu = (goal, goalView, commentView, index) => (
  <Menu>
    <Menu.Item key="view" onClick={() => goalView({ ...goal, index })}>
      {VIEW_GOAL}
    </Menu.Item>
    <Menu.Item key="comment" onClick={() => commentView({ ...goal, index })}>
      {PREVIEW_COMMENT_TITLE}
    </Menu.Item>
  </Menu>
);

const GoalSectionView = ({ sectionData, tableColumns, sectionTitle }) => {
  const defaultExpandable = {
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <img
          src={ExpandLess}
          onClick={(e) => onExpand(record, e)}
          className="expand"
          alt="expand"
        />
      ) : (
        <img
          src={ExpandMore}
          onClick={(e) => onExpand(record, e)}
          className="expand"
          alt="expand"
        />
      ),
    expandedRowRender: (record: any) => {
      const columns: TableColumnsType<ExpandedDataType> = [
        {
          title: "Milestones",
          dataIndex: "description",
          key: "description",
          render: (record) => (
            <div className="inner-table-milestone">
              <span>{record}</span>
            </div>
          ),
        },
        {
          title: "Target Date",
          dataIndex: "formattedDueDate",
          key: "formattedDueDate",
        },
        {
          title: "Actual Date",
          dataIndex: "formattedActualDate",
          key: "formattedActualDate",
        },
        {
          title: "Status",
          dataIndex: "milestoneStatus",
          key: "milestoneStatus",
          render: (status, milestone: any) => (
            <Space size="middle">
              <div
                className="milestone-status"
                style={{ color: `${milestone?.milestoneColor}` }}
              >
                {status}
              </div>
            </Space>
          ),
        },
      ];

      return (
        <Table
          columns={columns}
          dataSource={record?.milestones}
          pagination={false}
          className="tbl-inner-section"
        />
      );
    },
  };

  const [expandable, setExpandable] = useState<
    ExpandableConfig<DataType> | undefined
  >(defaultExpandable);

  const tableProps: TableProps<DataType> = {
    bordered: false,
    showHeader: true,
    pagination: false,
    expandable,
  };

  if (sectionData?.length <= 0) return null;

  return (
    <div className="business">
      <h5 className="title">{sectionTitle}</h5>
      <Table
        {...tableProps}
        columns={tableColumns}
        dataSource={sectionData}
        className="tbl-outer-section"
      />
    </div>
  );
};

const EmptyGoalView = () => {
  return (
    <div className="empty-goals">
      <img src={EmptyGoal} alt="" />
      <span>{GOAL_MAIN_PAGE_NOGOAL}</span>
    </div>
  );
};

const ManagerGoals = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const managerView = param?.page === TEAM_PERFORMANCE;
  const { getEmployeeFilteredData, isLoading, goalValues } =
    useAppSelector((state: any) => state.performance) || [];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const remainingCharacters = 300 - Number(comment?.length || 0);
  const [viewer, setViewer] = useState(false); //manger=false or user=true
  const [goalSelected, setGoalSelected] = useState<any>({});
  const [viewCmnt, setViewCmnt] = useState<any>(false);

  const goalView = (goal) => {
    dispatch(setGoalData(goal));
    dispatch(previewAction({ viewGoal: goal }));
    navigate(
      `/${n.START}/${n.MYWORK_URL}/${TEAM_PERFORMANCE}/detail/${props?.reporteeId}/previewgoal/${props.year}`,
      {
        state: {
          data: goal,
          isPreview: true,
          manager: managerView,
          isNew: false,
          goalsTabKey: props.goalsTabKey,
          userName: props.userName,
          reporteeId: props.reporteeId,
        },
      }
    );
  };

  const commentView = (goal) => {
    setIsModalOpen(true);
    setGoalSelected(goal);
    setViewCmnt(goal?.commentInfo ? true : false);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Goal Name & Description",
      dataIndex: ["goalName", "goalDescription"],
      render: (text, record: any) => (
        <>
          <div
            className={
              record?.cascadeOpen
                ? "cascaded-by-area"
                : record?.cascadedBy !== null
                ? "cascaded-by-area"
                : record?.ownerId !== null
                ? "cascaded-by-area contribute-by-area"
                : ""
            }
          >
            {record.cascadeOpen
              ? `Cascaded by ${record.cascadedBy}`
              : record.cascadedBy !== null
              ? `Cascaded by ${record.cascadedBy}`
              : record.ownerId !== null
              ? `Contributed to ${record.ownerName}`
              : ""}
          </div>
          <div className="inner-table-milestone">
            <span className="title-mile">{record["goalName"]}</span>
            <span className="cont-mile">{record["goalDescription"]}</span>
          </div>
        </>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
    },
    {
      title: "Due Date",
      dataIndex: "endDate",
    },
    {
      title: "Outcomes",
      dataIndex: "outcome",
    },
    {
      title: "Weightage",
      dataIndex: "weight",
    },
    {
      title: "Status",
      dataIndex: "goalStatus",
      render: (record, item) => renderStatusButton(record, item),
    },
    {
      title: "Action",
      key: "action",
      render: (record, data, index) => {
        const { key = "" } = record;
        return (
          <Space size="middle">
            <Dropdown
              overlayClassName="goals"
              overlay={() =>
                renderActionsMenu(record, goalView, commentView, index)
              }
              trigger={["click"]}
            >
              <img src={MenuIcon} alt="MenuIcon" />
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const handleSaveComment = () => {
    const { userId, goalId, sfGoalId, index, category } = goalSelected || {};
    const year = props?.year;
    setIsModalOpen(false);
    dispatch(
      saveGoalComment({
        year,
        userId,
        payload: {
          comment,
        },
        goalId,
        sfGoalId,
        index,
        category,
      })
    )
      .unwrap()
      .then((data) => {
        navigate(`/web/my_work/${"team_performance"}/detail/${userId}`, {
          state: { performanceTabKey: "2" },
        });
        amplitude.getInstance().logEvent("Add Comment Manager");
        setComment("");
      });
  };

  const handleEdit = () => {
    setComment(goalSelected?.commentInfo?.comment);
    setViewCmnt(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const tableColumns = columns.map((item) => ({ ...item }));

  return (
    <>
      <Spin spinning={isLoading}>
        <div>
          <p>
            <span>{TOTAL_WEIGHT}</span>{" "}
            {getEmployeeFilteredData?.goalSettingInfo?.totalWeight <= 100 ? (
              <span className="total-weight-green">
                {" "}
                {getEmployeeFilteredData?.goalSettingInfo?.totalWeight}%
              </span>
            ) : (
              <span className="total-weight-red">
                {" "}
                {getEmployeeFilteredData?.goalSettingInfo?.totalWeight}%
              </span>
            )}
          </p>
          <GoalSectionView
            tableColumns={tableColumns}
            sectionData={getEmployeeFilteredData?.business}
            sectionTitle={GOAL_ACTIVITY_SUB_TITLE}
          />
          <GoalSectionView
            tableColumns={tableColumns}
            sectionData={getEmployeeFilteredData?.technology}
            sectionTitle={GOAL_MAIN_PAGE_SUBTITLE1}
          />
          <GoalSectionView
            tableColumns={tableColumns}
            sectionData={getEmployeeFilteredData?.people}
            sectionTitle={GOAL_MAIN_PAGE_SUBTITLE2}
          />
          {getEmployeeFilteredData?.people?.length <= 0 &&
            getEmployeeFilteredData?.business?.length <= 0 &&
            getEmployeeFilteredData?.technology?.length <= 0 && (
              <EmptyGoalView />
            )}
        </div>
      </Spin>

      <CommentDialog
        openModal={isModalOpen}
        successHeading={PERFORMANCE}
        DialogHeading={viewCmnt ? "View Comment" : "Add Comment"}
        closeIcon={true}
        isEdit={viewCmnt}
        editAction={handleEdit}
        editBtn={"Edit"}
        goalValues={goalSelected}
        modalImageStyles={{ display: "none" }}
        msgContent={[
          <React.Fragment key="comment">
            <div>{COMMENTS_MANAGER_TITLE}</div>
            <TextArea
              rows={4}
              disabled={false} //viewer
              readOnly={viewCmnt}
              placeholder={viewCmnt ? goalSelected?.commentInfo?.comment : ""}
              value={viewCmnt ? goalSelected?.commentInfo?.comment : comment}
              onChange={(e) => {
                e.target.value?.length <= 300 &&
                  setComment(e.target.value.replace(/\n/g, ""));
              }}
              className={viewCmnt ? "manager-cmnt-sec-input-box" : ""}
            />
            {!viewCmnt && (
              <div
                className={`${
                  !remainingCharacters ? "comment-character-count" : ""
                } remainingCharacters`}
              >
                {remainingCharacters} {REMAINING_TXT}
              </div>
            )}
          </React.Fragment>,
        ]}
        isCancel={viewer}
        okTxt={viewCmnt ? CLOSE : SEND}
        okBtn={handleSaveComment}
        onOk={handleSaveComment}
        onCancel={handleCancel}
        modalSubHeading={"commentheading"}
        modalStyles={"commentdialog"}
        modalContent={"commentview"}
        maskClosable={false}
        isSingleButton={false}
      />
    </>
  );
};

export default ManagerGoals;
