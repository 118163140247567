import {DEX_WEB_URL} from './api';
import axios from 'axios';
axios.defaults.baseURL = DEX_WEB_URL
export default {
    async callSAMLAPI() {        
        var response = await axios.get(`api/v1/saml/login/info?authType=DEX&isWeb=true`, { headers: { "isEncrypted": true } });
        return response
    },
    async getJWTTokenForUser(samlId: string) {

        let params:any = await {
            "policyName": "Employee Terms and Policy",
            "policyVersion": "V1.0",
            "deviceIMEINumber": "GL1213113EQ",
            "locationInfo": "182.234.090.233",
            "samlRefId": samlId ,  //Pass samlrefId received in earlier response.
            "isWeb" : true
        }
        var response = await axios.post(`api/v1/employees/saml/auth/session`, params, { headers: { "isEncrypted": true } });
        return response;
    },

}