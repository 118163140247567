import React from "react";

export const LastMonthIcon = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
  >
    <rect width={28} height={28} fill="#FAE6E6" rx={3.5} />
    <path
      fill="#C00"
      d="M19.374 18.064a.765.765 0 0 1-.53-.216l-5.458-5.458-1.39 1.389c-.432.44-1.149.44-1.582 0l-4.211-4.22a.744.744 0 1 1 1.053-1.052l3.95 3.95 1.388-1.389c.433-.44 1.15-.44 1.583 0l5.72 5.72a.744.744 0 0 1-.53 1.269l.007.008Z"
    />
    <mask id="a" fill="#fff">
      <path d="M19.398 18.811h-.022l-3.666-.045a.749.749 0 0 1-.74-.754c0-.41.337-.739.747-.739h.008l3.666.045v-3.77c0-.412.336-.748.747-.748.41 0 .746.336.746.747v3.77c0 .404-.157.777-.448 1.061a1.505 1.505 0 0 1-1.052.433h.014Z" />
    </mask>
    <path
      fill="#C00"
      d="M19.398 18.811h-.022l-3.666-.045a.749.749 0 0 1-.74-.754c0-.41.337-.739.747-.739h.008l3.666.045v-3.77c0-.412.336-.748.747-.748.41 0 .746.336.746.747v3.77c0 .404-.157.777-.448 1.061a1.505 1.505 0 0 1-1.052.433h.014Z"
    />
    {/* <path
      fill="#C00"
      d="m19.376 18.811-.021 1.75H19.376v-1.75Zm-3.666-.045.021-1.75H15.71v1.75Zm.015-1.493.021-1.75H15.725v1.75Zm3.666.045-.021 1.75 1.771.021v-1.771h-1.75Zm1.045 1.06 1.221 1.254-1.22-1.254Zm-1.038-1.317h-.022v3.5h.022v-3.5Zm0 0-3.667-.044-.043 3.5 3.667.044.042-3.5Zm-3.688-.045c.6 0 1.01.486 1.01.996h-3.5c0 1.331 1.067 2.504 2.49 2.504v-3.5Zm1.01.996c0 .579-.47 1.011-1.003 1.011v-3.5a2.492 2.492 0 0 0-2.496 2.49h3.5Zm-1.003 1.011h.008v-3.5h-.008v3.5Zm-.014 0 3.667.045.042-3.5-3.666-.045-.043 3.5Zm5.438-1.705v-3.77h-3.5v3.77h3.5Zm0-3.77c0 .555-.447 1.002-1.003 1.002v-3.5a2.499 2.499 0 0 0-2.497 2.497h3.5Zm-1.003 1.002a1.001 1.001 0 0 1-1.004-1.003h3.5a2.499 2.499 0 0 0-2.496-2.497v3.5Zm-1.004-1.003v3.77h3.5v-3.77h-3.5Zm0 3.77c0-.026.006-.064.023-.105.017-.04.04-.07.058-.087l2.443 2.506c.629-.613.976-1.438.976-2.313h-3.5Zm.081-.193a.247.247 0 0 1 .169-.063v3.5c.867 0 1.674-.346 2.273-.93l-2.442-2.507Zm.169 3.437h.014v-3.5h-.014v3.5Z"
      mask="url(#a)"
    /> */}
  </svg>
  );
};
