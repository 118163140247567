import { Button, Form, Input } from "antd";
import "./CommentTextArea.css";

const CommentTextArea = (props: any) => {
  const handleCommentsChange = (changedValues: any) => {
    // Update comments state when the textArea value changes
    if ("textArea" in changedValues) {
      props?.setComment(changedValues.textArea);
    }
  };

  const onFinish = (values) => {
    // Handle form submission logic here
    console.log("Received values:", values);
  };

  return (
    <Form
      className="commentTextArea-checkin"
      onFinish={onFinish}
      onValuesChange={handleCommentsChange}
      layout="vertical"
    >
      <Form.Item name="textArea" label={props?.title} required={true}>
        <Input.TextArea rows={4} placeholder={props?.placeholder} />
      </Form.Item>
      <div className="btn-holder">
        <Button
          type="primary"
          onClick={props?.onClickSubmit}
          htmlType="submit"
          disabled={!props?.comment?.trim()}
        >
          {props?.submitButtonText}
        </Button>
      </div>
    </Form>
  );
};

export default CommentTextArea;
