import { useState, useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";
import { getUserBadges } from "../../../../../redux/actions/gamification";
import "../LeaderBoardLanding/profile.css";
import { formatBadgesDate } from "../../../../utils/util";
import { LazyLoadImage } from "react-lazy-load-image-component";


const EarnedBadgeDetail = ({ data }) => {
  return (
    <div className="badges-lock_list" key={data?.badgeId} type="primary">
      <div className="badge-otr_image">
        <LazyLoadImage src={data?.badgeurl} alt="banner" effect="blur" />
      </div>
      <span>{data?.badgeName}</span>
      <h4>{formatBadgesDate(data?.acquiredDate)}</h4>
    </div>
  );
};

export default EarnedBadgeDetail;
