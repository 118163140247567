import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./certificationStyle.css";
import AddImage from "../../themes/assets/images/svg/add.svg";
import n from "../navigation/Route.Names";
import CertificateImage from "../../themes/assets/images/svg/certificate-added.svg";
import Error from "../../themes/assets/images/svg/CertificationFailure.svg";
import { Spin, notification as ToastMessage } from "antd";
import OutlineButton from "../common/button/OutlineButton";
import FillButton from "../common/button/FillButton";
import { useAppSelector, useAppDispatch } from "../../redux/store/redux-hooks";
import {
  addMore,
  closeNotification,
  reset,
} from "../../redux/reducers/certification";
import { saveCertification } from "../../redux/actions/certification";
import {
  CERTIFICATE_SUCCESSFULL,
  CERTIFICATION,
  ADD_CERTIFICATION,
  SUBMIT,
  BACK,
  SUCCESS,
  DONE,
  RETRY,
  ERROR_CERTIFICATE_CONTEND,
  CERTIFICATE_FAILURE,
  CERTIFICATE_SUCCESSTOAST_TITLE,
  CERTIFICATE_SUCCESSTOAST_DESC,
  PREVIEW,
  ALTIVERSE,
  CERTIFICATION_URL,
  ALTIVERSE_BREADCRUMP,
  START,
  ADD_CERTIFICATION_URL,
  ADD_CERTIFICATION_BREADCRUMP,
  QUIET_MESSAGE,
  PROCEED,
  CANCEL,
  QUIET_TITLE,
} from "../../constants";
import { dateFormat } from "../utils/util";
import CertificateList from "../common/CertificateList/CertificateList";
import AddCertificateButton from "../common/button/AddCertificateButton";
import ModalDialog from "../common/modal/modal";
import { ToastSuccessIcon } from "../common/svg/svg";
import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";

function Preview() {
  const Certification = useAppSelector((state: any) => state.certification);
  const { addcertifications = [], notification, loading } = Certification;
  const [isPopup, setPopup] = useState({
    isLeave: false,
    page: "",
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleOk = () => {
    dispatch(closeNotification({}));
    if (notification.status === SUCCESS) {
      navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
    }
  };

  const handleOkHandler = () => {
    setPopup({ ...isPopup, isLeave: false, page: "" });
    dispatch(reset({}));
    if (isPopup.page === ALTIVERSE) {
      navigate(`/${n.START}/${ALTIVERSE}`);
    } else if (isPopup.page === CERTIFICATION_URL) {
      navigate(`/${n.START}/${CERTIFICATION_URL}`);
    }
  };

  const handleCancel = () => {
    dispatch(closeNotification({}));
    if (notification.status === SUCCESS) {
      navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
    }
  };

  const handleCancelHandler = () => {
    setPopup({ ...isPopup, isLeave: false });
  };

  const submitAction = () => {
    const request: string[] = [];
    addcertifications.forEach((item: any) => {
      const { startDate, endDate, ...rest } = item;
      const newrecord = {
        ...rest,
        startDate: startDate ? dateFormat(startDate) : "",
        endDate: endDate ? dateFormat(endDate) : "",
      };
      request.push(newrecord);
    });
    dispatch(saveCertification(request));
  };

  // const cancelAction = () => {
  //   navigate(`/${n.START}/${n.ADD_CERTIFICATION_URL}`);
  // };

  useEffect(() => {
    if (
      addcertifications?.[0]?.name === "" &&
      addcertifications?.[0]?.institution === "" &&
      addcertifications?.[0]?.startDate === null &&
      addcertifications?.[0]?.endDate === null &&
      addcertifications?.[0]?.description === ""
    ) {
      navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
    }
  }, []);

  // const addCertificate = () => {
  //   dispatch(addMore({}));
  //   navigate(`/${n.START}/${n.ADD_CERTIFICATION_URL}`);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showInitialDiv, setShowInitialDiv] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setShowInitialDiv(window.scrollY <= 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const redirection = (e: any, page) => {
    e.preventDefault();
    if (page === ADD_CERTIFICATION_URL) {
      navigate(`/${n.START}/${ADD_CERTIFICATION_URL}`);
    } else {
      // if (page === ALTIVERSE) {
      //   navigate(`/${n.START}/${ALTIVERSE}`);
      // } else if (page === CERTIFICATION_URL) {
      //   navigate(`/${n.START}/${CERTIFICATION_URL}`);
      // }
      setPopup({ ...isPopup, isLeave: true, page: page });
    }
  };
  const items = [
    {
      title: (
        <Link
          to={`/${START}/${ALTIVERSE}`}
          onClick={(e) => redirection(e, ALTIVERSE)}
          className="link-highlight"
        >
          {ALTIVERSE_BREADCRUMP}
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/${START}/${CERTIFICATION_URL}`}
          onClick={(e) => redirection(e, CERTIFICATION_URL)}
          className="link-highlight"
        >
          {CERTIFICATION}
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/${START}/${ADD_CERTIFICATION_URL}`}
          onClick={(e) => redirection(e, ADD_CERTIFICATION_URL)}
          className="link-highlight"
        >
          {ADD_CERTIFICATION_BREADCRUMP}
        </Link>
      ),
    },
    {
      title: `${PREVIEW}`,
    },
  ];

  return (
    <>
      <div className="certify_main-otr">
        <Spin spinning={loading} delay={500}>
          <div className="certifications-header">
            {/* <div className="Seperation-page-title">{PREVIEW}</div> */}
            <div className="hidestricky_breadcrump">
              <BreadcrumbComponent items={items} />
              <div className="Seperation-page-title">{PREVIEW}</div>
            </div>
            {!showInitialDiv && (
              <div className="headingWrapper">
                <BreadcrumbComponent items={items} />
                <div className="Seperation-page-title certification-page-title">
                  {PREVIEW}
                </div>
              </div>
            )}
            {/* <div className="certification-addbutton">
              <AddCertificateButton
                addCertificateAction={addCertificate}
                addCertificateImage={AddImage}
                addCertificateTxt={ADD_CERTIFICATION}
              />
            </div> */}
          </div>
          <div className="certification-card">
            <div className="certification-cardinner">
              <CertificateList certifications={addcertifications} />
              <div className="button-section">
                {/* <OutlineButton
                  backText={BACK}
                  outlineBtnAction={cancelAction}
                /> */}
                <FillButton fillBtnTxt={SUBMIT} fillBtnAction={submitAction} />
              </div>
            </div>
          </div>
        </Spin>
        <ModalDialog
          openModal={notification.isShown}
          successHeading={
            notification.status === SUCCESS
              ? CERTIFICATE_SUCCESSFULL
              : CERTIFICATE_FAILURE
          }
          msgContent={
            notification.status === SUCCESS ? "" : ERROR_CERTIFICATE_CONTEND
          }
          okTxt={notification.status === SUCCESS ? DONE : RETRY}
          okBtn={handleOk}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={false}
          modalImage={
            notification.status === SUCCESS ? CertificateImage : Error
          }
          modalStyles={"certification-popup"}
          modalImageClasses={"certificate-image"}
        />
        <ModalDialog
          openModal={isPopup.isLeave}
          DialogHeading={QUIET_TITLE}
          closeIcon={false}
          modalImageStyles={{ display: "none" }}
          modalHeadingStyles={{ display: "none" }}
          msgContent={QUIET_MESSAGE}
          okTxt={PROCEED}
          cancelTxt={CANCEL}
          cancelBtn={handleCancelHandler}
          okBtn={handleOkHandler}
          onOk={handleOkHandler}
          onCancel={handleCancelHandler}
          modalStyles={"backpopup"}
          maskClosable={false}
        />
      </div>
    </>
  );
}

export default Preview;
