import React, { useState, useEffect } from "react";
import EmployeeCard from "../../components/EmployeeCard";
import "./index.css";
import { Input, Spin } from "antd";
import CheckinSearchIn from "../../../../themes/assets/images/performance/svg/search-Icon-checkin.svg";
import employeeDetails from "../EmployeeData.json";
import NoDataFound from "../../../../themes/assets/images/not-found.png";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import { getEmployeeListData } from "../../../../redux/actions/performance";
import { SearchIconManagerView } from "../../../common/svg/svg";
import { NO_DATA, TOTAL_EMPLOYEES_COUNT } from "../../../../constants";
import { debounce } from "lodash";

const EmployeeList = ({ flow, goalsTabKey }) => {
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [searchDetails, setSearchDetails] = useState<any>([]);
  const { getEmployeeData, isLoading } = useAppSelector(
    (state: any) => state.performance
  );
  const year = useAppSelector(
    (state: any) => state?.performance?.teamGoalActiveYear
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEmployeeListData({ year }));
  }, [year]);

  const handleSearch = (e: any) => {
    const searchValue = e.target?.value;
    if (searchValue?.length >= 3 || searchValue === "") {
      dispatch(
        getEmployeeListData({
          searchString: searchValue,
        })
      );
    }
  };

  const debounceValue = debounce(handleSearch, 1000);

  useEffect(() => {
    const employeeListSorted = employeeList.sort((a, b) =>
      a.userName.localeCompare(b.userName)
    );
    setEmployeeList(employeeListSorted);
  }, []);

  const EmployeeListNoData = () => {
    return (
      <div className="employee_list_not_found_outer_container">
        <img
          className="employee_list_not_found"
          src={NoDataFound}
          alt="No data"
        />
        <p className="no-data">{NO_DATA}</p>
      </div>
    );
  };

  return (
    <>
      <Spin spinning={isLoading}>
        {getEmployeeData && (
          <div className="employee-list-outer-container">
            <div className="topcomponents">
              <div className="search">
                <Input placeholder="Search Here" onChange={debounceValue} />
                <SearchIconManagerView />
              </div>
              <div className="contents">
                {TOTAL_EMPLOYEES_COUNT} {getEmployeeData.length}
              </div>
            </div>

            <div>
              {getEmployeeData.length === 0 && !isLoading ? (
                <EmployeeListNoData />
              ) : (
                <div className="employeeDisplay">
                  {getEmployeeData.map((item: any, index: any) => (
                    <EmployeeCard flow={flow} key={index} employee={item} goalsTabKey={goalsTabKey} />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </Spin>
    </>
  );
};
export default EmployeeList;
