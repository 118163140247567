import { useEffect, useState } from "react";
import "./index.css";
import { Modal, Steps } from "antd";
import { Cell, Pie, PieChart } from "recharts";
import closeIcon from "../../../../themes/assets/images/performance/Close.png";
import {
  COMPLETED,
  FINISHED,
  GOAL_OVERVIEW,
  IN_PROGRESS,
  NOTIFICATION,
  NOTIFICATION_TITLE,
  NO_NOTIFICATION,
  NO_NOTIFICATION_SUBHEADER,
  OF,
  PROGRESS,
  PW_NOTIFICATION_COUNT_VIEW_ALL,
  PW_VIEW_ALL,
} from "../../../../constants";
import noNotification from "../../../../themes/assets/images/performance/no-notification.png";
import infoPopup from "../../../../themes/assets/images/performance/popup-info.png";
import { useNavigate } from "react-router-dom";

const PieChartLebelCard = ({ data, getIconColor }) => {
  return (
    <div className="pie-chart-label">
      <div
        className={"dot-pie"}
        style={{ backgroundColor: getIconColor(data?.status) }}
      />
      <div className="pie-label-text">
        {data?.status} - <span>{data?.count}</span>
      </div>
    </div>
  );
};

const ActivitiesData = ({ notification }) => {
  const stepData = notification?.map((item: any) => {
    return {
      title: item?.title,
      description: item?.message,
      icon: <div className="activity-icon-step" />,
    };
  });

  if (notification?.length <= 0) {
    return (
      <div className="empty-notification">
        <img src={noNotification} alt="" className="no-notification-img" />
        <div className="no-notificaiton-title">{NO_NOTIFICATION}</div>
        <div className="no-notification-sub-title">
          {NO_NOTIFICATION_SUBHEADER}
        </div>
      </div>
    );
  }

  return (
    <div className="activities-container-data">
      <Steps current={-1} direction="vertical" items={stepData} />
    </div>
  );
};

const ManagerOverview = ({ employeeData }) => {
  const [openActivities, setOpenActivities] = useState(false);

  function convertNumber(num) {
    const numStr = num.toString();

    if (numStr.length === 2 && numStr[0] === "0") {
      return parseInt(numStr[1]);
    } else {
      return num;
    }
  }

  const getPieChartData = (currentTabData) => {
    let tempData = currentTabData?.countByStatus?.map((item, index) => {
      return {
        name: item?.status,
        value: convertNumber(item?.count),
      };
    });

    return tempData;
  };

  const getCompletedCount = (arr: any) => {
    let count = 0;
    arr?.map((item: any) => {
      if (item?.status === COMPLETED) count = item?.count;
    });
    return count;
  };

  const [activeTab, setActiveTab] = useState({
    data: employeeData?.goalsOverview?.[0],
    index: 0,
    pieChartData: getPieChartData(employeeData?.goalsOverview?.[0]),
    completedCount: getCompletedCount(
      employeeData?.goalsOverview?.[0]?.countByStatus
    ),
  });

  useEffect(() => {
    setActiveTab({
      pieChartData: getPieChartData(employeeData?.goalsOverview?.[0]),
      index: 0,
      data: employeeData?.goalsOverview?.[0],
      completedCount: getCompletedCount(
        employeeData?.goalsOverview?.[0]?.countByStatus
      ),
    });
  }, [employeeData]);

  const onClickTab = (data: any, index: any) => {
    setActiveTab({
      pieChartData: getPieChartData(data),
      data,
      index,
      completedCount: getCompletedCount(data?.countByStatus),
    });
  };

  const onClickViewAll = () => {
    setOpenActivities(true);
  };

  const onClickCloseActivities = () => {
    setOpenActivities(false);
  };

  function getPieChartColor(status: any) {
    const statusData = employeeData?.goalStatus;
    const matchingStatus = statusData?.find((item) => item?.status === status);
    return matchingStatus ? matchingStatus.color : undefined;
  }

  let currentStep = employeeData?.routeMapInfo?.length;
  const stepsData = employeeData?.routeMapInfo?.map((item: any, index) => {
    if (item?.status === IN_PROGRESS) {
      currentStep = index;
    }
    return {
      title: item?.stepName,
      description: `${item?.startDate} to ${item?.endDate}`,
      icon:
        item?.status === FINISHED ? null : item?.status === IN_PROGRESS ? (
          <div className="in-progress-step">
            <div className="inprogress-step-inner" />
          </div>
        ) : item?.status === null ? (
          <div className="not-started-step" />
        ) : null,
    };
  });
  const navigate = useNavigate();
  return (
    <div className="manager-overview-container">
      {employeeData?.button?.info && (
        <div className="year-end-popup">
          <div className="popup-title">
            <img src={infoPopup} alt="" className="popup-icon" />
            <div className="pop-up-heading">{employeeData?.button?.info}</div>
          </div>
          <div
            className="year-end-button"
            onClick={() => navigate(employeeData?.button?.deepLink)}
          >
            {employeeData?.button?.name}
          </div>
        </div>
      )}
      <div className="steps-container">
        <div className="step-container-title">{PROGRESS}</div>
        <Steps
          direction="horizontal"
          labelPlacement="vertical"
          current={currentStep}
          items={stepsData}
        />
      </div>
      <div className="overview-data-container">
        <div className="pie-chart-container">
          <div className="pie-chart-header">
            <div className="pie-chart-title">{GOAL_OVERVIEW}</div>
            <div className="tab-pie">
              {employeeData?.goalsOverview?.map((item, index, arr) => {
                if (index === 0)
                  return (
                    <div
                      onClick={() => onClickTab(item, index)}
                      className={
                        activeTab?.index === index
                          ? "tab tab-left active"
                          : "tab tab-left"
                      }
                    >{`${item?.type} (${item?.count})`}</div>
                  );
                if (index === arr?.length - 1)
                  return (
                    <div
                      onClick={() => onClickTab(item, index)}
                      className={
                        activeTab?.index === index
                          ? "tab tab-right active"
                          : "tab tab-right"
                      }
                    >{`${item?.type} (${item?.count})`}</div>
                  );
                return (
                  <div
                    onClick={() => onClickTab(item, index)}
                    className={
                      activeTab?.index === index
                        ? "tab tab-center active"
                        : "tab tab-center"
                    }
                  >{`${item?.type} (${item?.count})`}</div>
                );
              })}
            </div>
          </div>
          <div className="pie-chart-data-divider">
            <div className="pie-chart-data-container">
              <PieChart width={244} height={244}>
                <Pie
                  data={
                    activeTab?.data?.count > 0
                      ? activeTab?.pieChartData
                      : [{ name: "", value: 1 }]
                  }
                  cx={115}
                  cy={115}
                  innerRadius={80}
                  outerRadius={120}
                  fill="#F3F3F3"
                  paddingAngle={0}
                  dataKey="value"
                >
                  {activeTab?.data?.count > 0
                    ? activeTab?.data?.countByStatus?.map((entry, index) => {
                      return (
                        <Cell
                          key={`cell-${index}`}
                          fill={getPieChartColor(entry?.status)}
                        />
                      );
                    })
                    : null}
                </Pie>
              </PieChart>
              <div className="pie-chart-center-text">
                {activeTab?.completedCount} {OF} {activeTab?.data?.count}{" "}
                {COMPLETED}
              </div>
            </div>
            <div className="pie-chart-detial">
              {activeTab?.data?.countByStatus?.map((item, index) => (
                <PieChartLebelCard
                  data={item}
                  key={index}
                  getIconColor={getPieChartColor}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="activities-container">
          <div className="pie-chart-header">
            <div className="pie-chart-title">{NOTIFICATION_TITLE}</div>
            {employeeData?.notificationsMissed?.length >
              PW_NOTIFICATION_COUNT_VIEW_ALL && (
                <div onClick={onClickViewAll} className="view-all-activities">
                  {PW_VIEW_ALL}
                </div>
              )}
          </div>
          <ActivitiesData notification={employeeData?.notificationsMissed} />
        </div>
      </div>
      <Modal
        wrapClassName={"activities-modal"}
        open={openActivities}
        footer={null}
        closable={false}
        width={622}
        centered
      >
        <div className="activities-container modal-container">
          <div className="pie-chart-header">
            <div className="pie-chart-title modal-activities-title">
              {NOTIFICATION_TITLE}
            </div>
            <div
              onClick={onClickCloseActivities}
              className="view-all-activities"
            >
              <img src={closeIcon} alt="" className="close-cion" />
            </div>
          </div>
          <ActivitiesData notification={employeeData?.notificationsMissed} />
        </div>
      </Modal>
    </div>
  );
};

export default ManagerOverview;
