import { createSlice } from "@reduxjs/toolkit";
import { calendarDetails } from "../actions/calender_events";
//import { userGroups, eventType,listOfCountries,} from '../actions/calender_event';

type calenderDetailState = {
  calendarDetails :any,
  loading: boolean,
  error: null,
  hasMore:boolean,
  loadMore:boolean
  minorError : null,
};

const initialDetailState: calenderDetailState = {
  calendarDetails: {},
  loading: false,
  error: null,
  hasMore:true,
  loadMore:true,
  minorError : null,
};

export const calender_events:any = createSlice({
  name: "calender_events",
  initialState: initialDetailState,
  reducers: {},

  extraReducers:(builder) =>  {
    builder.addCase(calendarDetails.pending,(state) => {
      state.loading = true;
    });
    builder.addCase(calendarDetails.fulfilled,(state:any, action) => {
      state.loading = false;
      state.user_groups = action.payload
    });
    builder.addCase(calendarDetails.rejected,(state:any, action) => {
      state.loading = false;
      state.error = action.payload;
    });
   
}
});

export default calender_events.reducer;

export const { calenderData } = calender_events.actions;
