import React, { useEffect, useState } from "react";
import "./EmployeeCard.css";
import { useNavigate } from "react-router-dom";
import AvatarProfile from "../../Avatar/AvatarProfile";
import {
  TEAMS_ASP,
  TEAMS_GOALS_FLOW,
  TEAMS_QUARTERLY_CHECKIN,
} from "../../../constants";
import n from "../../navigation/Route.Names";
import { useAppSelector } from "../../../redux/store/redux-hooks";

const EmployeeCard = ({ employee, flow, goalsTabKey = 0 }) => {
  const navigate = useNavigate();
  const year = useAppSelector(
    (state: any) => state?.performance?.teamGoalActiveYear
  );

  const onClickCard = () => {
    switch (flow) {
      case TEAMS_GOALS_FLOW:
        navigate(
          `/web/my_work/${"team_performance"}/detail/${employee.reporteeId}`,
          { state: { goalsTabKey, userName: employee?.userName, reporteeId: employee?.reporteeId } }
        );
        break;
      case TEAMS_QUARTERLY_CHECKIN:
        navigate(
          `/web/my_work/${"team_performance"}/${n.TEAM_QUARTERLY_CHECKIN_DETAIL
          }/${employee?.reporteeId}`
        );
        break;
      case TEAMS_ASP:
        navigate(
          `/web/my_work/team_performance/aspform/${employee.reporteeId}`
        );
        break;
      default:
        return null;
    }
  };

  return (
    <div onClick={onClickCard} className="cardcomponents" key={employee?.id}>
      <div className="image-Container">
        {employee?.profilePictureImage ? (
          <img
            className="image"
            src={`data:${employee?.profilePictureContentType};base64,${employee?.profilePictureImage}`}
            alt=""
          />
        ) : (
          employee?.userName && (
            <div className="avatar-container">
              <AvatarProfile
                name={employee?.userName}
                size={47}
                profilePic={""}
              />
            </div>
          )
        )}
      </div>
      <div className="text">
        <div className="employee-name-apprasial">
          <div className="name">{employee.userName}</div>
          {employee.appraisalQuarter && (
            <div
              className={
                employee.appraisalQuarter === "Year End"
                  ? "appraisal-quarter appraisal-year-end"
                  : "appraisal-quarter "
              }
            >
              {employee.appraisalQuarter}
            </div>
          )}
        </div>
        <div className="designation">{employee?.designation}</div>
      </div>
    </div>
  );
};
export default EmployeeCard;
