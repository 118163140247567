import React from "react";
import "./EmptyReviewComments.css";
import EmptyReview from "../../../themes/assets/images/empty-review.png";

const EmptyReviewComments = () => {
  return (
    <div className="empty-review-comments">
      <img src={EmptyReview} alt="no-comments" className="empty-review-img" />
      <div className="no-review-comment-title">No review comments yet</div>
    </div>
  );
};

export default EmptyReviewComments;
