import React, { useEffect } from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import { JOB_DETAILS } from "../../../constants";
import { InfoCard } from "..";
import Location from "../../../themes/assets/images/Location.png";
import Job from "../../../themes/assets/images/Job.png";
import Time from "../../../themes/assets/images/Time.png";
import { Button, notification, Spin } from "antd";
import { ToastErrorIcon, ToastSuccessIcon } from "../../common/svg/svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import { getJobDetail } from "../../../redux/actions/opportunities";
import { getTimeAgo } from "../../utils/util";

const DataCard = ({ title, desc }) => {
  return (
    <div>
      <div className="data-title">{title}</div>
      <div className="data-info" dangerouslySetInnerHTML={{ __html: desc }} />
    </div>
  );
};

const StickyFooter = ({ loading }) => {
  return (
    <div className="footer-opportunity">
      <Button
        disabled={loading}
        type="primary"
        className="footer-btn-opportunity"
      >
        Apply
      </Button>
    </div>
  );
};

const OpportunitiesDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { activeJobData, loading, jobsDetailData } = useAppSelector(
    (state: any) => state?.opportunities
  );

  useEffect(() => {
    if (!activeJobData?.req_id) {
      navigate("/web/my_work/opportunities");
    }
  }, []);

  const [api, contextHolder] = notification.useNotification();

  const openSuccessNotification = () => {
    api.success({
      message: "Notification Title",
      description:
        "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
      placement: "topRight",
      className: "custom-toast-area custom-success-toast",
      icon: <ToastSuccessIcon />,
    });
  };

  const openErrorNotification = () => {
    api.open({
      message: `Notification Message`,
      description: "hello",
      placement: "topRight",
      className: "custom-toast-area custom-error-toast",
      icon: <ToastErrorIcon />,
    });
  };

  function combineArrays(array1 = [], array2 = []) {
    if (array1?.length <= 0 || array2?.length <= 0) return;
    const combinedArray = [...array1, ...array2];
    const uniqueValues = new Set(combinedArray);
    return Array.from(uniqueValues).join(", ");
  }

  useEffect(() => {
    activeJobData?.req_id &&
      dispatch(
        getJobDetail({
          req_id: activeJobData?.req_id,
          job_title: activeJobData?.job_title,
          account: activeJobData?.account || "",
          experience_band: activeJobData?.experience_band,
          additional_field: activeJobData?.additional_field || "",
          primary_skills: activeJobData?.primary_skills || [],
          secondary_skills: activeJobData?.secondary_skills || [],
        })
      );
  }, []);

  const items = [
    {
      title: (
        <Link to={`/web/my_work/opportunities`} className="link-highlight">
          {JOB_DETAILS}
        </Link>
      ),
    },
    {
      title: "View Opportunity",
    },
  ];

  return (
    <>
      {contextHolder}
      <div className="opportunity-detail-page">
        <div className="opportunity-detail">
          <BreadcrumbComponent items={items} />
          {loading ? (
            <Spin spinning={loading}>
              <div className="job-additional-info spinner-opportunity" />
            </Spin>
          ) : (
            <>
              <div className="opportunities-title space-top">{JOB_DETAILS}</div>
              <div className="job-main-info">
                <div className="job-title-info">
                  <div className="job-title-data">
                    {jobsDetailData?.Job_Title}
                  </div>
                  <div className="job-desc-data">
                    {activeJobData?.capability_centre}
                  </div>
                </div>
                <div className="info-card-holder">
                  <InfoCard
                    bgColor={"#fff"}
                    icon={Location}
                    title={activeJobData?.job_location}
                  />
                  <InfoCard
                    bgColor={"#fff"}
                    icon={Job}
                    title={activeJobData?.account}
                  />
                  <InfoCard
                    bgColor={"#fff"}
                    icon={Time}
                    title={getTimeAgo(activeJobData?.job_posting_date)}
                  />
                </div>
              </div>
              <div className="job-additional-info">
                <DataCard
                  title={"Required Skills"}
                  desc={combineArrays(
                    activeJobData?.primary_skills,
                    activeJobData?.secondary_skills
                  )}
                />
                <DataCard
                  title={"Job Overview"}
                  desc={jobsDetailData?.Job_Summary}
                />
                <DataCard
                  title={"Roles & Responsibilities"}
                  desc={jobsDetailData?.Roles_and_Responsibilities}
                />
                <DataCard
                  title={"Education and Experience Required"}
                  desc={jobsDetailData?.Qualitification_and_Skills}
                />
                <DataCard
                  title={"Competencies"}
                  desc={jobsDetailData?.Preferred_Qualification}
                />
              </div>
            </>
          )}
        </div>
        {/* <StickyFooter loading={loading} /> */}
      </div>
    </>
  );
};

export default OpportunitiesDetail;
