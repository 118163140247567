import "./index.css";
import { CalendarCard, HolidayCalendarCard } from "../../calendar/HolidayCalendar";
import { Carousel, Input } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import React, { useState } from "react";
import CalendarIcon from "../../../themes/assets/images/svg/leave_management/CalendarBlank.svg";
import ManHoldingLaptop from "../../../themes/assets/images/svg/leave_management/manHoldingLaptop.svg";
import StaticCalendar from "./StaticCalendar";
import Right from "../../../themes/assets/images/svg/leave_management/ChevronRight.svg";
import {
  DropDownPopup,
  LeaveTypePopup,
  ReasonTypePopup,
  SelectDatesPopup,
} from "./Modals";
import moment from "moment";
import { LEAVE_ERROR } from "../../../constants";
import ErrorImage from "../../../themes/assets/images/adult-content.png";
import { useAppSelector } from "../../../redux/store/redux-hooks";

const { TextArea } = Input;

export const LeaveTypeBalanceCard = ({ leaveBalanceList }) => {
  const LeaveTypeBalanceDetails = (props: any) => {
    const { image, title, noOfDays } = props;
    return (
      <div className="leave-type-balance-card">
        <img className="leave-type-image" src={image} alt="" />
        <div className="leave-type-balance-card-text-container">
          <div className="leave-type-title">{title}</div>
          <div className="leave-type-balance-days">
            <span>{noOfDays} </span>
            <span className="leave-type-balance-days-text">days</span>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="leave-type-balance-card-container">
      {leaveBalanceList.map((item: any, index: number) => {
        return (
          <>
            <LeaveTypeBalanceDetails
              image={item?.resourceurl}
              title={item.leaveTypeDisplayName}
              noOfDays={item.leaveBalance}
            />
            {index != leaveBalanceList.length - 1 && (
              <div className="leave-type-balance-card-divider" />
            )}
          </>
        );
      })}
    </div>
  );
};

export const ErrorComponent = () => {
  return (
    <div className="leave_error_container">
      <img className="leave_error_image" src={ErrorImage} alt="" />
      <div className="leave_error_title">{LEAVE_ERROR}</div>
    </div>
  );
};

export const HolidayQuoteCard = () => {
  return (
    <div className="holiday-quote-card-container">
      <div className="holiday-quote-card-background">
        <div className="holiday-quote-card-image">
          <img className="manholdinglapotop" src={ManHoldingLaptop} alt="" />
        </div>
        <h1 className="holiday-quote-card-text">
          "To maintain your mental and physical well-being, it's vital to carve
          out some time for self-care on a regular basis."
        </h1>
      </div>
    </div>
  );
};

export const HolidayCarousel = ({ floaterBannerList }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <div className="holiday-carosuel-main-container">
      <Carousel
        autoplay
        className="carousel-container"
        afterChange={(current: number) => {
          setActiveSlide(current);
        }}
        autoplaySpeed={2000}
        dots={false}
      >
        {floaterBannerList.map((item: any, index: number) => (
          <div key={index} className="holiday-carousel-card">
            <LazyLoadImage
              src={item?.resourceUrl}
              className="carousel-bg-img"
              alt="banner"
              effect="blur"
            />
          </div>
        ))}
      </Carousel>
      <div className="indicators-container">
        {floaterBannerList.map((item: any, index: any) => {
          return (
            <div
              key={index}
              className={`indicator ${
                index === activeSlide
                  ? "active-indicator"
                  : "inactive-indicator"
              }`}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export const AbsenceRequestsCard = ({ absenceList }) => {
  const landingpageData = useAppSelector(
    (state: any) => state?.leavemanagement
  );
  const LeaveError = landingpageData?.error;

  const HolidayDetails = (props: any) => {
    const { leaveTypeDisplayName, endDate, startDate, workflowSteps } =
      props.data;
    function changeDateFormat(date: string) {
      return moment(date).format("MMM DD, YYYY");
    }

    return (
      <div className="absence-request-holiday-details-card-container">
        {/* holiday type and dates */}
        <div className="absence-request-holiday-details-card-text-container">
          <div className="absence-request-holiday-details-card-holiday-type-text">
            {leaveTypeDisplayName}
          </div>
          <div className="absence-request-dates-text">
            {changeDateFormat(startDate)} - {changeDateFormat(endDate)}
          </div>
        </div>

        <div
          className="absence-request-leave-type-status-text"
          style={{
            color: workflowSteps?.colorCode,
            backgroundColor: workflowSteps?.bgColor,
          }}
        >
          {workflowSteps?.displayProperties?.displayNameForEmployee}
        </div>
      </div>
    );
  };

  return (
    <div className="absence-requests-card-container">
      <div className="space-between-header">
        <div className="side-heading">Absence Request</div>
        <div className="view-all-button">View All</div>
      </div>
      <div className="absence-requests-card-holiday-details-conatiner">
        {LeaveError ? (
          <ErrorComponent />
        ) : (
          absenceList
            .filter((item: any, index: number) => index < 2)
            .map((item: any, index: number) => {
              return (
                <React.Fragment>
                  <HolidayDetails data={item} />
                  {index !== 1 && (
                    <div className="absence-request-card-divider" />
                  )}
                </React.Fragment>
              );
            })
        )}
      </div>
    </div>
  );
};

export const UpcomingHolidaysCard = () => {
  const upcomingLeavesData = useAppSelector(
    (state: any) => state?.leavemanagement
  );
  const CalendarData = upcomingLeavesData?.upcomingLeaves?.body;
  const imageLoading = upcomingLeavesData?.loadingLeaves;
  return (
    <div className="upcoming-holidays-main-card-container">
      <div className="space-between-header">
        <div className="side-heading">Upcoming Holidays</div>
        <div className="view-all-button">View All</div>
      </div>
      <div className="upcoming-holidays-cards-container">
        {CalendarData?.slice(0,3)?.map((item) => {
          return (
            <div className="upcoming-holidays-cards-container-calendar-card">
              <HolidayCalendarCard
                data={item}
                key={item}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const LeaveRequestCard = ({
  calendarData,
  leaveTypesData,
  leaveType,
  setLeaveType,
  leaveTemplate,
}) => {
  interface labelItem {
    displayName: string;
    colorCode: string;
  }

  const landingpageData = useAppSelector(
    (state: any) => state?.leavemanagement
  );
  const LeaveError = landingpageData?.error;

  const DropDownCard = (props: any) => {
    const { title, placeholder, mandatory, onClick, value } = props;
    return (
      <div onClick={onClick} className="form-item-container">
        <div className="form-item-text">
          {title} {mandatory && <span className="mandatory">*</span>}
        </div>
        <div className="select-type-input-box">
          <div
            className="input-text"
            style={{ color: value ? "black" : "grey" }}
          >
            {value ? value : placeholder}
          </div>
          <img src={Right} />
        </div>
      </div>
    );
  };
  const SelectDates = ({ onClick }) => {
    return (
      <div className="calendar-form-item-container">
        <div className="form-item-container" style={{ flex: 0.85 }}>
          <div className="form-item-text">
            Start Date and End Date <span className="mandatory">*</span>
          </div>
          <div onClick={onClick} className="select-type-input-box ">
            <div className="input-text">Select Dates</div>
            <img src={CalendarIcon} />
          </div>
        </div>
        <div className="form-item-container" style={{ flex: 0.15 }}>
          <div className="form-item-text">Days</div>
          <div className="requesting-days-box">
            <div className="input-text">-</div>
          </div>
        </div>
      </div>
    );
  };
  const TextInput = ({ placeholder, question, mandatory }) => {
    return (
      <div className="form-item-container">
        <div className="form-item-text">
          {question} {mandatory && <span className="mandatory">*</span>}
        </div>
        <TextArea
          className="select-type-input-box"
          placeholder={placeholder}
          style={{ resize: "none", height: "87px" }}
          maxLength={120}
        />
      </div>
    );
  };
  const labelData: labelItem[] = calendarData.map(
    (item: any) => item.workflowSteps
  );

  const [leaveTypeModal, setLeaveTypeModal] = useState<boolean>(false);
  const [reasonTypeModal, setReasonTypeModal] = useState<boolean>(false);
  const [selectDatesModal, setSelectDatesModal] = useState<boolean>(false);
  function onClickLeaveType() {
    setLeaveTypeModal(true);
  }

  return (
    <div className="leave-request-card-container">
      <div className="side-heading">Leave Request</div>
      <div className="leave-request-card-body">
        {LeaveError || calendarData?.length === 0 ? (
          <ErrorComponent />
        ) : (
          <>
            <div className="leave-request-card-body-header">
              <div className="leave-request-card-calendar-view">
                <div className="leave-request-card-calendar-view-top">
                  <StaticCalendar calendarData={calendarData} />
                </div>

                {/* labels */}
                <div className="leave-request-card-legends-container">
                  {labelData.map((item) => (
                    <div className="leave-request-card-legends-text-container">
                      <div
                        className="leave-request-card-legend-color"
                        style={{ backgroundColor: item.colorCode }}
                      ></div>
                      <div>{item.displayName}</div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="form-container">
                <DropDownCard
                  title="Leave Type"
                  mandatory={true}
                  placeholder={leaveTypesData[0]?.displayName}
                  value={leaveType?.displayName || ""}
                  onClick={onClickLeaveType}
                />

                {leaveTemplate?.pagesList?.map(
                  (pageItem: any, pageIndex: number) =>
                    pageItem?.sectionList?.map(
                      (sectionItem: any, sectionIndex: number) =>
                        sectionItem?.questionsList?.map(
                          (questionItem: any, questionIndex: number) => (
                            <>
                              {questionItem?.type === "TEXTAREA" && (
                                <TextInput
                                  question={questionItem?.question}
                                  placeholder={questionItem?.description}
                                  mandatory={questionItem?.mandatory}
                                />
                              )}
                              {questionItem?.type === "DROPDOWN" && (
                                <DropDownCard
                                  title={questionItem?.question}
                                  mandatory={questionItem?.mandatory}
                                  placeholder={questionItem?.description}
                                  onClick={() => setReasonTypeModal(true)}
                                  value={""}
                                />
                              )}
                            </>
                          )
                        )
                    )
                )}
              </div>
            </div>
            <div className="leave-request-card-footer-container">
              <div className="clear-all-btn">Clear All</div>
              <div className="inactive-preview-btn">Preview & Submit</div>
            </div>
          </>
        )}
        <LeaveTypePopup
          isModalOpen={leaveTypeModal}
          key={"leave-type-modal"}
          setModalOpen={setLeaveTypeModal}
          data={leaveTypesData}
          setLeaveType={setLeaveType}
        />
        <ReasonTypePopup
          isModalOpen={reasonTypeModal}
          key={"reason-type-modal"}
          setModalOpen={setReasonTypeModal}
        />
        <SelectDatesPopup
          isModalOpen={selectDatesModal}
          key={"select-type-modal"}
          setModalOpen={setSelectDatesModal}
        />
      </div>
    </div>
  );
};
