import { createSlice } from "@reduxjs/toolkit";
import { getClearanceDetail,downloadAttachment,saveClearanceDetail,uploadAttachment,deleteAttachment } from '../actions/details';
import {FAILURE, HOLD_NOTATION, SUCCESS} from '../../constants'
import {isRadioButtonChecked} from '../../components/utils/util'

type cleranceDetailState = {
  details: any,
  configuration: any,
  loading: boolean,
  error: null
};

const initialDetailState: cleranceDetailState = {
  details: {},
  configuration: {isShowNotification:false,isModal:false,isEnabled:true,alertMessage:{type:'warning',message:''},loading:false},
  loading: false,
  error: null,
};

export const detail:any = createSlice({
  name: "detail",
  initialState: initialDetailState,
  reducers: {
    typeChangeAction: (state, action) => {
      const {index,status} = action.payload
      state.details.cardDetails[index]['clearanceStatus'] = status;
      if(status===HOLD_NOTATION){
        state.details.cardDetails[index]['remarksValue'] = null;
        state.details.cardDetails[index]['deductionValue'] = null;
      }
      const ischecked = isRadioButtonChecked(state.details.cardDetails,'change');
      state.configuration.isEnabled=ischecked
    },
    deductionAction: (state, action) => {
      const {index,value}= action.payload
      state.details.cardDetails[index]['deductionValue'] = value;
    },
    remarksChangeAction: (state, action) => {
      const {index,value}= action.payload
      state.details.cardDetails[index]['remarksValue'] = value;
    },
    notificationAction: (state, action) => {
      const {isShowNotification,message,type}= action.payload
      state.configuration.isShowNotification = isShowNotification;
      state.configuration.alertMessage={type,message};
    },   
    beforeUploadAction: (state, action) => {
      const {isShowNotification,message,type,index}= action.payload
      state.configuration.isShowNotification = isShowNotification;
      state.details.cardDetails[index]['isValidFile'] = false;
      state.configuration.alertMessage={type,message};
    },   
    onChangeAction: (state, action) => {
      const {file,index}= action.payload
      if(state.details.cardDetails[index]['isValidFile']){   
        state.details.cardDetails[index]['attachmentDetails']=[file];         
      }
    },   
    onRemoveAction: (state, action) => {
      const {index}= action.payload
      state.details.cardDetails[index]['attachmentDetails'].splice(0,1);
    }   
  },

  extraReducers:(builder) =>  {
    builder.addCase(getClearanceDetail.pending,(state) => {
      state.loading = true;
      state.details.cardDetails = [];
      state.details.employeeDetail = {};
    });
    builder.addCase(getClearanceDetail.fulfilled,(state:any, action) => {
      state.loading = false;
      const {cardDetails,employeeDetail,pageTitle} = action.payload
      const newcardDetails = (cardDetails ||[]).map((item:any)=>({...item,tempclearanceStatus:item.clearanceStatus}));
      state.details.cardDetails={}
      state.details.cardDetails = newcardDetails
      state.details.employeeDetail = employeeDetail;
      state.details.pageTitle = pageTitle;
      const ischecked = isRadioButtonChecked(state.details.cardDetails,'');
      state.configuration.isEnabled=ischecked
    });
    builder.addCase(getClearanceDetail.rejected,(state:any, action) => {
      state.loading = false;
      state.error = action.payload;
      state.details.employeeDetail={}
      state.details.cardDetails={}
      state.details.cardDetails = []
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage={type:FAILURE,message:"Internal Server Error"};
    });
    builder.addCase(downloadAttachment.pending,(state) => {
      //state.loading = true;
    });
    builder.addCase(downloadAttachment.fulfilled,(state, action) => {
      //state.loading = false;
      //state.details = action.payload;
      const {fileName,mimeType,fileContent} = action.payload;
      const linkSource = `data:${mimeType};base64,${fileContent}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
    builder.addCase(downloadAttachment.rejected,(state:any, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(saveClearanceDetail.pending,(state) => {
      state.loading = true;
    });
    builder.addCase(saveClearanceDetail.fulfilled,(state, action) => {
      state.loading = false;
      const {custommessage,status,message} = action.payload;
      state.configuration.isShowNotification = true;
      if(status===SUCCESS){
        state.configuration.alertMessage={type:SUCCESS,message:custommessage};
      }else{
        state.configuration.alertMessage={type:FAILURE,message};
      }
      
    });
    builder.addCase(saveClearanceDetail.rejected,(state:any, action) => {
      state.loading = false;
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage={type:FAILURE,message:'error saved'};
      state.error = action.payload;
    });
    builder.addCase(uploadAttachment.pending,(state) => {
      state.loading = true;
    });
    builder.addCase(uploadAttachment.fulfilled,(state:any, action) => {
      state.loading = false;
      const { fileName,index, attachmentId,status,message }= action.payload
      if(status===SUCCESS){
        //if(state.details.cardDetails[index]['isValidFile']){   
          state.details.cardDetails[index]['attachmentDetails']=[{
            status:status==='success'?'done':'error',
            name:fileName
          }];    
          state.details.cardDetails[index]['isValidFile'] = true     
          state.details.cardDetails[index]['attachmentId']=attachmentId;         
        //}
      }else{
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage={type:FAILURE,message};
      }
    });
    builder.addCase(uploadAttachment.rejected,(state:any, action) => {
      state.loading = false;
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage={type:FAILURE,message:'error saved'};
      state.error = action.payload;
    });
    builder.addCase(deleteAttachment.pending,(state) => {
      state.loading = true;
    });
    builder.addCase(deleteAttachment.fulfilled,(state:any, action) => {
      state.loading = false;
      const { index }= action.payload
      state.details.cardDetails[index]['attachmentId'] = null;
      state.details.cardDetails[index]['attachmentDetails'].splice(0,1)
    });
    builder.addCase(deleteAttachment.rejected,(state:any, action) => {
      state.loading = false;
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage={type:FAILURE,message:'error saved'};
      state.error = action.payload;
    });
}
});

export default detail.reducer;

export const { typeChangeAction,deductionAction,remarksChangeAction,notificationAction,beforeUploadAction,onChangeAction,onRemoveAction } = detail.actions;