import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";
import n from "../navigation/Route.Names";
import "./SmartGoals.css";
import "./performance.css";
import type { MenuProps } from "antd";
import InfoIcon from "../../themes/assets/images/svg/Alert/Info.svg";
import {
  GOAL_ACTIVITY_SUB_TITLE,
  GOAL_MAIN_PAGE_SUBTITLE1,
  GOAL_MAIN_PAGE_SUBTITLE2,
  PERFORMANCE_BREADCRUMB,
  SMART_GOALS_ALERT,
  SMART_GOALS_BREADCRUMB,
  SMART_GOALS_PREVIEW,
} from "../../constants";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  Alert,
  Button,
  Dropdown,
  Space,
  Table,
  TableColumnsType,
  Card,
} from "antd";
// import { DownOutlined, MoreOutlined, UpOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
import { ExpandableConfig } from "rc-table/lib/interface";
import MenuIcon from "../../themes/assets/images/svg/menu-icon.svg";
import ExpandLess from "../../themes/assets/images/svg/goals/Expand_less.svg";
import ExpandMore from "../../themes/assets/images/svg/goals/Expand_more.svg";
import ExpandMoreGoal from "../../themes/assets/images/svg/Expand_more.svg";
import Warning from "../../themes/assets/images/svg/Alert/Warning.svg";
import ModalDialog from "../common/modal/modal";
import {
  CANCEL,
  DELETE,
  DELETE_MILESTONE_MESSAGE,
  DELETE_MILESTONE_TITLE,
  GOAL_MAIN_PAGE_NOGOAL,
} from "../../constants";
import WeightLeft from "../../themes/assets/images/svg/weight-left.svg";
import EmptyGoal from "../../themes/assets/images/svg/empty-goals.svg";
import AaccessTime from "../../themes/assets/images/svg/front/active_access_time.svg";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { setActiveSmartGoal } from "../../redux/reducers/goals";
interface DataType {
  key: number;
  name: any;
  subtitle: string;
  start_date: string;
  due_date: string;
  outcomes: string;
  weightage: string;
  status: any;
}

interface ExpandedDataType {
  key: React.Key;
  target_date: string;
  actual_date: string;
  name: string;
}

const SmartGoals = () => {
  const [banner, setBanner] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editGoal, setEditGoal] = useState<any>("");
  const navigate = useNavigate();
  const goalsList = useAppSelector((state: any) => state.goals) || [];
  const [year, setYear] = useState("2024");
  const dispatch = useAppDispatch();

  const onClickDropdown = (key, record) => {
    dispatch(setActiveSmartGoal(record));
    setEditGoal(key);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Goal Name & Description",
      dataIndex: "name",
      render: (record) => (
        <>
          <div className="inner-table-milestone">
            <span className="title-mile">{record["title"]}</span>
            <span className="cont-mile">{record["subtitle"]}</span>
          </div>
        </>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
    },
    {
      title: "Outcomes",
      dataIndex: "outcomes",
    },
    {
      title: "Weightage",
      dataIndex: "weightage",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (record) => {
        const { status, text } = record;
        return (
          <Button type="primary" className={status}>
            {text}
          </Button>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (record) => {
        const { key = "" } = record;
        return (
          <Space className="table-action" size="middle">
            <Dropdown menu={menuProps} trigger={["click"]}>
              <a
                onClick={(e) => {
                  e?.stopPropagation();
                  onClickDropdown(key, record);
                }}
              >
                <img src={MenuIcon} alt="MenuIcon" />
              </a>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const businessData: DataType[] = goalsList?.smartGoals?.business || [];
  const technologyData: DataType[] = goalsList?.smartGoals?.technology || [];
  const peopleData: DataType[] = goalsList?.smartGoals?.people || [];

  const items = [
    //{ key: "1", label: "Edit Goals" },
    { key: "2", label: "View Goals" },
    // { key: "3", label: "Delete Goals" },
  ];

  const handleMoreClick = () => {
    navigate(`/${n.PERFORMANCE_URL}/${n.SMART_GOALS_PREVIEW_URL}`);
  };
  const handleYearClick: MenuProps["onClick"] = (e) => {
    setYear(e.key);
  };
  const menuYear = {
    items,
    onClick: handleYearClick,
    selectable: true,
    defaultSelectedKeys: [year],
  };
  const menuProps = {
    items: items,
    onClick: (e) => handleMoreClick(),
  };
  const defaultExpandable = {
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <img
          src={ExpandLess}
          onClick={(e) => onExpand(record, e)}
          className="expand"
          alt="expand"
        />
      ) : (
        <img
          src={ExpandMore}
          onClick={(e) => onExpand(record, e)}
          className="expand"
          alt="expand"
        />
      ),
    // expandedRowRender: (record: any) => {
    //   const columns: TableColumnsType<ExpandedDataType> = [
    //     {
    //       title: "Milestones",
    //       dataIndex: "name",
    //       key: "name",
    //       render: (record) => (
    //         <div className="inner-table-milestone">
    //           <span>{record}</span>
    //         </div>
    //       ),
    //     },
    //     { title: "Target Date", dataIndex: "target_date", key: "target_date" },
    //     { title: "Actual Date", dataIndex: "actual_date", key: "actual_date" },
    //     {
    //       title: "",
    //       dataIndex: "operation",
    //       key: "operation",
    //       render: () => (
    //         <Space size="middle">
    //           <a>Edit</a>
    //         </Space>
    //       ),
    //     },
    //   ];

    //   const milestones: ExpandedDataType[] = [];
    //   for (let i = 0; i < 3; ++i) {
    //     milestones.push({
    //       key: i.toString(),
    //       target_date: "2014-12-24 23:12:00",
    //       actual_date: "2014-12-24 23:12:00",
    //       name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //     });
    //   }
    //   return (
    //     <Table
    //       columns={columns}
    //       dataSource={milestones}
    //       pagination={false}
    //       className="tbl-inner-section"
    //     />
    //   );
    // },
  };

  const [expandable, setExpandable] = useState<
    ExpandableConfig<DataType> | undefined
  >(defaultExpandable);
  const closeHandler = () => {
    setBanner(false);
  };

  const tableColumns = columns.map((item) => ({ ...item }));

  const tableProps: TableProps<DataType> = {
    bordered: false,
    showHeader: true,
    pagination: false,
    expandable,
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    //dispatch(reset({}));
    //navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
  };

  const breadbrumbItems = [
    {
      title: (
        <Link to={`/${n.PERFORMANCE_URL}`} className="link-highlight">
          {PERFORMANCE_BREADCRUMB}
        </Link>
      ),
    },
    {
      title: SMART_GOALS_BREADCRUMB,
    },
  ];

  return (
    <div className="smart-goals-section">
      <div className="breadcrumb_newgoal">
        <BreadcrumbComponent items={breadbrumbItems} />
        <div className="performance-header">
          <div className="left-side">
            <div className="my-goal-smart-text">My Goals</div>
          </div>
        </div>
      </div>
      <div className="">
        <Card style={{ width: "100%" }}>
          <Alert
            message={SMART_GOALS_ALERT}
            type="info"
            icon={<img src={InfoIcon} alt="" />}
            showIcon
          />
          <p>
            <span className="total-weight-text">Total Weight :</span>{" "}
            <span className="total-weight-green">100.0%</span>
          </p>
          <div className="business">
            <h5 className="title">{GOAL_ACTIVITY_SUB_TITLE}</h5>
            {businessData.length !== 0 ? (
              <Table
                {...tableProps}
                columns={tableColumns}
                dataSource={businessData}
                className="tbl-outer-section"
                onRow={(record, rowIndex) => ({
                  onClick: () => {
                    onClickDropdown(record?.key, record);
                    handleMoreClick();
                  },
                })}
              />
            ) : (
              <div className="empty-goals">
                <img src={EmptyGoal} alt="" />
                <span>{GOAL_MAIN_PAGE_NOGOAL}</span>
              </div>
            )}
          </div>
          <div className="business">
            <h5 className="title">{GOAL_MAIN_PAGE_SUBTITLE1}</h5>
            {technologyData.length !== 0 ? (
              <Table
                {...tableProps}
                columns={tableColumns}
                dataSource={technologyData}
                className="tbl-outer-section"
                onRow={(record, rowIndex) => ({
                  onClick: () => {
                    onClickDropdown(record?.key, record);
                    handleMoreClick();
                  },
                })}
              />
            ) : (
              <div className="empty-goals">
                <img src={EmptyGoal} alt="" />
                <span>{GOAL_MAIN_PAGE_NOGOAL}</span>
              </div>
            )}
          </div>
          <div className="business">
            <h5 className="title">{GOAL_MAIN_PAGE_SUBTITLE2}</h5>
            {peopleData.length !== 0 ? (
              <Table
                {...tableProps}
                columns={tableColumns}
                dataSource={peopleData}
                className="tbl-outer-section"
                onRow={(record, rowIndex) => ({
                  onClick: () => {
                    onClickDropdown(record?.key, record);
                    handleMoreClick();
                  },
                })}
              />
            ) : (
              <div className="empty-goals">
                <img src={EmptyGoal} alt="" />
                <span>{GOAL_MAIN_PAGE_NOGOAL}</span>
              </div>
            )}
          </div>
        </Card>
      </div>
      <ModalDialog
        openModal={isModalOpen}
        DialogHeading={DELETE_MILESTONE_TITLE}
        closeIcon={false}
        modalImageStyles={{ display: "none" }}
        modalHeadingStyles={{ display: "none" }}
        popupDivider={{ display: "none" }}
        indexScroll={{ display: "none" }}
        modalCenterImgStyles={{ display: "none" }}
        msgContent={DELETE_MILESTONE_MESSAGE}
        okTxt={DELETE}
        cancelTxt={CANCEL}
        cancelBtn={handleCancel}
        okBtn={handleOk}
        onOk={handleOk}
        onCancel={handleCancel}
        modalSubHeading={"modalSubHeading"}
        modalStyles={"backpopup delete-milestone-popup"}
        maskClosable={false}
      />
    </div>
  );
};

export default SmartGoals;
