import { Button } from "antd";
import React from "react";


function AddCertificateButton(props:any) {
    return(
        <>
        <Button type="primary" onClick={props.addCertificateAction}><img src={props.addCertificateImage} /><span>{props.addCertificateTxt}</span></Button>
        </>
    )
}


export default AddCertificateButton;