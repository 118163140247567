import US1 from "../../themes/assets/images/holiday_calendar/US_1.png";
import US2 from "../../themes/assets/images/holiday_calendar/US_2.png";
import US3 from "../../themes/assets/images/holiday_calendar/US_3.png";
import US4 from "../../themes/assets/images/holiday_calendar/US_4.png";
import US5 from "../../themes/assets/images/holiday_calendar/US_5.png";
import US6 from "../../themes/assets/images/holiday_calendar/US_6.png";
import US7 from "../../themes/assets/images/holiday_calendar/US_7.png";
import US8 from "../../themes/assets/images/holiday_calendar/US_8.png";

import UG1 from "../../themes/assets/images/holiday_calendar/UG_1.png";
import UG2 from "../../themes/assets/images/holiday_calendar/UG_2.png";
import UG3 from "../../themes/assets/images/holiday_calendar/UG_3.png";
import UG4 from "../../themes/assets/images/holiday_calendar/UG_4.png";
import UG5 from "../../themes/assets/images/holiday_calendar/UG_5.png";
import UG6 from "../../themes/assets/images/holiday_calendar/UG_6.png";
import UG7 from "../../themes/assets/images/holiday_calendar/UG_7.png";

import IN1 from "../../themes/assets/images/holiday_calendar/IN_1.png";
import IN2 from "../../themes/assets/images/holiday_calendar/IN_2.png";
import IN3 from "../../themes/assets/images/holiday_calendar/IN_3.png";
import IN4 from "../../themes/assets/images/holiday_calendar/IN_4.png";
import IN5 from "../../themes/assets/images/holiday_calendar/IN_5.png";
import IN6 from "../../themes/assets/images/holiday_calendar/IN_6.png";
import IN7 from "../../themes/assets/images/holiday_calendar/IN_7.png";
import IN8 from "../../themes/assets/images/holiday_calendar/IN_8.png";
import IN9 from "../../themes/assets/images/holiday_calendar/IN_9.png";
import IN10 from "../../themes/assets/images/holiday_calendar/IN_10.png";
import IN11 from "../../themes/assets/images/holiday_calendar/IN_11.png";

import MX1 from "../../themes/assets/images/holiday_calendar/MX1.png";
import MX2 from "../../themes/assets/images/holiday_calendar/MX2.png";
import MX3 from "../../themes/assets/images/holiday_calendar/MX3.png";
import MX4 from "../../themes/assets/images/holiday_calendar/MX4.png";
import MX5 from "../../themes/assets/images/holiday_calendar/MX5.png";
import MX6 from "../../themes/assets/images/holiday_calendar/MX6.png";

import SN1 from "../../themes/assets/images/holiday_calendar/SN1.png";
import SN2 from "../../themes/assets/images/holiday_calendar/SN2.png";
import SN3 from "../../themes/assets/images/holiday_calendar/SN3.png";
import SN4 from "../../themes/assets/images/holiday_calendar/SN4.png";

import PO1 from "../../themes/assets/images/holiday_calendar/PO1.png";
import PO2 from "../../themes/assets/images/holiday_calendar/PO2.png";
import PO3 from "../../themes/assets/images/holiday_calendar/PO3.png";
import PO4 from "../../themes/assets/images/holiday_calendar/PO4.png";
import PO5 from "../../themes/assets/images/holiday_calendar/PO5.png";

import CA1 from "../../themes/assets/images/holiday_calendar/CA1.png";
import CA2 from "../../themes/assets/images/holiday_calendar/CA2.png";
import CA3 from "../../themes/assets/images/holiday_calendar/CA3.png";
import CA4 from "../../themes/assets/images/holiday_calendar/CA4.png";

import CO1 from "../../themes/assets/images/holiday_calendar/CO1.png";
import CO2 from "../../themes/assets/images/holiday_calendar/CO2.png";
import CO3 from "../../themes/assets/images/holiday_calendar/CO3.png";
import CO4 from "../../themes/assets/images/holiday_calendar/CO4.png";
import CO5 from "../../themes/assets/images/holiday_calendar/CO5.png";
import CO6 from "../../themes/assets/images/holiday_calendar/CO6.png";
import CO7 from "../../themes/assets/images/holiday_calendar/CO7.png";
import CO8 from "../../themes/assets/images/holiday_calendar/CO8.png";
import CO9 from "../../themes/assets/images/holiday_calendar/CO9.png";
import CO10 from "../../themes/assets/images/holiday_calendar/CO10.png";
import CO11 from "../../themes/assets/images/holiday_calendar/CO11.png";

const holiday_details = {
  USA: [
    // {
    //   id: "us1",
    //   month: "JAN",
    //   date: "01",
    //   day: "Monday",
    //   title: "New Year Day",
    //   banner: US1,
    //   fixed: true,
    // },
    // {
    //   id: "us2",
    //   month: "JAN",
    //   date: "15",
    //   day: "Monday",
    //   title: "Martin Luther King Jr. Day",
    //   banner: US2,
    //   fixed: true,
    // },
    {
      id: "us3",
      month: "MAY",
      date: "27",
      day: "Monday",
      title: "Memorial Day",
      banner: US3,
      fixed: true,
    },
    {
      id: "us4",
      month: "JUL",
      date: "04",
      day: "Thursday",
      title: "Independence Day",
      banner: US4,
      fixed: true,
    },
    {
      id: "us5",
      month: "SEP",
      date: "02",
      day: "Monday",
      title: "Labor Day",
      banner: US5,
      fixed: true,
    },
    {
      id: "us6",
      month: "NOV",
      date: "28",
      day: "Thursday",
      title: "Thanksgiving Day",
      banner: US6,
      fixed: true,
    },
    {
      id: "us7",
      month: "NOV",
      date: "29",
      day: "Friday",
      title: "Thanksgiving Day",
      banner: US7,
      fixed: true,
    },
    {
      id: "us8",
      month: "DEC",
      date: "25",
      day: "Wednesday",
      title: "Christmas Day",
      banner: US8,
      fixed: true,
    },
  ],
  URUGUAY: [
    // {
    //   id: "ug1",
    //   month: "JAN",
    //   date: "01",
    //   day: "Monday",
    //   title: "New Year Day",
    //   banner: UG1,
    //   fixed: true,
    // },
    // {
    //   id: "ug2",
    //   month: "FEB",
    //   date: "12",
    //   day: "Monday",
    //   title: "Carnival",
    //   banner: UG2,
    //   fixed: true,
    // },
    // {
    //   id: "ug3",
    //   month: "FEB",
    //   date: "13",
    //   day: "Tuesday",
    //   title: "Carnival",
    //   banner: UG2,
    //   fixed: true,
    // },
    // {
    //   id: "ug4",
    //   month: "MAR",
    //   date: "28",
    //   day: "Thursday",
    //   title: "Semana Turismo",
    //   banner: UG3,
    //   fixed: true,
    // },
    // {
    //   id: "ug5",
    //   month: "MAR",
    //   date: "29",
    //   day: "Friday",
    //   title: "Semana Turismo",
    //   banner: UG3,
    //   fixed: true,
    // },
    {
      id: "ug6",
      month: "MAY",
      date: "01",
      day: "Wednesday",
      title: "International Worker’s Day",
      banner: UG4,
      fixed: true,
    },
    {
      id: "ug7",
      month: "JUL",
      date: "18",
      day: "Thursday",
      title: "Constitution Day",
      banner: UG5,
      fixed: true,
    },
    {
      id: "ug8",
      month: "AUG",
      date: "25",
      day: "Sunday",
      title: "Independence Day",
      banner: UG6,
      fixed: true,
    },
    {
      id: "ug9",
      month: "DEC",
      date: "25",
      day: "Wednesday",
      title: "Christmas Day",
      banner: UG7,
      fixed: true,
    },
  ],
  INDIA: [
    // {
    //   id: "in1",
    //   month: "JAN",
    //   date: "01",
    //   day: "Monday | Floater",
    //   title: "New Year Day",
    //   banner: IN1,
    //   fixed: false,
    // },
    // {
    //   id: "in2",
    //   month: "JAN",
    //   date: "15",
    //   day: "Monday | Floater",
    //   title: "Makara Sankrathi/Pongal",
    //   banner: IN2,
    //   fixed: false,
    // },
    // {
    //   id: "in3",
    //   month: "JAN",
    //   date: "26",
    //   day: "Friday | Fixed",
    //   title: "Republic Day",
    //   banner: IN3,
    //   fixed: true,
    // },
    // {
    //   id: "in4",
    //   month: "MAR",
    //   date: "25",
    //   day: "Monday | Floater",
    //   title: "Holi/Dhulandi",
    //   banner: IN4,
    //   fixed: false,
    // },
    // {
    //   id: "in5",
    //   month: "APR",
    //   date: "09",
    //   day: "Tuesday | Floater",
    //   title: "Ugadi/Gudi Padwa",
    //   banner: IN5,
    //   fixed: false,
    // },
    // {
    //   id: "in119",
    //   month: "APR",
    //   date: "11",
    //   day: "Thursday | Floater",
    //   title: "Ramzan/Eid-ul-Fitar",
    //   banner: IN6,
    //   fixed: false,
    // },
    {
      id: "in6",
      month: "MAY",
      date: "01",
      day: "Wednesday | Fixed",
      title: "May Day/Maharashtra Day",
      banner: IN7,
      fixed: true,
    },
    {
      id: "in7",
      month: "AUG",
      date: "15",
      day: "Thursday | Fixed",
      title: "Independence Day",
      banner: IN8,
      fixed: true,
    },
    {
      id: "in8",
      month: "OCT",
      date: "02",
      day: "Wednesday | Fixed",
      title: "Gandhi Jayanti",
      banner: IN9,
      fixed: true,
    },
    {
      id: "in9",
      month: "NOV",
      date: "01",
      day: "Friday | Fixed",
      title: "Diwali/Rajayothsava",
      banner: IN10,
      fixed: true,
    },
    {
      id: "in10",
      month: "DEC",
      date: "25",
      day: "Wednesday | Floater",
      title: "Christmas",
      banner: IN11,
      fixed: false,
    },
  ],
  MEXICO: [
    {
      id: "mx1",
      month: "MAY",
      date: "01",
      day: "Wednesday",
      title: "International Labor Day",
      banner: MX1,
      fixed: true,
    },
    {
      id: "mx2",
      month: "SEP",
      date: "16",
      day: "Monday",
      title: "Independence Day",
      banner: MX2,
      fixed: true,
    },
    {
      id: "mx3",
      month: "OCT",
      date: "01",
      day: "Tuesday",
      title: "Cambio de Presidente",
      banner: MX3,
      fixed: true,
    },
    {
      id: "mx4",
      month: "NOV",
      date: "18",
      day: "Monday",
      title: "Revolution Day",
      banner: MX4,
      fixed: true,
    },
    {
      id: "mx5",
      month: "DEC",
      date: "23",
      day: "Monday",
      title: "Christmas Day",
      banner: MX5,
      fixed: true,
    },
    {
      id: "mx6",
      month: "DEC",
      date: "24",
      day: "Tuesday",
      title: "Christmas Day",
      banner: MX5,
      fixed: true,
    },
    {
      id: "mx7",
      month: "DEC",
      date: "25",
      day: "Wednesday",
      title: "Christmas Day",
      banner: MX5,
      fixed: true,
    },
    {
      id: "mx8",
      month: "DEC",
      date: "30",
      day: "Monday",
      title: "New Years Day",
      banner: MX6,
      fixed: true,
    },
    {
      id: "mx9",
      month: "DEC",
      date: "31",
      day: "Tuesday",
      title: "New Years Day",
      banner: MX5,
      fixed: true,
    },
  ],
  SINGAPORE: [
    {
      id: "sn1",
      month: "MAY",
      date: "01",
      day: "Wednesday",
      title: "Labour Day",
      banner: MX1,
      fixed: true,
    },
    {
      id: "sn2",
      month: "MAY",
      date: "22",
      day: "Wednesday",
      title: "Vesak Day",
      banner: SN1,
      fixed: true,
    },
    {
      id: "sn3",
      month: "JUN",
      date: "17",
      day: "Monday",
      title: "Hari Raya Haji",
      banner: SN2,
      fixed: true,
    },
    {
      id: "sn4",
      month: "AUG",
      date: "09",
      day: "Friday",
      title: "National Day",
      banner: SN3,
      fixed: true,
    },
    {
      id: "sn5",
      month: "OCT",
      date: "31",
      day: "Thursday",
      title: "Deepavali",
      banner: SN4,
      fixed: true,
    },

    {
      id: "mx7",
      month: "DEC",
      date: "25",
      day: "Wednesday",
      title: "Christmas Day",
      banner: MX5,
      fixed: true,
    },
  ],
  POLAND: [
    {
      id: "po1",
      month: "MAY",
      date: "01",
      day: "Wednesday",
      title: "Labour Day",
      banner: MX1,
      fixed: true,
    },
    {
      id: "po2",
      month: "MAY",
      date: "03",
      day: "Friday",
      title: "Constitution Day",
      banner: PO1,
      fixed: true,
    },
    {
      id: "po3",
      month: "MAY",
      date: "30",
      day: "Thursday",
      title: "Corpus Christi",
      banner: PO2,
      fixed: true,
    },
    {
      id: "po4",
      month: "AUG",
      date: "15",
      day: "Thursday",
      title: "Assumption Day",
      banner: PO5,
      fixed: true,
    },
    {
      id: "po5",
      month: "NOV",
      date: "01",
      day: "Friday",
      title: "All Saints' Day",
      banner: PO3,
      fixed: true,
    },
    {
      id: "po6",
      month: "NOV",
      date: "11",
      day: "Monday",
      title: "Independence Day",
      banner: PO4,
      fixed: true,
    },

    {
      id: "po7",
      month: "DEC",
      date: "25",
      day: "Wednesday",
      title: "Christmas Day",
      banner: MX5,
      fixed: true,
    },
    {
      id: "po8",
      month: "DEC",
      date: "26",
      day: "Thursday",
      title: "2nd Day of Christmas",
      banner: MX5,
      fixed: true,
    },
  ],
  CANADA: [
    {
      id: "ca1",
      month: "MAY",
      date: "20",
      day: "Monday",
      title: "Victoria Day",
      banner: CA1,
      fixed: true,
    },
    {
      id: "ca2",
      month: "JUL",
      date: "01",
      day: "Monday",
      title: "Canada Day",
      banner: CA2,
      fixed: true,
    },
    {
      id: "ca3",
      month: "SEP",
      date: "02",
      day: "Monday",
      title: "Labor - Day",
      banner: MX1,
      fixed: true,
    },
    {
      id: "ca4",
      month: "OCT",
      date: "14",
      day: "Monday",
      title: "Thanksgiving",
      banner: CA3,
      fixed: true,
    },
    {
      id: "ca5",
      month: "DEC",
      date: "25",
      day: "Wednesday",
      title: "Christmas",
      banner: MX5,
      fixed: true,
    },
    {
      id: "ca6",
      month: "DEC",
      date: "26",
      day: "Thursday",
      title: "Boxing Day",
      banner: CA4,
      fixed: true,
    },
  ],
  COLOMBIA: [
    {
      id: "co1",
      month: "MAY",
      date: "01",
      day: "Wednesday",
      title: "Labor Day",
      banner: MX1,
      fixed: true,
    },
    {
      id: "co2",
      month: "MAY",
      date: "13",
      day: "Monday",
      title: "Ascension Day",
      banner: CO1,
      fixed: true,
    },
    {
      id: "co3",
      month: "JUN",
      date: "03",
      day: "Monday",
      title: "Corpus Christi Day",
      banner: CO2,
      fixed: true,
    },
    {
      id: "co4",
      month: "JUN",
      date: "10",
      day: "Monday",
      title: "Sacred Heart",
      banner: CO3,
      fixed: true,
    },
    {
      id: "co5",
      month: "JUL",
      date: "01",
      day: "Monday",
      title: "Feast of St Peter and St Paul",
      banner: CO4,
      fixed: true,
    },
    {
      id: "co6",
      month: "JUL",
      date: "20",
      day: "Saturday",
      title: "Independence Day",
      banner: CO5,
      fixed: true,
    },
    {
      id: "co7",
      month: "AUG",
      date: "07",
      day: "Wednesday",
      title: "Battle of Boyacá",
      banner: CO6,
      fixed: true,
    },
    {
      id: "co8",
      month: "AUG",
      date: "19",
      day: "Monday",
      title: "Assumption Day",
      banner: CO7,
      fixed: true,
    },
    {
      id: "co9",
      month: "OCT",
      date: "14",
      day: "Monday",
      title: "Colombus Day Holiday",
      banner: CO8,
      fixed: true,
    },
    {
      id: "co10",
      month: "NOV",
      date: "04",
      day: "Monday",
      title: "All Saint's Day",
      banner: CO9,
      fixed: true,
    },
    {
      id: "co11",
      month: "NOV",
      date: "11",
      day: "Monday",
      title: "Independence of Cartagena",
      banner: CO10,
      fixed: true,
    },
    {
      id: "co12",
      month: "DEC",
      date: "08",
      day: "Sunday",
      title: "Immaculate Conception",
      banner: CO11,
      fixed: true,
    },
    {
      id: "co13",
      month: "DEC",
      date: "23",
      day: "Monday",
      title: "Christmas Day",
      banner: MX5,
      fixed: true,
    },
    {
      id: "co14",
      month: "DEC",
      date: "24",
      day: "Tuesday",
      title: "Christmas Day",
      banner: MX5,
      fixed: true,
    },
    {
      id: "co15",
      month: "DEC",
      date: "25",
      day: "Wednesday",
      title: "Christmas Day",
      banner: MX5,
      fixed: true,
    },
    {
      id: "co16",
      month: "DEC",
      date: "30",
      day: "Monday",
      title: "New Year Day",
      banner: MX6,
      fixed: true,
    },
    {
      id: "co17",
      month: "DEC",
      date: "31",
      day: "Tuesday",
      title: "New Year Day",
      banner: MX6,
      fixed: true,
    },
  ],
};

export default holiday_details;
