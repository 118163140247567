import {
  START,
  SEPARATION_URL,
  SEPARATION_DETAIL_URL,
  MYPROFILE_URL,
  ALTIVERSE,
  MY_SPACE,
  MY_SPACE_CONTENTS,
  My_SPACE_EDITPOP,
  TABLE_DATA,
  SUCCESS_STORY_DETAIL,
  MYWORK_URL,
  LOGOUT_URL,
  ADD_CERTIFICATION_URL,
  CERTIFICATION_URL,
  CERTIFICATION_PREVIEW_URL,
  EDIT_CERTIFICATION_URL,
  CERTIFICATION_ID,
  PERFORMANCE_URL,
  GOAL_PREVIEW_URL,
  SMART_GOALS_PREVIEW_URL,
  SMART_GOALS_PREVIEW,
  MILESTONE_URL,
  NEW_GOAL_URL,
  POLICYHUB_URL,
  SAMRT_GOAL_URL,
  CHALLENG_GOAL_URL,
  CONTRIBUTE_VIEW_GOAL_URL,
  CONTRIBUTE_EDIT_GOAL_URL,
  //CONTRIBUTE_CARD_GOAL_URL,
  GOAL_EVALUATION_URL,
  COMPETENCY_EVALUATION_URL,
  CASCADING_EDIT_GOALS_URL,
  CASCADING_VIEW_GOALS_URL,
  VIEW_ASP,
  ARCHIVED_GOAL_URL,
  HOLIDAY_CALENDAR_URL,
  TEAM_PERFORMANCE,
  MANAGAER_CASCADE_GOAL_URL,
  MANAGER_GOAL_EVAL,
  SMART_GOAL_GAME,
  TEAM_QUARTERLY_CHECKIN,
  TEAM_QUARTERLY_CHECKIN_DETAIL,
  QUARTERLY_CHECKIN_URL,
  ASP_FORM_URL,
  ASP_EMPLOYEE_SIGNATURE,
} from "../../constants";

const constants = {
  START,
  SEPARATION_URL,
  MYPROFILE_URL,
  SEPARATION_DETAIL_URL,
  ALTIVERSE,
  MY_SPACE,
  MY_SPACE_CONTENTS,
  My_SPACE_EDITPOP,
  TABLE_DATA,
  SUCCESS_STORY_DETAIL,
  LOGOUT_URL,
  MYWORK_URL,
  ADD_CERTIFICATION_URL,
  CERTIFICATION_URL,
  CERTIFICATION_PREVIEW_URL,
  EDIT_CERTIFICATION_URL,
  CERTIFICATION_ID,
  PERFORMANCE_URL,
  GOAL_PREVIEW_URL,
  SMART_GOALS_PREVIEW_URL,
  SMART_GOALS_PREVIEW,
  MILESTONE_URL,
  NEW_GOAL_URL,
  POLICYHUB_URL,
  SAMRT_GOAL_URL,
  CHALLENG_GOAL_URL,
  CONTRIBUTE_VIEW_GOAL_URL,
  CONTRIBUTE_EDIT_GOAL_URL,
  // CONTRIBUTE_CARD_GOAL_URL,
  GOAL_EVALUATION_URL,
  COMPETENCY_EVALUATION_URL,
  CASCADING_EDIT_GOALS_URL,
  CASCADING_VIEW_GOALS_URL,
  VIEW_ASP,
  ARCHIVED_GOAL_URL,
  HOLIDAY_CALENDAR_URL,
  TEAM_PERFORMANCE,
  MANAGAER_CASCADE_GOAL_URL,
  MANAGER_GOAL_EVAL,
  SMART_GOAL_GAME,
  TEAM_QUARTERLY_CHECKIN,
  TEAM_QUARTERLY_CHECKIN_DETAIL,
  QUARTERLY_CHECKIN_URL,
  ASP_FORM_URL,
  ASP_EMPLOYEE_SIGNATURE,
};
export default constants;
