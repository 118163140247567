import { Button, Modal } from "antd";
import React, { useState } from "react";
import "./ClosePopUp.css";
import { CANCEL, SUBMIT } from "../../../constants";

const ClosePopUp = (props) => {
  return (
    <Modal
      open={props?.modalState}
      className="close-popup-checkin"
      onCancel={() => props?.setModalState(false)}
      footer={[
        <Button
          className="cancelBtn"
          key="back"
          onClick={() => props?.setModalState(false)}
        >
          {CANCEL}
        </Button>,
        <Button
          className="submitBtn"
          key="submit"
          type="primary"
          onClick={props?.onHandleSubmit}
        >
          {SUBMIT}
        </Button>,
      ]}
      closeIcon={null}
      centered={true}
      closable={false}
    >
      <div className="closePopUpTitle">{props?.title}</div>
      <div className="subtitle">{props?.subTitle}</div>
    </Modal>
  );
};

export default ClosePopUp;
