import * as React from "react";

export const CollapseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#0642B5"
      d="M16.292 15.61a.696.696 0 0 1-.508-.22l-4.313-4.5-4.255 4.44a.702.702 0 0 1-1.015 0 .778.778 0 0 1 0-1.06l4.561-4.76a.971.971 0 0 1 1.419 0l4.619 4.82c.278.29.278.77 0 1.06a.696.696 0 0 1-.508.22Z"
    />
  </svg>
);
