import { createSlice, current } from "@reduxjs/toolkit";
import {
  applyJob,
  getJobDetail,
  getJobOpportunitiesList,
} from "../actions/opportunities";

type OpportunitiesStruct = {
  loading: Boolean;
  error: Boolean;
  jobsData: Array<Object>;
  jobsDetailData: Object;
  activeJobData: Object;
};

const initialDetailState: OpportunitiesStruct = {
  loading: false,
  error: false,
  jobsData: [],
  jobsDetailData: {},
  activeJobData: {},
};

export const opportunities: any = createSlice({
  name: "opportunities",
  initialState: initialDetailState,
  reducers: {
    saveActiveJob: (state, action) => {
      state.activeJobData = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJobOpportunitiesList.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getJobOpportunitiesList.fulfilled, (state: any, action) => {
      state.loading = false;
      state.jobsData = action.payload;
    });
    builder.addCase(getJobOpportunitiesList.rejected, (state: any, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getJobDetail.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.jobsDetailData = {};
    });
    builder.addCase(getJobDetail.fulfilled, (state: any, action) => {
      state.loading = false;
      state.jobsDetailData = action?.payload;
    });
    builder.addCase(getJobDetail.rejected, (state: any, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(applyJob.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(applyJob.fulfilled, (state: any, action) => {
      state.loading = false;
    });
    builder.addCase(applyJob.rejected, (state: any, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default opportunities.reducer;
export const { saveActiveJob } = opportunities.actions;
