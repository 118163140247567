import { Carousel, Skeleton } from "antd";
import LockBadgeOne from "../../../../../themes/assets/images/svg/Bartle_test_locked.png";
import ContributeSlider from "./ContributeSlider";
import { useAppSelector } from "../../../../../redux/store/redux-hooks";

const ContributorCarouselCard = ({ data }) => {
  return (
    <div>
      <h3 className="contentStyle">
        <img src={LockBadgeOne} alt="gamebadge" />
        <div className="range_slide">
          <p>
            {data?.badgeDesc}
            <br />
            <span>{data?.badgeName}</span>
          </p>
          <ContributeSlider progress={data?.min} max={data?.max} />
        </div>
      </h3>
    </div>
  );
};

const TopContributor = ({ data }) => {
  return (
    <Carousel
      autoplay
      dots={data?.length > 1 ? true : false}
      className="contribut_slide"
    >
      {data?.map((item, index) => {
        return <ContributorCarouselCard data={item} key={index} />;
      })}
    </Carousel>
  );
};

export default TopContributor;
