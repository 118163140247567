import React, { useState } from "react";
import { Form, Input, Button, Card, List, Avatar } from "antd";
import "./QuaterlyCheckInGoals.css";
import ModalDialog from "../common/modal/modal";
import {
  CANCEL,
  QUATERLY_CHECKIN_COMMENTS_TITLE,
  SUBMIT_QUATERLY_CHECKIN_BTN,
  SUBMIT_QUATERLY_CHECKIN_CONFIRM,
  SUBMIT_QUATERLY_CHECKIN_TITLE,
  SUBMIT_QUATERLY_CHECKIN_MESSAGE,
  NO_COMMENTS_FOUND,
  QUATERLY_CHECKIN_TITLE,
  SELF_ASSESSMENT_TXT,
  QUATERLY_CHECKIN_SUBTITLE,
  QUATERLY_CALENDER_CONTENT,
  QUATERLY_GRID_TITLE,
  QUATERLY_GRID_TITLE_SECOND,
} from "../../constants";
import EmployeeAvatar from "../../themes/assets/images/performance/avatar-emp.jpg";
import NoComents from "../../themes/assets/images/performance/svg/no-comments.svg";
import selfAssessmentImg from "../../themes/assets/images/svg/goals/self_assessment_modal.svg";
import GoalsActivities from "./GoalActivities";
import CalenderChar from "../../themes/assets/images/svg/calender-char.svg";

const { Meta } = Card;
const locale = {
  emptyText: (
    <div>
      <p>
        <img src={NoComents} alt="No comments" />
      </p>
      <p className="no-data-found">{NO_COMMENTS_FOUND}</p>
    </div>
  ),
};
const data = [
  {
    title: "Shashank Shekhar",
    description: "Product Manager",
    EmployeeAvatar: EmployeeAvatar,
  },
  {
    title: "Shashank Shekhar",
    description: "Product Manager",
    EmployeeAvatar: EmployeeAvatar,
  },
];
const QuarterlyCheckInGoals = (props: any) => {
  const { index } = props;
  const onFinish = (values) => {
    // Handle form submission logic here
    console.log("Received values:", values);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [comments, setComments] = useState("");
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelAction = () => {
    setIsCompleteModalOpen(false);
  };
  const showModal = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsModalOpen(true);
  };
  const showAssesmentModal = (e: any) => {
    setIsModalOpen(false);
    setIsCompleteModalOpen(true);
  };
  const handleCommentsChange = (changedValues: any) => {
    // Update comments state when the textArea value changes
    if ("textArea" in changedValues) {
      setComments(changedValues.textArea);
    }
  };
  return (
    <div className="goal_quater-check-inn">
      <Form onFinish={onFinish} onValuesChange={handleCommentsChange}>
        <h2>{QUATERLY_CHECKIN_TITLE}</h2>
        <h4>{QUATERLY_CHECKIN_SUBTITLE}</h4>
        <Form.Item
          name="textArea"
          // rules={[{ required: true, message: "Please enter some text!" }]}
        >
          <Input.TextArea rows={4} placeholder="Add your comment" />
        </Form.Item>
        <div className="calender_cont-sec">
          <img src={CalenderChar} />
          <p>{QUATERLY_CALENDER_CONTENT}</p>
        </div>
        <Form.Item className="quaterly-checkin-btn">
          {index !== 0 && (
            <Button
              type="primary"
              onClick={(e: any) => showModal(e)}
              htmlType="submit"
              disabled={!comments.trim()}
            >
              {SUBMIT_QUATERLY_CHECKIN_BTN}
            </Button>
          )}
          <ModalDialog
            openModal={isModalOpen}
            DialogHeading={SUBMIT_QUATERLY_CHECKIN_TITLE}
            closeIcon={false}
            modalImageStyles={{ display: "none" }}
            modalHeadingStyles={{ display: "none" }}
            msgContent={SUBMIT_QUATERLY_CHECKIN_MESSAGE}
            modalContent={"checkin-msg"}
            okTxt={SUBMIT_QUATERLY_CHECKIN_CONFIRM}
            cancelTxt={CANCEL}
            cancelBtn={handleCancel}
            okBtn={(e: any) => showAssesmentModal(e)}
            onCancel={props?.onCancel}
            modalSubHeading={"quaterly-modal-heading"}
            modalStyles={"backpopup submit-first-quarter-checkin-popup"}
            maskClosable={false}
          ></ModalDialog>
          <ModalDialog
            openModal={isCompleteModalOpen}
            DialogHeading={SELF_ASSESSMENT_TXT}
            closeIcon={false}
            modalImageStyles={{ display: "none" }}
            modalHeadingStyles={{ display: "none" }}
            modalCenterImg={selfAssessmentImg}
            modalCenterImgClasses={"modal-Center-Images"}
            msgContent={SUBMIT_QUATERLY_CHECKIN_MESSAGE}
            modalContent={"checkin-msg check-in-goals"}
            okTxt={SUBMIT_QUATERLY_CHECKIN_CONFIRM}
            cancelTxt={CANCEL}
            cancelBtn={handleCancel}
            okBtn={() => {
              setIsCompleteModalOpen(false);
            }}
            onCancel={props?.onCancel}
            modalSubHeading={"quaterly-modal-heading"}
            modalStyles={
              "backpopup submit-first-quarter-checkin-popup checkin-popup"
            }
            maskClosable={false}
          />
        </Form.Item>
      </Form>
      <div className="quaterly-checkin-comments">
        <h4>{QUATERLY_GRID_TITLE}</h4>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
          locale={locale}
          dataSource={data}
          renderItem={(item: any) => {
            return (
              <>
                <List.Item>
                  <Card
                    className="quaterly_comments_card"
                    title={
                      <Meta
                        className="quaterly_comments_meta"
                        avatar={<Avatar src={item?.EmployeeAvatar} />}
                        title={item?.title}
                        description={item?.description}
                      />
                    }
                    bordered={true}
                  >
                    <p className="quaterly_comments_review">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum{" "}
                      <a href="">Read More...</a>
                    </p>
                  </Card>
                </List.Item>
              </>
            );
          }}
        />
      </div>
      <div className="quaterly-checkin-comments">
        <h4>{QUATERLY_GRID_TITLE_SECOND}</h4>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
          locale={locale}
          dataSource={data}
          renderItem={(item: any) => {
            return (
              <>
                <List.Item>
                  <Card
                    className="quaterly_comments_card"
                    title={
                      <Meta
                        className="quaterly_comments_meta"
                        avatar={<Avatar src={item?.EmployeeAvatar} />}
                        title={item?.title}
                        description={item?.description}
                      />
                    }
                    bordered={true}
                  >
                    <p className="quaterly_comments_review">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum{" "}
                      <a href="">Read More...</a>
                    </p>
                  </Card>
                </List.Item>
              </>
            );
          }}
        />
      </div>
    </div>
  );
};

export default QuarterlyCheckInGoals;
