import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface mySpaceState {
    activeIndex: number;
    currentTab: any;
}

const initialState: mySpaceState = {
    activeIndex: 0,
    currentTab: { index: 0 },
};

const mySpaceSlice = createSlice({
    name: "mySpace",
    initialState,
    reducers: {
        setActiveIndex: (state, action: PayloadAction<number>) => {
            state.activeIndex = action.payload;
        },
        setCurrentTab: (state, action: PayloadAction<any>) => {
            state.currentTab = action.payload;
            state.activeIndex = action.payload?.index || 0;
        }
    }
});

export const {
    setActiveIndex,
    setCurrentTab
} = mySpaceSlice.actions;

export default mySpaceSlice.reducer;
