import React from "react";

export const CurrentMonthIcon = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
  >
    <rect width={28} height={28} fill="#E6F2E6" rx={3.5} />
    <path
      fill="green"
      d="M6.733 18.819a.744.744 0 0 1-.53-1.27l4.218-4.218c.434-.44 1.15-.44 1.583 0l1.39 1.388 5.457-5.458a.744.744 0 1 1 1.053 1.053l-5.72 5.72c-.432.44-1.15.44-1.582 0l-1.39-1.39-3.949 3.95a.733.733 0 0 1-.53.217v.008Z"
    />
    <mask id="a" fill="#fff">
      <path d="M20.145 14.309a.749.749 0 0 1-.747-.747v-3.77l-3.673.044h-.008a.747.747 0 0 1-.007-1.493l3.666-.045h.022c.396 0 .77.15 1.053.433.284.284.44.657.44 1.06v3.771c0 .41-.328.747-.746.747Z" />
    </mask>
    <path
      fill="green"
      d="M20.145 14.309a.749.749 0 0 1-.747-.747v-3.77l-3.673.044h-.008a.747.747 0 0 1-.007-1.493l3.666-.045h.022c.396 0 .77.15 1.053.433.284.284.44.657.44 1.06v3.771c0 .41-.328.747-.746.747Z"
    />
    {/* <path
      fill="green"
      d="M19.398 9.791h1.75V8.02l-1.77.022.02 1.75Zm-3.673.045v1.75H15.746l-.021-1.75Zm-.754-.739h-1.75v.032l1.75-.032Zm.739-.754v1.75H15.73l-.021-1.75Zm3.666-.045v-1.75H19.355l.021 1.75Zm.77 4.26c.555 0 1.002.448 1.002 1.004h-3.5a2.499 2.499 0 0 0 2.497 2.497v-3.5Zm1.002 1.004v-3.77h-3.5v3.77h3.5Zm-1.77-5.52-3.675.044.043 3.5 3.674-.045-.043-3.5Zm-3.653.044h-.008v3.5h.008v-3.5Zm-.008 0c.562 0 .994.454 1.003.98l-3.499.063a2.497 2.497 0 0 0 2.496 2.457v-3.5Zm1.004 1.011c0 .51-.41.996-1.011.996v-3.5c-1.423 0-2.49 1.173-2.49 2.504h3.5Zm-.99.996 3.666-.045-.042-3.5-3.667.045.043 3.5Zm3.645-.045h.022v-3.5h-.022v3.5Zm.022 0a.28.28 0 0 1-.185-.08l2.476-2.474a3.224 3.224 0 0 0-2.29-.946v3.5Zm-.184-.08a.262.262 0 0 1-.072-.177h3.5c0-.872-.345-1.69-.953-2.297l-2.475 2.475Zm-.072-.177v3.771h3.5v-3.77h-3.5Zm0 3.771c0-.544.443-1.003 1.003-1.003v3.5a2.494 2.494 0 0 0 2.497-2.497h-3.5Z"
      mask="url(#a)"
    /> */}
  </svg>
  );
};
