import { createSlice, current } from "@reduxjs/toolkit";
import {
  getUserCardData,
  getLeaderboardList,
  getFilters,
  getUserDashboardData,
  getPointsSummaryData,
  getUserProfileData,
  getPointsLineChartData,
  getLeaderBoardActivityHistoryFilter,
  getPointsActivityHistoryFilter,
  getUserBadges,
  getBadgesFilter,
  getBadgesDetailFilter,
  getPointsHistoryByMonth,
  getPointsHistoryByMonthFilter,
  getResumeData,
  uploadResume,
} from "../actions/gamification";
import { ACCOUNT, CLUB, COUNTRY } from "../../constants";

type gamificationLandingPagedata = {
  profileData: any;
  pointsdata: any;
  userDashboardData: any;
  userProfileData: any;
  pointSummaryData: any;
  userCardData: any;
  leaderboardData: any;
  PointsLineChartData: any;
  gamificationdata: any;
  accountFilterData: any;
  countryFilterData: any;
  locationFilterData: any;
  clubFilterData: any;
  lockedBadgesFilter: any;
  badgesFilterData: any;
  myBadgesFilterData: any;
  badgeDatafilter: any;
  error: boolean;
  loading: boolean;
  loadingList: boolean;
  loadingProfile: boolean;
  leaderboardHistory: any;
  activityHistory: any;
  activeModulesPoints: any;
  activeMonthPoints: any;
  userBadges: any;
  loadingUserCardData: any;
  loadingMoreList: any;
  leaderBoardSelectedUser: any;
  loadingSummaryData: any;
  leaderBoardFilterBody: any;
  loadingLearderBoardFilter: any;
  isResumeAvailale: boolean;
  resumeData: Object;
  isFileError: Boolean;
  isFileSuccess: Boolean;
  isUplaodingResume: Boolean;
};

const initialDetailState: gamificationLandingPagedata = {
  profileData: [],
  pointsdata: {},
  leaderboardData: [],
  gamificationdata: {},
  userCardData: [],
  userBadges: [],
  accountFilterData: [],
  countryFilterData: [],
  locationFilterData: [],
  clubFilterData: [],
  lockedBadgesFilter: [],
  badgesFilterData: [],
  myBadgesFilterData: [],
  badgeDatafilter: [],
  error: false,
  loading: false,
  loadingList: false,
  loadingProfile: false,
  userDashboardData: {},
  userProfileData: {},
  pointSummaryData: {},
  PointsLineChartData: {},
  leaderboardHistory: {},
  activityHistory: {},
  activeModulesPoints: [],
  activeMonthPoints: "",
  loadingUserCardData: false,
  loadingMoreList: false,
  leaderBoardSelectedUser: false,
  loadingSummaryData: false,
  leaderBoardFilterBody: {
    account: [],
    country: [],
    location: [],
    clubs: [],
    key: "",
  },
  loadingLearderBoardFilter: false,
  isResumeAvailale: false,
  resumeData: {},
  isFileError: false,
  isFileSuccess: false,
  isUplaodingResume: false,
};

export const gamification: any = createSlice({
  name: "gamification",
  initialState: initialDetailState,
  reducers: {
    CloseExtraction: (state, action) => {
      state.isFileSuccess = false;
      state.isFileError = false;
    },
    setActiveModulePoints: (state, action) => {
      state.activeModulesPoints = action?.payload;
    },
    setActiveMonthPoints: (state, action) => {
      state.activeMonthPoints = action?.payload;
    },
    setActiveAccountFilters: (state, action) => {
      state.accountFilterData = action?.payload;
    },
    setActiveCountryFilters: (state, action) => {
      state.countryFilterData = action?.payload;
    },
    setActiveLocationFilters: (state, action) => {
      state.locationFilterData = action?.payload;
    },
    setActiveClubFilters: (state, action) => {
      state.clubFilterData = action?.payload;
    },
    setActiveLockedBadgesFilters: (state, action) => {
      state.badgesFilterData = action?.payload;
    },
    setActiveMyBadgesFilters: (state, action) => {
      state.myBadgesFilterData = action?.payload;
    },
    setEmptyLeaderboardData: (state, action) => {
      state.leaderboardData = {};
    },
    setLeaderBoardSelectedUSer: (state, action) => {
      state.leaderBoardSelectedUser = action?.payload;
    },
    setLeaderBoardFilter: (state, action) => {
      state.leaderBoardFilterBody = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeaderboardList.pending, (state) => {
      if (state.leaderboardData?.data?.length > 0) state.loadingMoreList = true;
      else state.loadingList = true;
      state.error = false;
    });
    builder.addCase(getLeaderboardList.fulfilled, (state: any, action) => {
      state.loadingList = false;
      state.loadingMoreList = false;
      if (state.leaderboardData?.data?.length >= 0)
        state.leaderboardData.data = [
          ...state.leaderboardData?.data,
          ...action.payload?.data,
        ];
      else state.leaderboardData = action?.payload;
    });
    builder.addCase(getLeaderboardList.rejected, (state: any, action) => {
      state.loadingList = false;
      state.loadingMoreList = false;
      state.error = true;
    });
    builder.addCase(getFilters.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getFilters.fulfilled, (state: any, action) => {
      state.loading = false;
      let orderedFilters: any = [];

      state.filterData = action?.payload;
      orderedFilters.push(
        action?.payload?.data?.filter((item) => item?.code === ACCOUNT)?.[0]
      );
      orderedFilters.push(
        action?.payload?.data?.filter((item) => item?.code === COUNTRY)?.[0]
      );
      orderedFilters.push(
        action?.payload?.data?.filter((item) => item?.code === CLUB)?.[0]
      );
      state.filterData.data = orderedFilters;
    });
    builder.addCase(getFilters.rejected, (state: any, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getUserCardData.pending, (state) => {
      state.loadingProfile = true;
      state.error = false;
    });
    builder.addCase(getUserCardData.fulfilled, (state: any, action) => {
      state.loadingProfile = false;
      state.userCardData = action?.payload;
    });
    builder.addCase(getUserCardData.rejected, (state: any, action) => {
      state.loadingProfile = false;
      state.error = true;
    });
    builder.addCase(getPointsSummaryData.pending, (state: any, action) => {
      state.loadingSummaryData = true;
      state.error = false;
    });
    builder.addCase(getPointsSummaryData.fulfilled, (state: any, action) => {
      state.loadingSummaryData = false;
      state.pointSummaryData = action?.payload;
    });
    builder.addCase(getPointsSummaryData.rejected, (state: any, action) => {
      state.loadingSummaryData = false;
      state.error = true;
    });
    builder.addCase(getPointsLineChartData.pending, (state: any, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getPointsLineChartData.fulfilled, (state: any, action) => {
      state.loading = false;
      state.PointsLineChartData = action?.payload;
      state.activityHistory = action?.payload?.data;
    });
    builder.addCase(getPointsLineChartData.rejected, (state: any, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getUserDashboardData.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getUserDashboardData.fulfilled, (state: any, action) => {
      state.loading = false;
      state.userDashboardData = action?.payload;
    });
    builder.addCase(getUserDashboardData.rejected, (state: any, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getUserProfileData.pending, (state: any, action) => {
      state.loadingUserCardData = true;
      state.error = false;
    });
    builder.addCase(getUserProfileData.fulfilled, (state: any, action) => {
      state.loadingUserCardData = false;
      state.userProfileData = action?.payload;
    });
    builder.addCase(getUserProfileData.rejected, (state: any, action) => {
      state.loadingUserCardData = false;
      state.error = true;
    });
    builder.addCase(getBadgesFilter.pending, (state: any, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getBadgesFilter.fulfilled, (state: any, action) => {
      state.loading = false;
      state.lockedBadgesFilter = action?.payload;
    });
    builder.addCase(getBadgesFilter.rejected, (state: any, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getBadgesDetailFilter.pending, (state: any, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getBadgesDetailFilter.fulfilled, (state: any, action) => {
      state.loading = false;
      state.lockedBadgesFilter = action?.payload;
    });
    builder.addCase(getBadgesDetailFilter.rejected, (state: any, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(
      getLeaderBoardActivityHistoryFilter.pending,
      (state: any, action) => {
        if (!state.leaderboardData?.data) {
          state.loadingLearderBoardFilter = true;
        }

        state.error = false;
      }
    );
    builder.addCase(
      getLeaderBoardActivityHistoryFilter.fulfilled,
      (state: any, action: any) => {
        state.loadingLearderBoardFilter = false;
        state.loadingList = false;
        state.loadingMoreList = false;

        if (
          state.leaderboardData?.data?.length >= 0 &&
          action?.payload?.start > 0
        )
          state.leaderboardData.data = [
            ...state.leaderboardData?.data,
            ...action.payload?.result?.data,
          ];
        else state.leaderboardData = action?.payload?.result;
      }
    );
    builder.addCase(
      getLeaderBoardActivityHistoryFilter.rejected,
      (state: any, action) => {
        state.loadingLearderBoardFilter = false;
        state.loadingList = false;
        state.loadingMoreList = false;
        state.error = true;
      }
    );
    builder.addCase(
      getPointsActivityHistoryFilter.pending,
      (state: any, action) => {
        state.loading = true;
        state.error = false;
      }
    );
    builder.addCase(
      getPointsActivityHistoryFilter.fulfilled,
      (state: any, action) => {
        state.loading = false;
        state.activityHistory = action?.payload?.data;
      }
    );
    builder.addCase(
      getPointsActivityHistoryFilter.rejected,
      (state: any, action) => {
        state.loading = false;
        state.error = true;
      }
    );
    builder.addCase(getUserBadges.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getUserBadges.fulfilled, (state: any, action) => {
      state.loading = false;
      state.userBadges = action?.payload;
    });
    builder.addCase(getUserBadges.rejected, (state: any, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getPointsHistoryByMonth.pending, (state) => {
      state.loadingMoreList = true;
      state.error = false;
    });
    builder.addCase(
      getPointsHistoryByMonth.fulfilled,
      (state: any, action: any) => {
        let tempData = {};
        state.loadingMoreList = false;

        let initialData = current(
          state?.activityHistory[action?.payload?.month].history
        );

        let apiData =
          action?.payload?.result?.data?.[action?.payload?.month]?.history;

        const mergeObjects = (obj1, obj2) => {
          const result = { ...obj1 };
          for (const [key, value] of Object.entries(obj2)) {
            if (key in result) {
              result[key] = result[key].concat(value);
            } else {
              result[key] = value;
            }
          }
          return result;
        };

        tempData = mergeObjects(initialData, apiData);

        state.activityHistory[action?.payload?.month].history = { ...tempData };
      }
    );
    builder.addCase(getPointsHistoryByMonth.rejected, (state: any, action) => {
      state.loadingMoreList = false;
      state.error = true;
    });
    builder.addCase(getPointsHistoryByMonthFilter.pending, (state) => {
      state.loadingMoreList = true;
      state.error = false;
    });
    builder.addCase(
      getPointsHistoryByMonthFilter.fulfilled,
      (state: any, action: any) => {
        let tempData = {};
        state.loadingMoreList = false;

        let initialData = current(
          state?.activityHistory[action?.payload?.month].history
        );

        let apiData =
          action?.payload?.result?.data?.[action?.payload?.month]?.history;

        const mergeObjects = (obj1, obj2) => {
          const result = { ...obj1 };
          for (const [key, value] of Object.entries(obj2)) {
            if (key in result) {
              result[key] = result[key].concat(value);
            } else {
              result[key] = value;
            }
          }
          return result;
        };

        tempData = mergeObjects(initialData, apiData);

        state.activityHistory[action?.payload?.month].history = { ...tempData };
      }
    );
    builder.addCase(
      getPointsHistoryByMonthFilter.rejected,
      (state: any, action) => {
        state.loadingMoreList = false;
        state.error = true;
      }
    );

    //get Resume Details
    builder.addCase(getResumeData.pending, (state) => {
      state.isResumeAvailale = false;
    });
    builder.addCase(getResumeData.fulfilled, (state: any, action: any) => {
      state.isResumeAvailale = true;
      state.resumeData = action?.payload;
    });
    builder.addCase(getResumeData.rejected, (state: any, action: any) => {
      if (action?.payload?.status) state.isResumeAvailale = false;
      //else error
    });

    //upload resume
    builder.addCase(uploadResume.pending, (state) => {
      state.isUplaodingResume = true;
      state.isFileSuccess = false;
    });
    builder.addCase(uploadResume.fulfilled, (state: any, action: any) => {
      state.isFileSuccess = true;
      state.isFileError = false;
      state.isResumeAvailale = true;
      state.isUplaodingResume = false;
      state.resumeData = action?.payload;
    });
    builder.addCase(uploadResume.rejected, (state: any, action: any) => {
      state.isFileSuccess = false;
      state.isFileError = false;
      state.isUplaodingResume = false;
    });
  },
});

export default gamification.reducer;
export const {
  setActiveModulePoints,
  setActiveMonthPoints,
  setActiveAccountFilters,
  setActiveCountryFilters,
  setActiveLocationFilters,
  setActiveClubFilters,
  setActiveLockedBadgesFilters,
  setActiveMyBadgesFilters,
  setEmptyLeaderboardData,
  setLeaderBoardSelectedUSer,
  setLeaderBoardFilter,
  CloseExtraction,
} = gamification.actions;
