import "./index.css";
import { EmployeeImg, LandingPageWizard } from "./images";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import { getSmartGameTemplate } from "../../../redux/actions/performance";
import { SmartGoalsModal } from "./SmartModal";
import {
  CHECK_MY_GOALS,
  GOAL_DESC,
  PLAY_GAME,
  SMART,
  SMART_GAME_DESC,
  SMART_GOALS,
  THE_SMART_GAME,
  VIJAY_DESC1,
  VIJAY_DESC2,
  VIJAY_INTRO,
  SMART_CARD_DATA,
} from "../../../constants";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import n from "../../navigation/Route.Names";
import amplitude from "amplitude-js";

const SmartCard = (props: any) => {
  const { title, letter, color, desc } = props;
  return (
    <div className="smart-card-container">
      <div className="smart-card-desc">{desc}</div>
      <div className="smart-card-footer">
        <div className="smart-card-letter" style={{ backgroundColor: color }}>
          {letter}
        </div>
        <div className="smart-card-title">{title}</div>
      </div>
    </div>
  );
};
const SmartGame = () => {
  const dispatch = useAppDispatch();
  const { smartGame, smartGameError } = useAppSelector(
    (state: any) => state.performance
  );
  const { questionList } = smartGame?.pageList?.[0]?.sectionList[0] || "";
  const [isGoalOpen, setIsGoalOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [noOfCorrectAnswers, setNoOfCorrectAnswers] = useState(0);
  const handleOpen = () => {
    setIsGoalOpen(true);
    amplitude.getInstance().logEvent("Smart Goals Play Game");
  };

  const handleClose = () => {
    setIsGoalOpen(false);
    setCurrentQuestion(0);
    setNoOfCorrectAnswers(0);
  };

  const currentYear = moment().format("YYYY");

  useEffect(() => {
    dispatch(getSmartGameTemplate({ year: currentYear }));
  }, []);

  const nextQuestion = (isCorrect: string, activeQuestion: number) => {
    if (currentQuestion < questionList?.length) {
      setCurrentQuestion(activeQuestion + 1);
    }
    if (isCorrect === "true")
      setNoOfCorrectAnswers((noOfCorrectAnswers) => noOfCorrectAnswers + 1);
  };

  const handlePlayAgain = () => {
    setCurrentQuestion(0);
    setNoOfCorrectAnswers(0);
  };

  const navigate = useNavigate();

  return (
    <div className="smart-game-container">
      <div className="smart-game-title">{SMART_GOALS}</div>
      <div className="smart-game-content">
        <div className="play-game-card">
          <div>
            <LandingPageWizard height={"100%"} />
          </div>
          <div className="game-card-content">
            <div className="game-card-title">{THE_SMART_GAME}</div>
            <div className="game-card-desc">{GOAL_DESC}</div>
            <div className="game-card-btn" onClick={handleOpen}>
              {PLAY_GAME}
            </div>
          </div>
        </div>
        <div className="smart-desc">{SMART_GAME_DESC}</div>
        <div className="smart-cards">
          {SMART_CARD_DATA.map((item, index) => (
            <SmartCard
              key={index}
              title={item.title}
              letter={item.letter}
              color={item.color}
              desc={item.desc}
            />
          ))}
        </div>
        <div className="check-goals-card">
          <div>
            <EmployeeImg />
          </div>
          <div className="check-goals-card-content">
            <div className="vijay-intro">{VIJAY_INTRO}</div>
            <div className="vijay-desc">
              {VIJAY_DESC1}
              <span className="smart-text">{SMART}</span> {VIJAY_DESC2}
            </div>
            <div
              onClick={() =>
                navigate(`/${n.PERFORMANCE_URL}/${n.SAMRT_GOAL_URL}`)
              }
              className="check-my-goals-btn"
            >
              {CHECK_MY_GOALS}
            </div>
          </div>
        </div>
      </div>
      <SmartGoalsModal
        currentQuestion={currentQuestion}
        totalItems={questionList}
        openModal={isGoalOpen}
        closeModal={handleClose}
        data={questionList?.[currentQuestion] || {}}
        nextQuestion={nextQuestion}
        noOfCorrectAnswers={noOfCorrectAnswers}
        handlePlayAgain={handlePlayAgain}
        error={smartGameError}
      />
    </div>
  );
};

export default SmartGame;
