import React from "react";
import { Row, Col, Rate } from "antd";
import "./CommonTable.css";
import "../Performance/ASPForm/aspform.css";
import {
  CALCULATED_RATING_GOALS,
  CALCULATED_RATING_DESC,
  CALCULATED_RATING_TITLE,
  CALCULATED_RATING_BEHAVIOURAL,
} from "../../constants";

const TableContent = (props: any) => {
  const { col1, col2, rating, nonHtmlData } = props;
  return (
    <>
      <Row className="row-sub-header">
        <Col span={8}>{col1} </Col>
        <Col
          span={12}
          dangerouslySetInnerHTML={{
            __html: col2,
          }}
          style={{ whiteSpace: nonHtmlData ? "pre-wrap" : "normal" }}
        />
        <Col span={4} className="rating-row">
          <div>
            <Rate disabled className={"custom-rating"} defaultValue={rating} />
          </div>
        </Col>
      </Row>
    </>
  );
};

const TableHeader = ({ header1, header2, header3 }) => {
  return (
    <Row className="row-main-header">
      <Col span={8}>{header1}</Col>
      <Col span={12}>{header2}</Col>
      <Col span={4}>{header3}</Col>
    </Row>
  );
};
const CommonTable = (props: any) => {
  const { goalDetails, evaluations } = props;
  return (
    <>
      <div>
        <TableHeader
          header1={CALCULATED_RATING_GOALS}
          header2={CALCULATED_RATING_DESC}
          header3={CALCULATED_RATING_TITLE}
        />
        {goalDetails?.map((ele: any) => {
          return (
            <TableContent
              col1={ele?.goalName}
              col2={ele?.goalDescription}
              rating={ele?.selfAssessment?.rateScale?.rating}
              nonHtmlData={true}
            />
          );
        })}
      </div>
      <div className="calculated-form-container">
        <TableHeader
          header1={CALCULATED_RATING_BEHAVIOURAL}
          header2={CALCULATED_RATING_DESC}
          header3={CALCULATED_RATING_TITLE}
        />
        {evaluations?.map((ele: any) => {
          return (
            <TableContent
              col1={ele.name}
              col2={ele.description}
              rating={ele?.overAllSelfAssessment?.rateScale?.rating}
              nonHtmlData={false}
            />
          );
        })}
      </div>
    </>
  );
};
export default CommonTable;
