import { createSlice } from "@reduxjs/toolkit";
import { saveCertification,getCertifications,updateCertifications,deleteCertifications,getSkills, downloadAttachment } from '../actions/certification';
import {CERTIFICATE_FAILURE,SUCCESS} from '../../constants'
import dayjs from 'dayjs';
import { base64ToFile, formatCertificate } from "../../components/utils/util";
import { upload } from "@testing-library/user-event/dist/upload";

type certificationState = {
  listcertifications: any,
  addcertifications: any,
  editcertifications: any,
  loading: boolean,
  reload: boolean,
  error: null,
  notification: any,
  listskills: any,
  isAdded: any,
  isUpdated: any,
  isDeleted: any,
};

const certificateRecord = {
  name: '',
  institution: '',
  startDate: null,
  endDate: null,
  description: '',
  skill: [],
  upload: [],
  isNew: true,
  isChanged: false,
  base64File: '',
  fileName: '',
  certificateId: '',
  attachmentId: '',
}

const initialCertificationState: certificationState = {
  listcertifications: [],
  addcertifications: [certificateRecord],
  editcertifications: [certificateRecord],
  loading: false,
  error: null,
  reload: false,
  notification: {message:'',isShown:false,status:''},
  listskills:[],
  isAdded: false,
  isUpdated: false,
  isDeleted: false,
};

export const certification = createSlice({
  name: "certification",
  initialState: initialCertificationState,
  reducers: {
    addMore: (state, action) => {
      state.addcertifications.push(certificateRecord)
    },
    previewCertification: (state, action) => {
      const preview = action.payload
      const addcertifications = preview.map((item:any)=>{
        let upload = item?.upload
        if(upload?.file?.status==='removed'){
          upload =[]
        }
        return {...item,upload:upload}
      })
      state.addcertifications=addcertifications
    },
    closeNotification: (state, action) => {
      state.notification.isShown=false
      state.notification.status=''
      state.isUpdated=false
      state.isDeleted=false
    },
    editCertification: (state, action) => {
      const {certification_id} = action.payload
      let filterdata:string[] = []
        state.listcertifications.forEach((item:any)=>{
          if(item.certificateId===certification_id){
            const {order,...restobject} = item
            filterdata.push({...restobject,startDate:item.startDate?dayjs(item.startDate, 'YYYY-MM-DD'):null,endDate:item.endDate?dayjs(item.endDate, 'YYYY-MM-DD'):null})
          }
        })
      state.editcertifications=filterdata
    },
    reset: (state, action) => {
      state.addcertifications = [certificateRecord]
    },
    download: (state, action) => {
      const {fileName,mimeType,fileContent} = action.payload;
      const linkSource = `data:${mimeType};base64,${fileContent}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
  },

  extraReducers: (builder) => {
    builder.addCase(saveCertification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveCertification.fulfilled, (state: any, action) => {
      state.loading = false;
      const status = action.payload;
      state.notification.isShown = true
      state.notification.status = status        
      if(status===SUCCESS){
        state.listcertifications= []
        state.addcertifications= [certificateRecord]
      }             
      state.reload = true
    });
    builder.addCase(saveCertification.rejected, (state: any, action) => {
      state.loading = false;
      state.error = action.payload;
      state.notification.isShown = true
      state.notification.message = CERTIFICATE_FAILURE
      state.notification.status = action.payload      
    });
    builder.addCase(getCertifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCertifications.fulfilled, (state: any, action:any) => {      
      const listCertifications = action.payload 
      state.listcertifications=formatCertificate(listCertifications,state.listskills)
      state.loading = false;
    });
    builder.addCase(getCertifications.rejected, (state: any, action) => {
      state.loading = false;
      state.error = action.payload;
      state.notification.isShown = true
      state.notification.message = CERTIFICATE_FAILURE
      state.notification.status = action.payload       
    });
    builder.addCase(getSkills.pending, (state) => {
      //state.loading = true;
    });
    builder.addCase(getSkills.fulfilled, (state: any, action:any) => {
      //state.loading = false;
      const skills = action.payload
      const formattedSkills = skills.map((element:any)=>{
        return {
          label: element.labelEnUS,
          value: element.labelEnUS,
          title: element.optionId,
          key: element.optionId,
        }
      })
      state.listskills=formattedSkills
    });
    builder.addCase(getSkills.rejected, (state: any, action) => {
      state.loading = false;
      state.error = action.payload;    
    });
    builder.addCase(updateCertifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCertifications.fulfilled, (state: any, action:any) => {
      state.loading = false;
      const [edit_certification] = action.payload.data
      const status=action.payload.status
      state.notification.status = status        
      if(status===SUCCESS){
      const filterdata= state.listcertifications.map((item:any)=>{
        if(item.certificateId===edit_certification.certificateId){
          if(edit_certification.fileContent){
            const sfAttachment = {
              "module": "EMPLOYEE_PROFILE",
              "moduleCategory": "HRIS_ATTACHMENT",
              "fileName": edit_certification.fileName,
              "fileContent":edit_certification.fileContent,
              "attachmentId":item.attachmentId,
              "upload":[{
                name: edit_certification.fileName,
                status:'done'      
              }],
              "mimeType": edit_certification.mimeType,
          }
          return {...edit_certification,...sfAttachment}
        }else if(edit_certification?.upload?.file?.status==='removed'){
          return {...edit_certification,upload:[]}
        }
        return {...edit_certification}
        }else{
          return {...item}
        }
      })
      state.reload = false
      state.listcertifications=filterdata      
      state.isUpdated=true
    }
    });
    builder.addCase(updateCertifications.rejected, (state: any, action) => {
      state.loading = false;
      state.error = action.payload;
      state.notification.isShown = true
      state.notification.message = CERTIFICATE_FAILURE
      state.notification.status = action.payload  
      state.reload = false    
    });
    builder.addCase(deleteCertifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCertifications.fulfilled, (state: any, action:any) => {
      state.loading = false;
      const {status,data:{certification_id}}=action.payload
      if(status===SUCCESS){
        state.listcertifications=state.listcertifications.filter((item:any)=>item.certificateId!==certification_id);
      }
      state.reload = false        
      state.isDeleted=true     
    });
    builder.addCase(deleteCertifications.rejected, (state: any, action) => {
      state.loading = false;
      state.error = action.payload;
      state.notification.isShown = true
      state.notification.message = CERTIFICATE_FAILURE
      state.notification.status = action.payload 
      state.reload = false      
    });
    builder.addCase(downloadAttachment.pending,(state) => {
      //state.loading = true;
    });
    builder.addCase(downloadAttachment.fulfilled,(state, action) => {
      //state.loading = false;
      //state.details = action.payload;
      const {fileName,mimeType,fileContent} = action.payload;
      const linkSource = `data:${mimeType};base64,${fileContent}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
    builder.addCase(downloadAttachment.rejected,(state:any, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  }
});

export default certification.reducer;

export const { addMore,previewCertification,closeNotification,reset,editCertification,download } = certification.actions;