import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Spin } from "antd";
import "./Certification.css";
import n from "../navigation/Route.Names";
import {
  BACK,
  CERTIFICATIONS,
  SUBMIT,
  EDIT_TITLE,
  EDIT_MESSAGE,
  PROCEED,
  CANCEL,
  CERTIFICATE_UPDATE_SUCCESSFULL,
  DONE,
  RETRY,
  SUCCESS,
  CERTIFICATE_UPDATE_FAILED,
  FAILURE,
  ADD_CERTIFICATION_URL,
  START,
  PREVIEW,
  CERTIFICATION_URL,
  ALTIVERSE,
  ALTIVERSE_BREADCRUMP,
  CERTIFICATION,
  EDIT_CERTIFICATION_BREADCRUMP,
} from "../../constants";
import OutlineButton from "../common/button/OutlineButton";
import { useAppSelector, useAppDispatch } from "../../redux/store/redux-hooks";
import {
  getSkills,
  updateCertifications,
} from "../../redux/actions/certification";
import RenderCertificateForm from "./RenderCertificateForm";
import { dateFormat } from "../utils/util";
import ModalDialog from "../common/modal/modal";
import CertificateUpdateForm from "./CertificateUpdateForm";
import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";

export default function EditCertification() {
  const [formValid, setFormValid] = useState(true);
  const [isPopup, setPopup] = useState({
    isLeave: false,
    isNotification: false,
    page: "",
  });
  const Certification = useAppSelector((state: any) => state.certification);
  const dispatch = useAppDispatch();
  const {
    editcertifications = [],
    loading,
    notification: { status },
    listskills,
  } = Certification;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = (values: any) => {
    const processRequest = values.certificates;
    const request: string[] = [];
    processRequest.forEach((item: any) => {
      const { endDate, startDate, ...rest } = item;
      const newrecord = {
        ...rest,
        startDate: startDate ? dateFormat(startDate) : "",
        endDate: endDate ? dateFormat(endDate) : "",
      };
      request.push(newrecord);
    });
    dispatch(updateCertifications(request))
      .unwrap()
      .then((res) => {
        //setPopup({ ...isPopup, isLeave: false, isNotification: true });
        navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleDelete = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onValuesChange = async (values: any) => {
    try {
      const [oldRecord] = editcertifications;
      const [newRecord] = values.certificates;
      const [key] = Object.keys(newRecord);
      var a = JSON.stringify(oldRecord[key]);
      var b = JSON.stringify(newRecord[key]);
      if (
        key === "upload" &&
        oldRecord.upload.length === 0 &&
        newRecord.upload.file.status === "removed"
      ) {
        setFormValid(true);
      } else {
        if (a !== b) {
          setFormValid(false);
        } else {
          setFormValid(true);
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      editcertifications?.[0]?.name === "" &&
      editcertifications?.[0]?.institution === "" &&
      editcertifications?.[0]?.startDate === null &&
      editcertifications?.[0]?.endDate === null &&
      editcertifications?.[0]?.description === ""
    ) {
      navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
    }
  }, []);

  const showEditModal = (page) => {
    const newRecord = form.getFieldsValue().certificates;
    if (JSON.stringify(editcertifications) !== JSON.stringify(newRecord)) {
      setPopup({ ...isPopup, isLeave: true, page: page });
    } else {
      if (page === ALTIVERSE) {
        navigate(`/${n.START}/${n.ALTIVERSE}`);
      } else if (page === CERTIFICATION_URL) {
        navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
      }
    }
  };

  const handleOk = () => {
    if (isPopup.isLeave) {
      setPopup({ ...isPopup, isLeave: false });
      if (isPopup.page === ALTIVERSE) {
        navigate(`/${n.START}/${n.ALTIVERSE}`);
      } else if (isPopup.page === Certification) {
        navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
      }
      //navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
    } else {
      setPopup({ ...isPopup, isNotification: false });
      if (status !== FAILURE) {
        if (isPopup.page === ALTIVERSE) {
          navigate(`/${n.START}/${n.ALTIVERSE}`);
        } else if (isPopup.page === Certification) {
          navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
        }
        // navigate(`/${n.START}/${n.CERTIFICATION_URL}`);
      }
    }
  };

  const handleEditCancel = () => {
    setPopup({ ...isPopup, isLeave: false });
  };

  const dateChange = (e: any, index: number) => {
    const data = form.getFieldValue("certificates");
    if (e) {
      data[index] = { ...data[index], ["startDate"]: e };
    } else {
      data[index] = { ...data[index], ["startDate"]: e, ["endDate"]: "" };
    }
    form.setFieldValue("certificates", data);
  };
  const valChange = (data: any) => {
    const [oldRecord] = editcertifications;
    var a = JSON.stringify(oldRecord["skill"]);
    var b = JSON.stringify(data);
    if (a !== b) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  };

  const fieldChange = (
    skills: any,
    index: number,
    changeFiled: string,
    base64File: string,
    fileName: string,
    type: string,
    isError: boolean
  ) => {
    const formData = form.getFieldValue("certificates");
    const filteredData = formData.map((data: any, skillindex: any) => {
      if (index === skillindex) {
        if (changeFiled === "skill") {
          return { ...data, skill: skills };
        } else if (changeFiled === "removeSkill") {
          valChange(skills);
          return { ...data, skill: skills };
        } else if (changeFiled === "addimage") {
          if (isError) {
            const isList = (data.bckUpload || []).filter(
              (ele: any) => ele.status === "done"
            );
            return { ...data, upload: isList.length > 0 ? isList : [] };
          }
          return {
            ...data,
            fileContent: base64File,
            fileName: fileName,
            attachmentId: null,
            mimeType: type,
          };
        } else if (changeFiled === "removeimage") {
          return {
            ...data,
            upload: [],
            fileContent: "",
            fileName: "",
            attachmentId: null,
            mimeType: "",
          };
        }
      } else {
        return { ...data };
      }
    });
    form.setFieldValue("certificates", filteredData);
  };
  const [showInitialDiv, setShowInitialDiv] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      setShowInitialDiv(window.scrollY <= 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const redirection = (e: any, page: string) => {
    e.preventDefault();
    showEditModal(page);
  };
  const items = [
    {
      title: (
        <Link
          to={`/${START}/${ALTIVERSE}`}
          onClick={(e) => redirection(e, ALTIVERSE)}
          className="link-highlight"
        >
          {ALTIVERSE_BREADCRUMP}
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/${START}/${CERTIFICATION_URL}`}
          onClick={(e) => redirection(e, CERTIFICATION_URL)}
          className="link-highlight"
        >
          {CERTIFICATION}
        </Link>
      ),
    },
    {
      title: `${EDIT_CERTIFICATION_BREADCRUMP}`,
    },
  ];

  return (
    <Spin spinning={loading} delay={500}>
      <div className="certify_main-otr">
        <Form
          form={form}
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          onValuesChange={onValuesChange}
        >
          {/* <div className="Seperation-page-title">{CERTIFICATIONS}</div> */}
          <div className="hidestricky_breadcrump">
            <BreadcrumbComponent items={items} />
            <div className="Seperation-page-title">{CERTIFICATIONS}</div>
          </div>
          {!showInitialDiv && (
            <div className="headingWrapper">
              <BreadcrumbComponent items={items} />
              <div className="Seperation-page-title certification-page-title">
                {CERTIFICATIONS}
              </div>
            </div>
          )}
          <Form.List name={["certificates"]} initialValue={editcertifications}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <CertificateUpdateForm
                    key={key}
                    index={index}
                    showModal={showModal}
                    isModalOpen={isModalOpen}
                    remove={remove}
                    handleDelete={handleDelete}
                    handleCancel={handleCancel}
                    restField={restField}
                    name={name}
                    addcertifications={editcertifications}
                    dateChange={dateChange}
                    fieldChange={fieldChange}
                    listskills={listskills}
                  />
                ))}
                <div className="btn_otr-sec edit-certify">
                  <div className="certify_btn-otr">
                    {/* <Form.Item className="certify-back">
                      <OutlineButton
                        backText={BACK}
                        outlineBtnAction={showEditModal}
                      />
                    </Form.Item> */}
                    <Form.Item className="certify-prev">
                      <Button htmlType="submit" disabled={formValid}>
                        {SUBMIT}
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </Form.List>
        </Form>
        <ModalDialog
          openModal={isPopup.isLeave || isPopup.isNotification}
          DialogHeading={isPopup.isLeave ? EDIT_TITLE : null}
          closeIcon={false}
          modalImageStyles={{ display: "none" }}
          modalHeadingStyles={{ display: "none" }}
          msgContent={
            isPopup.isLeave
              ? EDIT_MESSAGE
              : status === SUCCESS
              ? CERTIFICATE_UPDATE_SUCCESSFULL
              : CERTIFICATE_UPDATE_FAILED
          }
          okTxt={isPopup.isLeave ? PROCEED : status === SUCCESS ? DONE : RETRY}
          cancelTxt={CANCEL}
          cancelBtn={handleEditCancel}
          okBtn={handleOk}
          onOk={handleOk}
          onCancel={handleEditCancel}
          isSingleButton={isPopup.isNotification}
          isCancel={isPopup.isNotification}
          modalStyles={"backpopup"}
          maskClosable={false}
        />
      </div>
    </Spin>
  );
}
