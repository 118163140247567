export const headerList = [
  {
    key: "id",
    id: "id",
    title: "Survey ID",
    isFiltered: true,
    isSort: true,
  },
  {
    key: "type",
    id: "type",
    title: "Type",
    isFiltered: true,
    isSort: true,
  },
  {
    key: "questId",
    id: "questId",
    title: "Question ID",
    isFiltered: true,
    isSort: true,
  },
  {
    key: "question",
    id: "question",
    title: "Question",
    isFiltered: true,
    isSort: true,
  },
  {
    key: "createdAt",
    id: "createdAt",
    title: "Created Date",
    isFiltered: true,
    isSort: true,
  },
  {
    key: "actionType",
    title: "Publish To Prod",
    isSort: false,
    isFiltered: false,
  },
  {
    key: "status",
    title: "Action",
    isSort: false,
    isFiltered: false,
  },
];

export const tableData: any = [
  {
    id: "56789fd9989",
    type: "Test 1",
    questionId: "123",
    question: "Testing 1",
    date: "5/4/2023",
    publish: "Publish to PROD",
    action: true,
  },
  {
    id: "66789fd998",
    type: "Test 2",
    questionId: "345",
    question: "Testing 2",
    date: "10/5/2023",
    publish: "Publish to PROD",
    action: true,
  },
  {
    id: "76789fd998",
    type: "Test 3",
    questionId: "567",
    question: "Testing 3",
    date: "15/6/2023",
    publish: "Publish to PROD",
    action: true,
  },
];
// Content section data

export const ContentHeaderList = [
  {
    key: "_id",
    id: "_id",
    title: "Content ID",
    isFiltered: true,
    isSort: true,
  },
  {
    key: "type",
    id: "type",
    title: "Type",
    isFiltered: true,
    isSort: true,
  },
  {
    key: "title",
    id: "title",
    title: "Title",
    isFiltered: true,
    isSort: true,
  },
  {
    key: "category",
    id: "category",
    title: "Category",
    isFiltered: true,
    isSort: true,
  },
  {
    key: "createdDate",
    id: "createdDate",
    title: "Created Date",
    isFiltered: true,
    isSort: true,
  },
  {
    key: "actionType",
    title: "Publish To Prod",
    isSort: false,
    isFiltered: false,
  },
  {
    key: "status",
    title: "Action",
    isSort: false,
    isFiltered: false,
  },
];

export const contentTableBody: any = [
  {
    order: "111",
    type: "Testing 1",
    content: "Ajay",
    status: "In progress",
    date: "5/4/2023",
    action: true,
  },
  {
    order: "222",
    type: "Testing 2",
    content: "Balaji",
    status: "Pending",
    date: "5/4/2023",
    action: true,
  },
  {
    order: "333",
    type: "Testing 3",
    content: "Chandu",
    status: "Completed",
    date: "5/4/2023",
    action: true,
  },
];
