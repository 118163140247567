import React, { useState, useEffect } from "react";
import { List, Card } from "antd";
import moment from "moment";
import "./LandingPage.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const CalendarComponent = () => {
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);
  const [visibleEvents, setVisibleEvents] = useState(2);
  const [currentView, setCurrentView] = useState("month");
  const [events, setEvents] = useState([]);
  const [specificDateEvent, setSpecificDateEvent] = useState([]);
  const todaysDate = moment();
  const [date, setDate] = useState(todaysDate.format("MMMM D, YYYY"));
  const navigate = useNavigate();

  useEffect(() => {
    fetchEventsForFullMonth();
    fetchEventsForSpecificDate();
  }, [currentView]);

  const handleEvents = (eventId) => {
    {
      eventId && navigate(`/detailedEventScreen/${eventId}`);
    }
  };

  const fetchEventsForFullMonth = async (date) => {};
  // const getEventListForDate = (date, eventsData) => {
  //   const formattedDate = moment(date).format("YYYY-MM-DD");
  //   const eventsForDate = eventsData.filter((event) =>
  //     event.dateList.includes(formattedDate)
  //   );
  //   return eventsForDate;
  // };

  const fetchEventsForSpecificDate = async (date) => {
    // const month = moment(date).format('MMMM');
    // const year = moment(date).format("YYYY");
    // try {
    //   const response = await gettingEventsForspecificDate(month, year);
    //   setSpecificDateEvent(response.data);
    //   return response.data
    // } catch (error) {
    //   console.error('Error while fetching events for specific date:', error);
    //   const errorMessage = error.response.error
    //   console.log(errorMessage, "errorMessage")
    //   return [];
    // }
  };

  const handleSelectSlot = async ({ start, end, event }) => {
    const selectedDate = moment(start).format("YYYY-MM-DD");
    const formattedEventDate = moment(selectedDate).format("MMMM D, YYYY");
    setDate(formattedEventDate);
    const selectedEvents = specificDateEvent.filter((event) =>
      moment(event.date).isSame(selectedDate, "day")
    );
    console.log(selectedEvents, "selectedEvents");
    if (selectedEvents.length > 0) {
      setSelectedDateEvents(selectedEvents[0].eventsEntityList);
    } else {
      const response = await fetchEventsForSpecificDate(selectedDate);
      setSpecificDateEvent(response);
      const selectedEventsAfterFetchingData = response.filter((event) =>
        moment(event.date).isSame(selectedDate, "day")
      );
      if (selectedEventsAfterFetchingData.length > 0) {
        console.log(selectedEventsAfterFetchingData);
        setSelectedDateEvents(
          selectedEventsAfterFetchingData[0].eventsEntityList
        );
      } else {
        console.log("error fetching data");
        setSelectedDateEvents([]);
      }
    }
  };
  const EventComponent = ({ event }) => {
    const [backgroundColor, setBackgroundColor] = useState(
      event.backgroundColor
    );
    const [textColor, setTextColor] = useState(event.textColor);

    useEffect(() => {
      setBackgroundColor(event.backgroundColor);
      setTextColor(event.textColor);
    }, [event.backgroundColor, event.textColor]);
    console.log(event);
    // const startTime = event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    // const endTime = event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return (
      <>
        <div
          className="displayEvents"
          style={{ pointerEvents: "none" }}
          // style={{ display: 'flex', alignItems: 'center' }}
        >
          <div
            style={{
              backgroundColor: backgroundColor,
              color: textColor,
              padding: "5px 5px",
              borderRadius: "4px",
              borderLeft: `4px solid ${textColor}`,
              fontSize: "10px",
              fontWeight: "600",
            }}
          >
            {event.startTime}
            {event.title}
          </div>
        </div>
        {/* <div style={{marginTop: "20px"}}>
               {!isFullyVisible &&
                //  isMonthView &&  numHiddenEvents &&
                <div
               style={{
                 width: '10px',
                 height: '10px',
                 borderRadius: '50%',
                 marginRight: '5px',
                  backgroundColor: textColor
               }}
               >
             </div> 
          }
          </div> */}
      </>
    );
  };

  const handleNavigate = (newDate, view) => {
    if (view === "month") {
      fetchEventsForSpecificDate(newDate);
      fetchEventsForFullMonth(newDate);
    }
  };

  const handleEventDrop = () => {};

  const isEventFullyVisible = (index) => {
    return index < visibleEvents;
  };

  // const getEventsForDate = (date) => {
  //   const formattedDate = moment(date).format("YYYY-MM-DD");
  //   return events.filter((event) => event.dateList.includes(formattedDate));
  // };

  return (
    <div className="mainContainer">
      <div className="calendarContainer">
        <DnDCalendar
          selectable
          localizer={localizer}
          events={events.map((event, index) => ({ ...event, index }))}
          // events={events}
          startAccessor="start"
          endAccessor="end"
          defaultView="month"
          views={["month", "week", "day"]}
          onView={(view) => setCurrentView(view)}
          onNavigate={(newDate, view) => handleNavigate(newDate, view)}
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectSlot}
          onEventDrop={handleEventDrop}
          components={{
            event: ({ event, event: { index } }) => (
              <EventComponent
                event={event}
                isFullyVisible={isEventFullyVisible(index)}
                // visibleEvents={visibleEvents}
              />
            ),
          }}
          style={{ height: "100%", aspectRatio: 1, width: "700px" }}
        />
      </div>
      <div className="eventsWrapper">
        <div className="eventsDuration">
          <div className="CalendarDate">{date}</div>
          <div className="bookings">{selectedDateEvents.length} bookings</div>
        </div>
        <div>
          <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={selectedDateEvents}
            renderItem={(event) => (
              <List.Item key={event.eventId}>
                <Card
                  className="EventDisplayCards"
                  onClick={() => handleEvents(event.eventId)}
                  {...console.log(event, "event")}
                  style={{
                    backgroundColor: event.icons.bgColor,
                    color: event.icons.textColor,
                    borderLeft: `4px solid ${event.icons.textColor}`,
                  }}
                >
                  <div>
                    <div className="eventTypeWrapper">
                      <div className="eventType">
                        {" "}
                        <img src={event.icons.iconRefUrl} />
                        {event.eventType}
                      </div>
                      {event.holiday === false ? (
                        <div className="eventTimimgs">
                          {event.startTime} - {event.endTime}
                        </div>
                      ) : (
                        "All day"
                      )}
                    </div>
                    <div className="eventDescription">{event.description}</div>
                  </div>
                </Card>
              </List.Item>
            )}
          ></List>
        </div>
      </div>
    </div>
  );
};
export default CalendarComponent;
