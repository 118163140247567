import React, { useEffect, useState } from "react";
import "./Preview.css";
import {
  useNavigate,
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";
import FillButton from "../common/button/FillButton";
import {
  ADD_GOAL,
  BREAK_INTOMILESTONE_CHUNKS,
  CANCEL,
  CATEGORY_VIEW,
  DISCARD_BUTTON,
  EDIT_CASCADEDGOAL_TXT,
  EDIT_GOAL,
  EDIT_GOAL_URL,
  FAILURE,
  GOAL_DESCRIPTION_VIEW,
  GOAL_METRIC_VIEW,
  GOAL_NAME_VIEW,
  GOAL_OUTCOME_VIEW,
  GOAL_START_AND_END_DATE_VIEW,
  GOAL_VISIBILITY_VIEW,
  GOAL_WEIGHT_VIEW,
  MANAGAER_CASCADE_GOAL_URL,
  MANAGER_CMNT_ADDED_TOAST,
  MILESTONE_TITLE,
  NOTIFICATION_DURATION,
  PERFORMANCE,
  PERFORMANCE_BREADCRUMB,
  PREVIEW_BREADCRUMB,
  QUESTION_DTO,
  SAVE_DRAFT_BUTTON,
  SAVE_DRAFT_MESSAGE,
  SAVE_DRAFT_TITLE,
  SEND,
  SUCCESS,
  TEAM_PERFORMANCE,
  TEAMS_PERFORMANCE,
  TEAMS_GOAL,
  UPDATE_GOAL,
  WARNING,
  EMPLOYEE_LIST,
  SUBMITTED_GOAL,
  CASCADE_GOAL,
  DRAFT_POPUP_MSG,
  DRAFT_POPUP_TITLE,
} from "../../constants";
import OutlineButton from "../common/button/OutlineButton";
import {
  PREVIEW_MAIN_TITLE,
  COMMENTS_MANAGER_TITLE,
  REMAINING_TXT,
} from "../../constants";
import { Button, notification, Spin } from "antd";
import n from "../navigation/Route.Names";
import CommentDialog from "./components/CommentDialog";
import { Input } from "antd";
import {
  CLOSE,
  COMMENTS_MANAGER,
  PREVIEW_COMMENT_TITLE,
  VIEW_COMMENT_TITLE,
  NEW_GOAL_BREAD_TXT,
} from "../../constants";
import {
  notificationAction,
  viewState,
} from "../../redux/reducers/performance";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
import { saveGoal, saveGoalComment } from "../../redux/actions/performance";
import Milestones from "./Milestones";
import ModalDialog from "../common/modal/modal";
import { goalVisibility, goalWeight } from "../utils/util";
import {
  GoalsErrorIcon,
  GoalsInfoIcon,
  ToastSuccessIcon,
} from "../common/svg/svg";
import { isNull } from "lodash";
import DraftPopupImage from "../../themes/assets/images/performance/svg/DraftGoalStatus.svg";
import amplitude from "amplitude-js";

const { TextArea } = Input;

const Preview = () => {
  const [api, contextHolder] = notification.useNotification({
    top: 0,
    maxCount: 1,
  });
  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const {
    employeeViewGoal: goalValues,
    viewGoal: goalData,
    sectionList,
    goalPayload,
    milestonePayload,
    isLoading,
    configuration,
  } = useAppSelector((state: any) => state?.performance);
  const [comment, setComment] = useState(goalValues?.commentInfo || null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const cascadeStatus = searchParams.get("cascadedGoal") === "true";
  const goalsTabKey = location?.state?.goalsTabKey;

  const paramGoalId = param?.goalId;
  const year = param?.year;
  let isPublic = paramGoalId?.includes("public");

  const {
    data,
    savePayload: payloadFromEditGoal,
    isNew, //new goal
    manager, //managerflow
    reporteeId,
    userName,
  } = location?.state || {};
  const [viewCmnt, setViewCmnt] = useState<any>(
    goalData?.commentInfo ? true : goalValues?.commentInfo ? true : false
  ); //manager : goalData

  let viewer = location?.pathname?.includes(TEAM_PERFORMANCE)
    ? false
    : location?.pathname?.includes(TEAM_PERFORMANCE) &&
      location?.pathname?.includes(MANAGAER_CASCADE_GOAL_URL)
    ? false
    : true; // manager =false, user=true, cascade=false
  const [empView, setEmpView] = useState(viewer); //manger=false or user=true
  const remainingCharacters = 300 - Number(comment?.length || 0);

  const backHandler = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const fillBtnAction = (value: object) => {
    saveOrDraft("SAVE");
    amplitude.getInstance().logEvent("Submit Goal");
  };

  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
        additionalInfo: "",
      })
    );
  };

  useEffect(() => {
    if (configuration.isShowNotification) {
      const type = configuration.alertMessage.type;
      api[type]({
        message: configuration.alertMessage.message,
        description: configuration.alertMessage.additionalInfo,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon:
          type === SUCCESS ? (
            <ToastSuccessIcon />
          ) : type === FAILURE ? (
            <GoalsErrorIcon />
          ) : (
            <GoalsInfoIcon />
          ),
        className:
          type === SUCCESS
            ? `custom-toast-area custom-success-toast`
            : type === FAILURE
            ? `custom-toast-area custom-error-toast`
            : `custom-toast-area custom-info-toast`,

        onClose: onCloseHandler,
      });
    }
  }, [configuration.isShowNotification]);

  const saveOrDraft = (status: string) => {
    let sfGoalId = "";
    let goalId = "";
    let publicGoalId = "";
    let isPublic = paramGoalId?.includes("public");
    if (paramGoalId) {
      let id = paramGoalId.split("-");
      const [isSf, gid] = id;
      if (isSf === "sf") {
        sfGoalId += `${gid}`;
      } else if (isSf === "dex") {
        goalId += `${gid}`;
      } else {
        publicGoalId += `${gid}`;
      }
    }

    dispatch(
      saveGoal({
        year: year,
        questionnaireCategory: "WEB_GOAL",
        sfGoalId: sfGoalId,
        payload: goalPayload,
        status: status,
        method: paramGoalId && !isPublic ? "put" : "post",
        goalId: goalId,
        publicGoalId: publicGoalId,
      })
    )
      .unwrap()
      .then((data: any) => {
        const { result } = data;
        if (result.status !== "error") {
          dispatch(
            saveGoal({
              year: year,
              questionnaireCategory: "WEB_MILESTONE",
              sfGoalId: paramGoalId ? sfGoalId : "",
              payload: milestonePayload,
              status: status,
              method: paramGoalId && !isPublic ? "put" : "post",
              goalId: paramGoalId ? goalId : result.goalId,
              //publicGoalId: paramGoalId ? publicGoalId : "",
              publicGoalId: paramGoalId
                ? isPublic
                  ? result.goalId
                  : goalId
                : result.goalId,
            })
          )
            .unwrap()
            .then((data: any) => {
              navigate(`/${n.PERFORMANCE_URL}`, {
                state: {
                  locationActiveIndex: 1,
                },
              });
            })
            .catch((e: any) => {
              console.log(e);
            });
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const cancelBtn = (value: object) => {
    if (paramGoalId) {
      const baseUrl = `/${n.PERFORMANCE_URL}/${EDIT_GOAL_URL}/${year}/${paramGoalId}`;
      const url = cascadeStatus ? `${baseUrl}?cascadedGoal=true` : baseUrl;
      navigate(url);
    } else {
      navigate(`/${n.PERFORMANCE_URL}/${n.NEW_GOAL_URL}/${year}`);
    }
  };
  const handleCancel = () => {
    setIsCommentModalOpen(false);
    setViewCmnt(empView ? true : goalData?.commentInfo ? true : false);
  };
  const handleCancelHandler = () => {
    saveOrDraft("DRAFT");
  };

  const handleOk = () => {
    setIsModalOpen(false);
    navigate(`/${n.PERFORMANCE_URL}`);
  };

  const handleSaveComment = () => {
    const { userId, goalId, sfGoalId, index, category } = data || {};
    setIsCommentModalOpen(false);
    amplitude.getInstance().logEvent("Add Comment Manager");
    dispatch(
      saveGoalComment({
        year,
        userId,
        payload: {
          comment,
        },
        goalId,
        sfGoalId,
        index,
        category,
        message: MANAGER_CMNT_ADDED_TOAST,
      })
    )
      .unwrap()
      .then((data) => {
        navigate(`/web/my_work/${"team_performance"}/detail/${userId}`, {
          state: { performanceTabKey: "2" },
        });
      });
  };

  const handleEdit = () => {
    setComment(goalData?.commentInfo?.comment);
    setViewCmnt(false);
  };

  const handleBreadcrumbClick = () => {
    const url = isNull(goalsTabKey)
      ? `/${n.START}/${n.MYWORK_URL}/${TEAM_PERFORMANCE}/${MANAGAER_CASCADE_GOAL_URL}`
      : `/${n.START}/${n.MYWORK_URL}/${TEAM_PERFORMANCE}`;
    navigate(url, { state: { goalsTabKey } });
  };

  const employeeflowBreadcrumb = [
    {
      title: (
        <Link
          to={`/${n.PERFORMANCE_URL}`}
          className="link-highlight"
          // onClick={(e) => backHandler(e)}
        >
          {PERFORMANCE_BREADCRUMB}
        </Link>
      ),
    },
    {
      title: paramGoalId ? (
        isPublic ? (
          <Link
            to={`/${n.PERFORMANCE_URL}/${EDIT_GOAL_URL}/${year}/${paramGoalId}`}
            className="link-highlight"
          >
            {EDIT_GOAL}
          </Link>
        ) : cascadeStatus ? (
          <Link
            to={`/${n.PERFORMANCE_URL}/${EDIT_GOAL_URL}/${year}/${paramGoalId}?cascadedGoal=true`}
            className="link-highlight"
          >
            {EDIT_CASCADEDGOAL_TXT}
          </Link>
        ) : (
          <Link
            to={`/${n.PERFORMANCE_URL}/${EDIT_GOAL_URL}/${year}/${paramGoalId}`}
            className="link-highlight"
          >
            {EDIT_GOAL}
          </Link>
        )
      ) : (
        <Link
          to={`/${n.PERFORMANCE_URL}/${n.NEW_GOAL_URL}/${year}`}
          className="link-highlight"
        >
          {NEW_GOAL_BREAD_TXT}
        </Link>
      ),
    },
    {
      title: PREVIEW_BREADCRUMB,
    },
  ];
  const managerflowBreadcrumb = [
    {
      title: (
        <Link
          to={`/${n.START}/${n.MYWORK_URL}/${TEAM_PERFORMANCE}`}
          state={{ goalsTabKey, EMPLOYEE_LIST: "2", userName, reporteeId }}
          className="link-highlight"
          // onClick={(e) => backHandler(e)}
        >
          {TEAMS_GOAL}
        </Link>
      ),
    },
    {
      title: (
        <span onClick={handleBreadcrumbClick} className="link-highlight">
          {isNull(goalsTabKey)
            ? CASCADE_GOAL
            : goalsTabKey === 0
            ? SUBMITTED_GOAL
            : EMPLOYEE_LIST}
        </span>
      ),
    },
    {
      title: PREVIEW_BREADCRUMB,
    },
  ];
  if (userName && reporteeId) {
    managerflowBreadcrumb.splice(2, 0, {
      title: (
        <Link
          to={{
            pathname: `/${n.START}/${n.MYWORK_URL}/${TEAM_PERFORMANCE}/detail/${reporteeId}`,
          }}
          state={{ goalsTabKey, EMPLOYEE_LIST: "2", userName, reporteeId }}
          className="link-highlight"
        >
          {userName}
        </Link>
      ),
    });
  }

  return (
    <>
      {contextHolder}
      <Spin spinning={isLoading}>
        <div className="contribute_view-otr">
          <div className="container">
            <div className="breadcrumb_preview">
              <BreadcrumbComponent
                items={viewer ? employeeflowBreadcrumb : managerflowBreadcrumb}
              />
            </div>
            <div className="goal-preview-header">
              <div className="goal-preview-title">
                <h3 className="preview-main-title">{PREVIEW_MAIN_TITLE}</h3>
              </div>
              {!isNew ? (
                empView ? (
                  ""
                ) : (
                  <Button
                    className="managercomment"
                    onClick={() => {
                      setIsCommentModalOpen(true);
                    }}
                  >
                    {PREVIEW_COMMENT_TITLE}
                  </Button>
                )
              ) : (
                ""
              )}
            </div>

            <div className="preview-goal-main">
              <div className="preview-goal-sub">
                <div className="row-section">
                  <div className="view-status-row">
                    <div className="row-section">
                      <h5>{CATEGORY_VIEW}</h5>
                      <p>{goalData?.category || goalData?.GOAL_QUESTION_1}</p>
                    </div>
                    <div
                      style={{
                        backgroundColor: goalData?.goalStatusColor,
                      }}
                      className={
                        goalData?.goalStatus === "Behind"
                          ? "status-section behind-bg"
                          : goalData?.goalStatus === "Completed"
                          ? "status-section completed-bg"
                          : goalData?.goalStatus === "Not Started"
                          ? "status-section notstarted-bg"
                          : goalData?.goalStatus === "On Track"
                          ? "status-section ontrack-bg"
                          : goalData?.goalStatus === "Postponed"
                          ? "status-section postponed-bg"
                          : goalData?.goalStatus === "Cancelled"
                          ? "status-section cancelled-bg"
                          : "status-section"
                      }
                    >
                      <p
                        style={{
                          color: goalData?.goalTextColor,
                        }}
                        className="goal-status-value"
                      >
                        {goalData?.goalStatus || goalData?.GOAL_QUESTION_11}
                      </p>
                    </div>
                  </div>
                  <div className="row-section">
                    <h5>{GOAL_NAME_VIEW}</h5>
                    <p>{goalData?.goalName || goalData?.GOAL_QUESTION_2}</p>
                  </div>
                  <div className="row-section">
                    <h5>{GOAL_DESCRIPTION_VIEW}</h5>
                    <p>
                      {goalData?.goalDescription || goalData?.GOAL_QUESTION_3}
                    </p>
                  </div>
                  <div className="row-section">
                    <h5>{GOAL_OUTCOME_VIEW}</h5>
                    <p>{goalData?.outcome || goalData?.QUESTION_4}</p>
                  </div>
                  <div className="row-section">
                    <h5>{GOAL_METRIC_VIEW}</h5>
                    <p>{goalData?.metric || goalData?.GOAL_QUESTION_5}</p>
                  </div>
                  <div className="row-section">
                    <h5>{GOAL_WEIGHT_VIEW}</h5>
                    <p>{goalWeight(goalData)}</p>
                  </div>
                  <div className="row-section">
                    <h5>{GOAL_VISIBILITY_VIEW}</h5>
                    <p>{goalVisibility(goalData)}</p>
                  </div>
                  <div className="row-section">
                    <h5>{GOAL_START_AND_END_DATE_VIEW}</h5>
                    <p>
                      {goalData?.startDate || goalData?.GOAL_QUESTION_7} -{" "}
                      {goalData?.endDate || goalData?.GOAL_QUESTION_8}
                    </p>
                  </div>

                  <Milestones
                    milestones={
                      goalData?.milestones || goalData?.GOAL_QUESTION_10
                    }
                    milestoneImage={sectionList?.[0]?.milestoneStatusDetails}
                    isEdit={false}
                    questionDTO={QUESTION_DTO}
                  />

                  {empView && (
                    <div className="button-area">
                      <OutlineButton
                        outlineBtnStyles="outlineBtnStyles"
                        backText={CANCEL}
                        outlineBtnAction={cancelBtn}
                      />

                      <FillButton
                        fillBtnStyles="fillBtnStyles"
                        fillBtnTxt={
                          paramGoalId && !isPublic ? UPDATE_GOAL : ADD_GOAL
                        }
                        fillBtnAction={fillBtnAction}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
      <ModalDialog
        openModal={isModalOpen}
        modalImage={DraftPopupImage}
        modalImageClasses={"draft-popup-heading-image"}
        DialogHeading={DRAFT_POPUP_TITLE}
        closeIcon={false}
        indexScroll={{ display: "none" }}
        modalHeadingStyles={{ dislay: "none" }}
        popupDivider={{ display: "none" }}
        msgContent={DRAFT_POPUP_MSG}
        okTxt={SAVE_DRAFT_BUTTON}
        cancelTxt={DISCARD_BUTTON}
        cancelBtn={handleOk}
        okBtn={handleCancel}
        onOk={handleOk}
        onCancel={handleCancel}
        modalSubHeading={"modalSubHeading"}
        modalCenterImgStyles={{ display: "none" }}
        modalStyles={"backpopup delete-milestone-popup draft-popup-section"}
        maskClosable={false}
        isSingleButton={false}
      />
      <CommentDialog
        openModal={isCommentModalOpen}
        successHeading={PERFORMANCE}
        DialogHeading={
          empView
            ? `${COMMENTS_MANAGER}${
                goalValues?.commentInfo?.managerInfo?.userName || ""
              }`
            : viewCmnt && !empView
            ? "View Comment"
            : "Add Comment"
        }
        closeIcon={true}
        isEdit={viewCmnt}
        editAction={handleEdit}
        editBtn={"Edit"}
        goalValues={empView ? goalValues : goalData}
        modalImageStyles={{ display: "none" }}
        msgContent={[
          <React.Fragment key="comment">
            <div>{COMMENTS_MANAGER_TITLE}</div>
            <TextArea
              rows={4}
              disabled={false} //empView
              readOnly={viewCmnt} //emp true
              placeholder={
                empView || viewCmnt ? goalData?.commentInfo?.comment : ""
              }
              value={
                !empView
                  ? viewCmnt
                    ? goalData?.commentInfo?.comment
                    : comment
                  : empView
                  ? goalValues?.commentInfo?.comment
                  : comment
              }
              onChange={(e) => {
                e.target.value?.length <= 300 &&
                  setComment(e.target.value.replace(/\n/g, ""));
              }}
              className={viewCmnt && !empView ? "comments-input-box-style" : ""}
            />

            {!viewCmnt && (
              <div
                className={`${
                  !remainingCharacters ? "comment-character-count" : ""
                } remainingCharacters`}
              >
                {remainingCharacters} {REMAINING_TXT}
              </div>
            )}
          </React.Fragment>,
        ]}
        isCancel={false}
        okTxt={empView || viewCmnt ? CLOSE : SEND}
        okBtn={empView || viewCmnt ? handleCancel : handleSaveComment}
        onOk={handleSaveComment}
        onCancel={handleCancel}
        modalSubHeading={"commentheading"}
        modalStyles={"commentdialog"}
        modalContent={"commentview"}
        maskClosable={false}
        isSingleButton={false}
      />
    </>
  );
};

export default Preview;
