import { Button } from "antd";
import React from "react";


function DeleteButton(props:any) {
    return(
        <>
        <Button type="primary" onClick={props.deleteBtnAction}><img src={props.DeleteImage} /></Button>
        </>
    )
}


export default DeleteButton;