import { createAsyncThunk } from "@reduxjs/toolkit";
import admin from "../services/admin";
import tableContentData from "../../components/common/table/content.json";
//import {CardDetails} from '../../components/separation/clearance-detail/ClearanceInterface'

//upload attachment action
export const getAdmin = createAsyncThunk(
  "table",
  async (data: any, { rejectWithValue }) => {
    try {
      const url = `api/v1/contents`;
      try {
        const response = await admin.get(url);
        return tableContentData;
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

export const getAllSurveyDetails = createAsyncThunk(
  "SurveyDetails",
  async (data: any, { rejectWithValue }) => {
    try {
      const url = `api/v1/survey-questions`;
      try {
        const response = await admin.get(url);
        const result = response.data;
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);
