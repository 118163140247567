import * as React from "react"
const EbookLeftArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#0642B5"
      fillRule="evenodd"
      d="m14.56 20-7.29-7.29a1 1 0 0 1 0-1.42l7.36-7.36a.999.999 0 0 1 1.42 0H16a1 1 0 0 1 0 1.41L9.39 12 16 18.59A1 1 0 0 1 16 20a.998.998 0 0 1-1.44 0Z"
      clipRule="evenodd"
    />
  </svg>
)
export default EbookLeftArrow
