import axios from "axios";
import { baseURLSeperation } from "../../constants";
import { getLoggedInUserData } from "../../components/utils/util";
import { removeFromLocalStorage } from "../../components/utils/util";
import { LOGGEDINUSERDETAILS, USERJWT } from "../../constants";
const defaultOptions = {
  baseURL: baseURLSeperation,
};

const cancelToken = axios.CancelToken;
const source = cancelToken.source();
const controller = new AbortController();

let instance = axios.create(defaultOptions);
// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
  let userToken = getLoggedInUserData();
  config.headers.Authorization = userToken
    ? `Bearer ${userToken.apiToken}`
    : "";
  config.headers.isEncrypted = true;
  config.cancelToken = source.token;
  config.signal = controller.signal;
  return config;
});
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
       removeFromLocalStorage(USERJWT);
       removeFromLocalStorage(LOGGEDINUSERDETAILS);
       window.location.href = "/";
    }
    return Promise.reject(error.response.data);
  }
);

export const DEX_WEB_URL = process.env.REACT_APP_DEX_WEB_URL;

export default instance;
