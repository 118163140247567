import { useState } from "react";
import "./GoalActivities.css";
import { Button, Dropdown, Space, Table } from "antd";
import type { MenuProps } from "antd";
import ExpandMoreBlack from "../../themes/assets/images/svg/Expand_more-black.svg";
import OutlineButton from "../common/button/OutlineButton";
import FillButton from "../common/button/FillButton";
import EmptyGoal from "../../themes/assets/images/svg/empty-goals.svg";
import moreButton from "../../themes/assets/images/moreButton.png";
import type {
  ColumnsType,
  TableProps,
  // TablePaginationConfig,
} from "antd/es/table";
import GoalProfileImg from "../../themes/assets/images/goals_card_profile.png";
import { REVIEW_TXT } from "../../constants";
import GoalMoreImg from "../../themes/assets/images/svg/goals/goals_card_profile_more_vertical.svg";
import {
  GOAL_ACTIVITY_MAIN_TITLE,
  GOAL_ACTIVITY_SUB_TITLE,
} from "../../constants";

interface DataType {
  key: number;
  name: any;
  subtitle: string;
  start_date: string;
  due_date: string;
  outcomes: string;
  weightage: string;
  status: any;
}

function GoalsActivities() {
  const [year, setYear] = useState("2023 Goal Plan");
  const items: MenuProps["items"] = [
    {
      label: "2023 Goal Plan",
      key: "2023 Goal Plan",
    },
    {
      label: "2022 Goal Plan",
      key: "2022 Goal Plan",
    },
    {
      label: "2021 Goal Plan",
      key: "2021 Goal Plan",
    },
    {
      label: "2020 Goal Plan",
      key: "2020 Goal Plan",
    },
    {
      label: "2019 Goal Plan",
      key: "2019 Goal Plan",
    },
  ];
  const handleMoreClick: MenuProps["onClick"] = (e) => {
    console.log("click", e);
  };

  const handleYearClick: MenuProps["onClick"] = (e) => {
    setYear(e.key);
  };
  const menuYear = {
    items,
    onClick: handleYearClick,
    selectable: true,
    defaultSelectedKeys: [year],
  };
  const fillBtnAction = (value: object) => {
    //navigate("/newgoal");
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "Goal Name & Description",
      dataIndex: "name",
      render: (record) => (
        <>
          <div className="inner-table-milestone">
            <span className="title-miles">{record["title"]}</span>
            <span className="cont-mile">{record["subtitle"]}</span>
          </div>
        </>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
    },
    {
      title: "Outcomes",
      dataIndex: "outcomes",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (record) => {
        const { status, text } = record;
        return (
          <Button type="primary" className={status}>
            {text}
          </Button>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (record) => {
        const { key = "" } = record;
        return (
          <Space size="middle">
            <img src={moreButton} className="imgMore" alt="" />
          </Space>
        );
      },
    },
  ];
  const tableColumns = columns.map((item) => ({ ...item }));

  const data = [
    {
      key: 1,
      category: "Bussiness Category",
      name: {
        title:
          "Backlog Visibility & Readin Backlog Visibility Backlog Visibility ",
        subtitle: "Achieve high sprint velocity and on-time product releases",
      },
      start_date: "10/01/2023",
      due_date: "10/01/2023",
      outcomes: "Delivery Excellence & Innovation",
      weightage: "1 25%",
      metrics: "1. 90 to 100% accurate loading of engineering team",
      status: {
        status: "behind",
        text: "Behind",
      },
      subtitle: "",
      action: "hh",
    },
    {
      key: 2,
      category: "Bussiness Category",
      name: {
        title:
          "Backlog Visibility & Readin Backlog Visibility Backlog Visibility ",
        subtitle: "Achieve high sprint velocity and on-time product releases",
      },
      start_date: "10/01/2023",
      due_date: "10/01/2023",
      outcomes: "Delivery Excellence & Innovation",
      weightage: "2 25%",
      metrics: "1. 90 to 100% accurate loading of engineering team",
      status: {
        status: "completed",
        text: "Completed",
      },
      subtitle: "",
      action: "hh",
    },
    {
      key: 3,
      category: "Bussiness Category",
      name: {
        title:
          "Backlog Visibility & Readin Backlog Visibility Backlog Visibility ",
        subtitle: "Achieve high sprint velocity and on-time product releases",
      },
      start_date: "10/01/2023",
      due_date: "10/01/2023",
      outcomes: "Delivery Excellence & Innovation",
      weightage: "3 25%",
      metrics: "1. 90 to 100% accurate loading of engineering team",
      status: {
        status: "on-track",
        text: "On Track",
      },
      subtitle: "",
      action: "hh",
    },
    {
      key: 4,
      category: "Bussiness Category",
      name: {
        title:
          "Backlog Visibility & Readin Backlog Visibility Backlog Visibility ",
        subtitle: "Achieve high sprint velocity and on-time product releases",
      },
      start_date: "10/01/2023",
      due_date: "10/01/2023",
      outcomes: "Delivery Excellence & Innovation",
      weightage: "3 25%",
      metrics: "1. 90 to 100% accurate loading of engineering team",
      status: {
        status: "draft",
        text: "Draft",
      },
      subtitle: "",
      action: "hh",
    },
  ];
  const tableProps: TableProps<DataType> = {
    bordered: false,
    showHeader: true,
    pagination: false,
    // expandable,
  };

  return (
    <div className="goal_active-otr">
      <div className="goal_active">
        <div className="goal_title-sec">
          <h1>{GOAL_ACTIVITY_MAIN_TITLE}</h1>
          <ul>
            <li>
              <span>-</span>
              <span className="title">Total Goals</span>
            </li>
            <li>
              <span>00%</span>
              <span className="title">Progess</span>
            </li>
            <li>
              <span>-</span>
              <span className="title">Completed</span>
            </li>
          </ul>
        </div>
        <div className="goal_year-drop">
          <a href="" className="quality-btn">
            <OutlineButton
              backText="Quaterly Check In"
              outlineBtnAction={fillBtnAction}
            />
          </a>
          <Dropdown menu={menuYear} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <Space className="year-selection">
                {year}
                <img src={ExpandMoreBlack} alt="" />
              </Space>
            </a>
          </Dropdown>
          <FillButton fillBtnTxt="+ Add Goals" fillBtnAction={fillBtnAction} />
        </div>
      </div>
      <div className="goal_active">
        <div className="business">
          <h5 className="title">{GOAL_ACTIVITY_SUB_TITLE}</h5>
          {data.length !== 0 ? (
            <Table
              {...tableProps}
              columns={tableColumns}
              dataSource={data}
              className="tbl-outer-section"
            />
          ) : (
            <div className="empty-goals">
              <img src={EmptyGoal} alt="" />
              <span>No goals created</span>
            </div>
          )}
        </div>
      </div>
      <div className="goal_checkin-otr">
        <div className="goal_checkin-card">
          <div className="goal_card_img">
            <img src={GoalProfileImg} alt="goal_card_img" />
          </div>
          <div className="goal_card_content">
            <div className="goal_card_quater">Q1 Check-In</div>
            <div className="goal_card_title">Camilo Mejias</div>
            <div className="goal_card_para">
              Please complete self-assessment and update your milestones on or
              before DD/MM/YYYY
            </div>
            <div className="goal_card_button">
              <FillButton
                fillBtnAction={() => {
                  alert("test");
                }}
                fillBtnTxt={REVIEW_TXT}
              />
            </div>
          </div>
          <div className="goal_card_action">
            <img src={GoalMoreImg} alt="goal_card_more_img" />
          </div>
        </div>
        <div className="goal_checkin-card">
          <div className="goal_card_img">
            <img src={GoalProfileImg} alt="goal_card_img" />
          </div>
          <div className="goal_card_content">
            <div className="goal_card_quater">Q1 Check-In</div>
            <div className="goal_card_title">Camilo Mejias</div>
            <div className="goal_card_para">
              Please complete self-assessment and update your milestones on or
              before DD/MM/YYYY
            </div>
            <div className="goal_card_button">
              <FillButton
                fillBtnStyles={"review_btn"}
                fillBtnAction={() => {
                  alert("test");
                }}
                fillBtnTxt={REVIEW_TXT}
              />
            </div>
          </div>
          <div className="goal_card_action">
            <img src={GoalMoreImg} alt="goal_card_more_img" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoalsActivities;
