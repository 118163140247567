import {
  EARNED_BADGES,
  LOCKED_BADGES,
  VIEW_ALL_LINK,
} from "../../../../../constants";
import { useState, useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";
import { getUserBadges } from "../../../../../redux/actions/gamification";
import "../LeaderBoardLanding/profile.css";
import "../index.css";
import LockedBadgeIcon from "./LockedIcon";
import BadgesEmpty from "../../../../../themes/assets/images/svg/badges-nothing.svg";
import BadgesBlog from "../../../../../themes/assets/images/svg/badges-blog.svg";
import LockBadgeOne from "../../../../../themes/assets/images/svg/Lock-badge-one.svg";
import { useNavigate } from "react-router-dom";
import TopContributor from "./TopContributor";
import EarnedBadgeDetail from "./EarnedBadge";
import { Skeleton } from "antd";

const EmptyEarnedBadges = ({ message }) => {
  return (
    <div className="empty-badges">
      <img src={BadgesEmpty} alt="badgesempty" />
      <h3>No Badges</h3>
      <p>{message}</p>
    </div>
  );
};

const EarnedBadgeSkeleton = ({ loading }) => {
  return (
    <div className="earned-badge-skeleton">
      <div className="earned-badge-skeleton-top">
        {Array.from({ length: 3 })?.map((item) => {
          return <Skeleton.Button active={loading} />;
        })}
      </div>
      <div className="badges-skeleton-earned">
        {Array.from({ length: 6 })?.map((item) => {
          return (
            <Skeleton.Button
              active={loading}
              className="badge-skeleton-container"
            />
          );
        })}
      </div>
    </div>
  );
};

const CarouselContributorBadge = ({ loading }) => {
  return (
    <div className="badges_blog skeleton-contribute-bg">
      <Skeleton.Avatar active={loading} size={80} />
      <div className="row-skeleton">
        {Array.from({ length: 2 })?.map((item) => {
          return (
            <Skeleton.Button active={loading} className="contribute-skeleton" />
          );
        })}
      </div>
    </div>
  );
};

const EarnedBadges = ({ userBadges }) => {
  const navigate = useNavigate();

  const earnedBadges = userBadges?.data?.earnedBadges;
  const { loading } = useAppSelector((state) => state?.gamification);

  if (loading) {
    return (
      <div className="badges_detail-otr">
        <EarnedBadgeSkeleton loading={loading} />
      </div>
    );
  }

  return (
    <div className={"badges_detail-otr"}>
      <div className="badge_head-title-inn badge_title-sec leaderboard_header">
        <h1>My Badges</h1>
        <button
          onClick={() => navigate(EARNED_BADGES)}
          className="badge_btn button"
        >
          {VIEW_ALL_LINK}
        </button>
      </div>
      {earnedBadges?.length > 0 ? (
        <div className="earned_badges_detail">
          <div className="badges_lock-otr">
            <div className="badges_active_display">
              {earnedBadges?.map((item, index) => (
                <EarnedBadgeDetail key={index} data={item} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <EmptyEarnedBadges message={userBadges?.data?.headerInfo?.noBadgeMsg} />
      )}
    </div>
  );
};

const LockedBadges = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userBadges, loading, error } = useAppSelector(
    (state) => state.gamification
  );
  const earnedBadges = userBadges?.data?.earnedBadges;
  const fetchUserBadges = () => {
    dispatch(getUserBadges({}));
  };
  useEffect(() => {
    fetchUserBadges();
  }, []);

  return (
    <div className={"badges_detail-sec badges_detail-inn"}>
      <div className="badge-title-screen">Badges</div>
      <div className="badges-screen-sub-container">
        <EarnedBadges userBadges={userBadges} />
        <div className="badges_lock-right">
          {loading ? (
            <CarouselContributorBadge loading={loading} />
          ) : userBadges?.data?.headerInfo?.progressBar?.length > 0 ? (
            <div className="progressbar">
              <TopContributor
                data={userBadges?.data?.headerInfo?.progressBar}
              />
            </div>
          ) : (
            <div className="badges_blog">
              <img src={BadgesBlog} alt="badgesblog" />
              <p>{userBadges?.data?.headerInfo?.bannerMsg}</p>
            </div>
          )}
          {loading ? (
            <div className="locked-badges">
              <EarnedBadgeSkeleton loading={loading} />
            </div>
          ) : (
            <div className="locked-badges">
              <div className="badge_title-sec leaderboard_header">
                <h1>Locked Badges</h1>
                <button
                  onClick={() => navigate(LOCKED_BADGES)}
                  className="badge_btn button"
                >
                  {VIEW_ALL_LINK}
                </button>
              </div>
              <LockedBadgeIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LockedBadges;
