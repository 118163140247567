import React, { useState } from "react";
import "./ToastMsg.css";
import { Alert, Space } from "antd";
// import ToastInfo from "../../../themes/assets/images/svg/toastAlert/Info.svg";
// const icon = <ToastInfo />;
const ToastMessage = (props: any) => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };
  return (
    <>
      <div className={props?.customToastArea}>
        <div className={props?.customToastLeftArea}>
          <div className="toast-img-area">
            <img src={props?.icon} alt="" />
          </div>
          <div className="toast-msg-area">
            <h4>{props?.toastMsgTitle}</h4>
            <p>{props?.toastMsgPara}</p>
          </div>
        </div>
        <div className={props.customToastRightArea}>
          <button onClick={props?.closeAction}>
            <img src={props?.closeIcon} />
          </button>
        </div>
      </div>
      {/* <Alert
      message={props.toastMsgTitle}
      description={props.toastMsgPara}
      icon={icon}
      type="info"
      showIcon={true}
      closable afterClose={handleClose}
      className={props.toastSection}
    /> */}
    </>
  );
};

export default ToastMessage;
