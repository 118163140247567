import { createAsyncThunk } from "@reduxjs/toolkit";
import opportunities from "../services/opportunities";

//get job opportunities action
export const getJobOpportunitiesList = createAsyncThunk(
  "getGameLanding",
  async (args: any, { rejectWithValue }) => {
    const url = `/api/v1/opportunities/jobMatches`;
    try {
      const response = await opportunities.post(url, {});
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get job detail action
export const getJobDetail = createAsyncThunk(
  "getJobDetail",
  async (args: any, { rejectWithValue }) => {
    console.log("args", args);
    const url = `/api/v1/opportunities/details`;
    try {
      const response = await opportunities.post(url, args);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// apply job action
export const applyJob = createAsyncThunk(
  "applyJob",
  async (args: any, { rejectWithValue }) => {
    const url = `api/v1/game-leaderboard/landingPage`;
    try {
      const response = await opportunities.post(url, args?.request);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
