import { useState } from "react";
import { Card } from "antd";
import "./index.css";
import EmployeeList from "../EmployeeList";
import PendingReview from "../../ManagerView/PendingReview/index";
import { TabView, TabPanel } from "primereact/tabview";
import "primereact/resources/primereact.css";
import {
  EMPLOYEE_LIST,
  PENDING_REVIEWS,
  TEAMS_QUARTERLY_CHECKIN,
  TEAMS_QUARTERLY_CHECKIN_TITLE,
} from "../../../../constants";

const TeamsQuarterlyCheckin = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="team-performanceContainer">
      <div className="performance-header">
        <div className="left-side">
          <h5>{TEAMS_QUARTERLY_CHECKIN_TITLE}</h5>
        </div>
      </div>
      <div className="performance-body">
        <Card style={{ width: "100%" }}>
          <TabView activeIndex={activeIndex} className="manager-tabs">
            <TabPanel header={PENDING_REVIEWS}>
              <PendingReview flow={TEAMS_QUARTERLY_CHECKIN} goalsTabKey={activeIndex} />
            </TabPanel>
            <TabPanel header={EMPLOYEE_LIST}>
              <EmployeeList flow={TEAMS_QUARTERLY_CHECKIN} goalsTabKey={activeIndex} />
            </TabPanel>
          </TabView>
        </Card>
      </div>
    </div>
  );
};

export default TeamsQuarterlyCheckin;
