import React, { useState } from "react";
import { NOTIFICATIONS_MSG } from "../../constants";
import {
  Col,
  DatePicker,
  Form,
  Grid,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
} from "antd";
import "./NotificationModal.css";
const { useBreakpoint } = Grid;
const NotificationModal = () => {
  const [notificationValue, setNotificationValue] = useState(1);
  const handleRadioButton = (e: RadioChangeEvent) => {
    setNotificationValue(e.target.value);
  };
  const screens = useBreakpoint();

  return (  
    <div>
      <div className="notification_msg">{NOTIFICATIONS_MSG}</div>
      <div className="notification_msg">
        <Radio.Group
          onChange={(e) => {
            handleRadioButton(e);
          }}
          value={notificationValue}
        >
          <Radio value={1}>Yes</Radio>
          <Radio value={2}>No</Radio>
        </Radio.Group>
      </div>
      <Form>
      {notificationValue === 1 && 
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div className="notificationWrapper">
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Please enter a title" }]}
            >
              <Input
                style={{ width: "100%" }}
                size="large"
                placeholder="Enter Title"
              />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div className="notificationWrapper">
            <Form.Item
              label="Details"
              name="details"
              rules={[{ required: true, message: "Please enter details" }]}
            >
              <Input
                style={{ width: "100%" }}
                size="large"
                placeholder="Enter Details"
              />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div className="notificationWrapper">
            <Form.Item
              label="Date"
              name="date"
              rules={[
                { required: true, message: "Please enter the date and time" },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                size="large"
                showTime
                placeholder="Enter Date & Time"
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
}
    </Form>

      </div>
  );
};

export default NotificationModal;
