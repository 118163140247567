import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import "./ViewGoal.css";
import "./SmartGoals.css";
import { Card } from "antd";
import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";
import AccessTime from "../../themes/assets/images/svg/front/active_access_time.svg";
import BusinessMilestone from "../../themes/assets/images/svg/BusinessMilestone.svg";
import {
  BEHIND,
  COMPLETED,
  GOAL_SUBTITLE,
  GOAL_TITLE,
  NOTSTARTED,
  ONTRACK,
  PERFORMANCE_BREADCRUMB,
  SMART_GOALS_BREADCRUMB,
  VIEW_SMART_GOAL,
} from "../../constants";
import n from "../navigation/Route.Names";
import { useAppSelector } from "../../redux/store/redux-hooks";

const SmartGoalPreview = () => {
  const [isEmptyMilestone, setEmptyMilestone] = useState(false);

  const activeSmartGoalData = useAppSelector(
    (state: any) => state?.goals?.activeSmartGoal
  );
  const items = [
    {
      title: (
        <Link to={`/${n.PERFORMANCE_URL}`} className="link-highlight">
          {PERFORMANCE_BREADCRUMB}
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/${n.PERFORMANCE_URL}/${n.SAMRT_GOAL_URL}`}
          className="link-highlight"
        >
          {SMART_GOALS_BREADCRUMB}
        </Link>
      ),
    },
    {
      title: VIEW_SMART_GOAL,
    },
  ];

  return (
    <div>
      <div className="view-goal smart-goal_preview">
        <div className="breadcrumb_preview">
          <BreadcrumbComponent
            // rootPath={"Perfomance / Edit Cascaded Goal"}
            // subPath={"View Goal"}
            // rootLink={`${n.PERFORMANCE_URL}/${n.CASCADING_EDIT_GOALS_URL}`}
            items={items}
          />
        </div>
        <div className="goal-preview-header">
          <div className="goal-preview-title">
            <h3 className="preview-main-title">Goal Preview</h3>
          </div>
        </div>
        <Card style={{ width: "100%" }}>
          <div className="view-status-row">
            <div className="row-section">
              <h5>Category</h5>
              <p>{activeSmartGoalData?.category}</p>
            </div>
            <div
              className={
                activeSmartGoalData?.status?.text === BEHIND
                  ? "status-section behind-bg"
                  : activeSmartGoalData?.status?.text === COMPLETED
                  ? "status-section completed-bg"
                  : activeSmartGoalData?.status?.text === NOTSTARTED
                  ? "status-section notstarted-bg"
                  : activeSmartGoalData?.status?.text === ONTRACK
                  ? "status-section ontrack-bg"
                  : "status-section"
              }
            >
              <p>{activeSmartGoalData?.status?.text}</p>
            </div>
          </div>
          <div className="row-section">
            <h5>Goal Name</h5>
            <p>{activeSmartGoalData?.name?.title}</p>
          </div>
          <div className="row-section">
            <h5>Goal Description</h5>
            <p>{activeSmartGoalData?.name?.subtitle}</p>
          </div>
          <div className="row-section">
            <h5>Outcome</h5>
            <p>{activeSmartGoalData?.outcomes}</p>
          </div>
          <div className="row-section">
            <h5>Metric</h5>
            <p>{activeSmartGoalData?.metrics}</p>
          </div>
          <div className="row-section">
            <h5>Weight</h5>
            <p>{activeSmartGoalData?.weightage}</p>
          </div>
          <div className="row-section">
            <h5>Visibility</h5>
            <p>Private</p>
          </div>
          <div className="row-section">
            <h5>Start and End Date</h5>
            <p>{`${activeSmartGoalData?.start_date} - ${activeSmartGoalData?.due_date}`}</p>
          </div>
          <div className="milestone-section">
            <div className="milestone-header-section">
              <div className="milestone-header-left">
                <h5>{GOAL_TITLE}</h5>
                <span>{GOAL_SUBTITLE}</span>
              </div>
            </div>
            {isEmptyMilestone ? (
              <div className="milestone-body-section">
                <div className="milestone-body-header">
                  <img src={AccessTime} alt="" />
                  <h5>Milestones 1</h5>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500. Lorem Ipsum has been
                  the industry's standard dummy text ever since the 1500s, when
                  an unknown printer took a galley of type and scrambled
                </p>
                <div className="milestone-body-dates">
                  <span className="milestone-start-date">
                    Target Date : 31/02/2023
                  </span>
                  <span>Actual Date : 31/02/2023</span>
                </div>
              </div>
            ) : (
              <div className="empty-milestone">
                <img src={BusinessMilestone} alt="" />
                <span>Add milestone based on your goal setting.</span>
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};
export default SmartGoalPreview;
