import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../services/api";
//import {CardDetails} from '../../components/separation/clearance-detail/ClearanceInterface'

//upload attachment action
export const getStoryDetail = createAsyncThunk(
  "getStoryDetail",
  async (data: any, { rejectWithValue }) => {
    try {
      const contentId = data.contentId;
      const url = `api/v1/webapi/contentDetails?contentId=${contentId}`;
      try {
        const response = await api.get(url);
        const result = response.data;
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

//upload attachment action
export const reactions = createAsyncThunk(
  "reactions",
  async (data: any, { rejectWithValue }) => {
    try {
      const url = `api/v1/webapi/reactions`;
      try {
        const response = await api.post(url, data);
        const result = response.data;
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);
