import { TabsProps, Tabs, Dropdown, Space, MenuProps, Button, Spin } from "antd";
import { Card, Select } from "antd";
import HolidayCalendar from "./HolidayCalendar";
import { DownOutlined } from "@ant-design/icons";
import { TabPanel, TabView } from "primereact/tabview";
import { useEffect, useState } from "react";
import ArrowDown from "../../themes/assets/images/svg/Arrow-Down.svg";
import { useAppDispatch } from "../../redux/store/redux-hooks";
import {
  getCity,
  getCountry,
  getHoliday,
  getTab,
} from "../../redux/actions/my_space";
import {
  All_CITIES,
  DEFAULT_COUNTRY_CODE_HOLIDAY_CALENDER,
  INDIA_COUNTRY_CODE_HOLIDAY_CALENDER,
} from "../../constants";

const CalendarTabs = () => {
  const [activeKey, setActiveKey] = useState<any>();
  const [tab, setTab] = useState<any>([]);
  const [bckTab, setBckTab] = useState<any>([]);
  const [countries, setCountries] = useState<any>([]);
  const [country, setCountry] = useState<any>("");
  const [cities, setCities] = useState<any>([]);
  const [city, setCity] = useState<any>("");
  const [isLoading, setIsLoading] = useState<any>(true);
  const dispatch = useAppDispatch();
  // const cityhandleChange = (value: string) => {
  //   setCity(value);
  // };

  const handleChange = (value: string) => {
    setCountry(value);
    setActiveKey(0);
    if (value === INDIA_COUNTRY_CODE_HOLIDAY_CALENDER) {
      // dispatch(getCity({ country: value }))
      //   .unwrap()
      //   .then((result) => {
      //     const cities = result?.map((item) => ({
      //       value: item.locationCode,
      //       label: item.city,
      //     }));

      //     //setCity(cities.length > 0 ? cities[0]?.value : "")
      //     cities.unshift({ value: "", label: All_CITIES });
      //     setCities(cities);
      //     setTab(bckTab);
      //   })
      //   .catch((err) => {});
      setTab(bckTab);
    } else {
      setTab(tabFilterHandler(bckTab, value))
    }
  };
  const handleITTabChange = (index) => {
    setActiveKey(index);
  };

  useEffect(() => {
    setIsLoading(true)
    dispatch(getTab({}))
      .unwrap()
      .then((result) => {
        if (INDIA_COUNTRY_CODE_HOLIDAY_CALENDER !== country) {
          const filterTab = result.filter((item) => item.key !== "FLOATER");
          setTab(filterTab);
        } else {
          setTab(result);
        }
        setBckTab(result);

        /* start to get country dispatch */
        dispatch(getCountry({}))
          .unwrap()
          .then((countryresult) => {
            const countries = countryresult?.map((item) => ({
              value: item.countryCode,
              label: item.country,
            }));
            const country = countryresult?.filter((item) => item?.isDefaultCountry);
            const singleCountry = country.length > 0 ? country[0]?.countryCode : ""
            setCountry(singleCountry)
            setCountries(countries || []);
            setTab(tabFilterHandler(result, singleCountry))
            setIsLoading(false)
          })
          .catch((err) => { });
        /* end to get country dispatch */

      })
      .catch((err) => { });


  }, []);

  useEffect(() => {
    setActiveKey(0);
  }, []);

  const tabFilterHandler = (data, singleCountry) => {
    if (INDIA_COUNTRY_CODE_HOLIDAY_CALENDER !== singleCountry) {
      const filterTab = data.filter((item) => item.key !== "FLOATER");
      return filterTab
    } else {
      return data
    }
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="calendar_tabs_container">
          <div className="header_section">
            <h1>Holiday Calendar</h1>
            <div className="country-city-dropdown-section">
              {/* {country === INDIA_COUNTRY_CODE_HOLIDAY_CALENDER && (
              <Select
                placeholder="Select City"
                defaultValue=""
                style={{ width: 120 }}
                onChange={cityhandleChange}
                suffixIcon={<img src={ArrowDown} />}
                className="holiday-country-select"
                popupClassName="holiday-dropdown-open"
                options={cities}
              />
            )} */}
              {!isLoading && (<Select
                placeholder="Select Country"
                defaultValue={country}
                value={country}
                style={{ width: 120 }}
                onChange={handleChange}
                suffixIcon={<img src={ArrowDown} />}
                className="holiday-country-select"
                popupClassName="holiday-dropdown-open"
                options={countries}
              />)}
            </div>
          </div>
          {!isLoading && (<Card className="holiday-tab" style={{ width: "100%" }}>
            <TabView
              //scrollable
              activeIndex={activeKey}
              onTabChange={(e) => {
                handleITTabChange(e.index);
              }}
            >
              {tab.map((item) => (
                <TabPanel key={item.key} header={item.value}>
                  <HolidayCalendar
                    activekey={item.key}
                    country={country}
                    city={city}
                  />
                </TabPanel>
              ))}
            </TabView>
          </Card>)}
        </div>
      </Spin>
    </>
  );
};

export default CalendarTabs;
