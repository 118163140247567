import React, { memo, useMemo } from "react";
import { Avatar } from "antd";
import { getWordLetter } from "../utils/util";
import "./AvatarProfile.css";

const colorSet = [
  {
    id: 1,
    backgroundColor: "#F0ECFE",
    color: "#4D2CAF",
  },
  {
    id: 2,
    backgroundColor: "#FFF7E6",
    color: "#B57B06",
  },
  {
    id: 3,
    backgroundColor: "#FDEBF1",
    color: "#A62A52",
  },
  {
    id: 4,
    backgroundColor: "#ECF8FE",
    color: "#2C83AF",
  },
];

const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};

const generateRandomAvtarVariations = () => {
  const index = getRandomInt(colorSet?.length);
  return colorSet?.[index];
};

const AvatarProfile = memo((props: any) => {
  const { profilePic, name, size } = props;
  const colors = generateRandomAvtarVariations();
  return (
    <Avatar.Group>
      {profilePic ? (
        <Avatar size={size} src={profilePic} />
      ) : (
        <Avatar
          style={{
            backgroundColor: colors?.backgroundColor,
            color: colors?.color,
          }}
          className="avatar-profile"
          size={size}
        >
          {getWordLetter(name ? name : "")}
        </Avatar>
      )}
    </Avatar.Group>
  );
});
export default AvatarProfile;
