import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAdmin, getAllSurveyDetails } from "../actions/admin";
import { newPageOptions } from "../../components/utils/util";

interface TableState {
    itemsPerPage: number;
    currentPage: any;
    displayedData: any[];
    data: any[];
    pageOptions: any[],
    totalItems: number,
    itemsPerPageOptions: any[],
    allData: any[],
    selectedFilter: any,
    appliedFilter: any
}

const initialState: TableState = {
    itemsPerPage: 10,
    currentPage: 1,
    displayedData: [],
    data: [],
    pageOptions: [],
    totalItems: 0,
    itemsPerPageOptions: [10, 20, 30, 40],
    allData: [],
    selectedFilter: {
        order: [],
        type: "",
        content: "",
        _id: "",
        question: "",
        questionId: "",
        date: "",
        publish: "",
        title: "",
    },
    appliedFilter: {
        order: [],
        type: "",
        content: "",
        _id: "",
        question: "",
        questionId: "",
        date: "",
        publish: "",
        title: "",
    }
};

const tableSlice = createSlice({
    name: "table",
    initialState,
    reducers: {
        setItemsPerPage: (state, action: PayloadAction<number>) => {
            state.itemsPerPage = action.payload;
            const { itemsPerPage, currentPage, allData } = state
            state.data = allData.slice(currentPage - 1, itemsPerPage);
            state.pageOptions = newPageOptions(state.totalItems, itemsPerPage)
        },
        setCurrentPage: (state, action: PayloadAction<any>) => {
            state.currentPage = action.payload;
            const { itemsPerPage, currentPage, allData } = state
            const startIndex = (parseInt(currentPage) - 1) * Number(itemsPerPage);
            const endIndex = startIndex + itemsPerPage;
            const newData = allData.slice(startIndex, endIndex)
            state.data = newData
        },
        setDisplayedData: (state, action: PayloadAction<any[]>) => {
            // state.displayedData = action.payload;
        },
        setData: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload || [];
            // state.totalItems = action.payload?.length || 0;
        },
        setAllData: (state, action: PayloadAction<any[]>) => {
            /*state.allData = action.payload || [];
            state.data = action.payload || [];
            state.totalItems = action.payload?.length || 0;*/
        },
        setPageOptions: (state, action: PayloadAction<any[]>) => {
            // state.pageOptions = action.payload;
        },
        setItemsPerPageOptions: (state, action: PayloadAction<any[]>) => {
            // state.itemsPerPageOptions = action.payload;
        },
        setSelectedFilter: (state, action: PayloadAction<any>) => {
            state.selectedFilter = action.payload;
        },
        setAppliedFilter: (state, action: PayloadAction<any>) => {
            state.appliedFilter = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAdmin.pending, (state) => {
        });
        builder.addCase(getAdmin.fulfilled, (state: any, action) => {
            const { contents = [] } = action.payload
            const { itemsPerPage, currentPage } = state
            state.allData = contents;
            state.data = contents.slice(currentPage - 1, itemsPerPage);
            state.totalItems = contents?.length || 0;
            state.pageOptions = newPageOptions(state.totalItems, itemsPerPage)
        });
        builder.addCase(getAdmin.rejected, (state: any, action) => {
            state.error = action.payload;
        });
        builder.addCase(getAllSurveyDetails.pending, (state) => {
        });
        builder.addCase(getAllSurveyDetails.fulfilled, (state: any, action) => {
            const { survey = [] } = action.payload
            state.allData = survey;
            state.data = survey;
            state.totalItems = survey?.length || 0;
        });
        builder.addCase(getAllSurveyDetails.rejected, (state: any, action) => {
            state.error = action.payload;
        });
    }
});

export const {
    setItemsPerPage,
    setCurrentPage,
    setDisplayedData,
    setData,
    setPageOptions,
    setItemsPerPageOptions,
    setAllData,
    setSelectedFilter,
    setAppliedFilter
} = tableSlice.actions;

export default tableSlice.reducer;
