import { useState } from "react";
import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import {
  Dropdown,
  MenuProps,
  TabsProps,
  Card,
  Tabs,
  Button,
  Spin,
  Space,
  notification,
} from "antd";
import "./index.css";
import ExpandMore from "../../../../themes/assets/images/svg/Expand_more.svg";
import QuarterlyCheckInManager from "./quarterly-checkin-manager";
import BreadcrumbComponent from "../../../common/breadcrumb/breadcrumb";
import ASPForm from "../../ManagerView/EmployeeDetails/ASPForm/index";
import ToastMessage from "../../../common/ToastMsg/toastMessage";
import ToastInfo from "../../../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastClose from "../../../../themes/assets/images/performance/svg/ToastClose.svg";
import ManagerGoals from "./goals-employee";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  notificationAction,
  resetState,
} from "../../../../redux/reducers/performance";
import n from "../../../navigation/Route.Names";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import {
  getDropDownYears,
  getEmployeeDataManagerView,
  getEmployeeGoalListData,
} from "../../../../redux/actions/performance";
import ManagerOverview from "../ManagerOverview";
import {
  setActiveTeamGoalYear,
  viewState,
} from "../../../../redux/reducers/performance";
import {
  FAILURE,
  MANAGER_CMNT_ADDED_TOAST,
  MANAGER_EDIT_CMNT_TOAST,
  MANAGER_TOAST_MSG,
  NOTIFICATION_DURATION,
  SUCCESS,
  TEAMS_PERFORMANCE,
  WARNING,
  SUBMITTED_GOAL,
  TEAMS_GOAL,
  EMPLOYEE_LIST,
} from "../../../../constants";
import {
  GoalsErrorIcon,
  GoalsInfoIcon,
  ToastSuccessIcon,
} from "../../../common/svg/svg";

const EmployeeDetailPerformance = (props) => {
  const year = useAppSelector(
    (state: any) => state?.performance?.teamGoalActiveYear
  );
  const { dropDownYears } = useAppSelector((state: any) => state?.performance);

  const location = useLocation();
  const param = useParams();
  const dispatch = useAppDispatch();
  const { goalsTabKey, userName } = location?.state || {};
  const [activeTab, setActiveTab] = useState(1);

  const {
    getEmployeeFilteredData,
    isLoading,
    employeeDataManagerView,
    error,
    configuration,
  } = useAppSelector((state: any) => state.performance);
  const employeereporteeId = getEmployeeFilteredData?.reporteeInfo;

  const [api, contextHolder] = notification.useNotification({
    top: 0,
    maxCount: 1,
  });
  useEffect(() => {
    dispatch(
      getEmployeeGoalListData({
        year: year,
        reporteeId: param.reporteeId,
      })
    );
  }, [param.reporteeId, year]);

  useEffect(() => {
    dispatch(
      getEmployeeDataManagerView({
        reporteeId: param?.reporteeId,
        year: year,
      })
    );
  }, [year]);

  useEffect(() => {
    dispatch(getDropDownYears({ reporteeId: param?.reporteeId }));
  }, []);

  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
        additionalInfo: "",
      })
    );
  };

  useEffect(() => {
    if (configuration.isShowNotification) {
      const type = configuration.alertMessage.type;
      api[type]({
        message: configuration.alertMessage.message,
        description: type === SUCCESS ? MANAGER_TOAST_MSG : "",
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon:
          type === SUCCESS ? (
            <ToastSuccessIcon />
          ) : type === FAILURE ? (
            <GoalsErrorIcon />
          ) : (
            <GoalsInfoIcon />
          ),
        className: "custom-toast-area custom-success-toast",

        onClose: onCloseHandler,
      });
    }
  }, [configuration.isShowNotification]);

  const items: MenuProps["items"] = dropDownYears;

  const tabitems: TabsProps["items"] = [
    {
      key: "1",
      label: "Overview",
      children: <ManagerOverview employeeData={employeeDataManagerView} />,
    },
    {
      key: "2",
      label: "Goals",
      children: (
        <ManagerGoals
          year={year}
          manager={param.manager}
          reporteeId={param?.reporteeId}
          goalsTabKey={goalsTabKey}
          userName={userName}
        />
      ),
    },
  ];

  const handleYearClick: MenuProps["onClick"] = (e) => {
    dispatch(setActiveTeamGoalYear(e.key));
  };

  const menuYear = {
    items,
    onClick: handleYearClick,
    selectable: true,
    defaultSelectedKeys: [year],
  };

  const onChange = (key: string) => {
    console.log(key);
  };

  const breadbrumbItems = [
    {
      title: (
        <Link
          to={`/${n.START}/${n.MYWORK_URL}/${n.TEAM_PERFORMANCE}`}
          state={{ goalsTabKey }}
          className="link-highlight"
        >
          {TEAMS_GOAL}
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/${n.START}/${n.MYWORK_URL}/${n.TEAM_PERFORMANCE}`}
          className="link-highlight"
          state={{ goalsTabKey }}
        >
          {goalsTabKey === 0 ? SUBMITTED_GOAL : EMPLOYEE_LIST}
        </Link>
      ),
    },
    {
      title: employeereporteeId?.userName,
    },
  ];

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="team-performanceContainer">
          {contextHolder}
          <div className="empDetialBreadcrumb">
            <BreadcrumbComponent items={breadbrumbItems} />
          </div>
          <div className="performance-header-empDetail">
            <div className="left-side">
              <div className="detail-username">
                {employeereporteeId?.userName}
              </div>
              <Dropdown menu={menuYear} trigger={["click"]}>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Space className="year-selection">
                    {year}
                    <img src={ExpandMore} alt="" />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
          <div className="performance-body">
            <Card style={{ width: "100%" }}>
              <Tabs
                defaultActiveKey={
                  location?.state?.EMPLOYEE_LIST || param?.activeTab ? "2" : "1"
                }
                items={tabitems}
                // value={ }
                onChange={onChange}
              />
            </Card>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default EmployeeDetailPerformance;
