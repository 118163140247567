import React, { PureComponent, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export const AllMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
interface DataPoint {
  name: string;
  pv: number;
}
export default function LineChartComponent({ lineChartData }) {
  const lineGraphData = lineChartData;
  const [modifiedData, setModifiedData] = useState<DataPoint[]>([]);
  const [currentMonth, setCurrentMonth] = useState("");

  useEffect(() => {
    if (lineChartData) {
      const keys = Object.keys(lineChartData);
      const latestKey = keys[0];
      const [year, month] = latestKey.split("-");
      const monthNames = AllMonths.map((month) => month.substring(0, 3));
      let monthIndex = monthNames.indexOf(month);
      let currentYear = parseInt(year);
      const data: any = [];

      for (let i = 0; i < 6; i++) {
        if (monthIndex < 0) {
          monthIndex += 12;
          currentYear--;
        }
        const monthAbbr = monthNames[monthIndex];
        const key = `${currentYear}-${monthAbbr}`;
        const points = lineChartData[key]?.totalPoints || "0";
        const totalPoints = convertPointsToNumber(points);
        data.push({ name: monthAbbr, pv: totalPoints });
        monthIndex--;
      }
      setCurrentMonth(monthNames[(monthIndex + 1 + 12) % 12]);
      setModifiedData(data.reverse());
    }
  }, [lineChartData]);
  function convertPointsToNumber(pointsString) {
    pointsString = pointsString.replace(/,/g, "");
    if (pointsString.toLowerCase().endsWith("k")) {
      return parseFloat(pointsString) * 1000;
    } else if (pointsString.toLowerCase().endsWith("m")) {
      return parseFloat(pointsString) * 1000000;
    } else {
      return parseFloat(pointsString);
    }
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={300}
        height={200}
        data={modifiedData}
        margin={{
          top: 0,
          right: 50,
          left: 0,
          bottom: 30,
        }}
      >
        <CartesianGrid stroke="#F1F1F5" strokeDasharray="0" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="pv"
          stroke="#0062FF"
          strokeWidth={3}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
