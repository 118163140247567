import { createAsyncThunk } from "@reduxjs/toolkit";
import bartle_test from "../services/bartle_test";

export const getBartleTestResults = createAsyncThunk(
  "getBartleTestResults",
  async (data: any, { rejectWithValue }) => {
    const url = `api/v1/assessment/results`;
    try {
      const response = await bartle_test.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
