import { Rate } from "antd";
import AvatarProfile from "../../Avatar/AvatarProfile";
import { RateComponent, WaitingASP } from "./SubmittedGoal";
import "./SubmittedGoal.css";
import React from "react";

const CompetancyCard = ({ data, profileData, otherProfileData }) => {
  return (
    <div className="submitted-goal-asp-form">
      <div className="goal-title">
        {data?.name}
        <div
          className="title-desc"
          dangerouslySetInnerHTML={{ __html: data?.description }}
        ></div>
      </div>
      <div className="feedback-asp-view">
        <div className="review-comment-card">
          <div
            className="comment-desc"
            dangerouslySetInnerHTML={{
              __html: data?.overAllSelfAssessment?.assessmentComment?.comment,
            }}
          />
          <div className="rating-container-launch">
            <RateComponent
              value={data?.overAllSelfAssessment?.rateScale?.rating}
            />
            <div className="profile-checkin">
              <div className="avatar-container">
                <AvatarProfile
                  name={profileData?.userName}
                  size={32}
                  profilePic={
                    profileData?.profilePictureImage &&
                    `data:image/png;base64,${profileData?.profilePictureImage}`
                  }
                />
              </div>

              <div className="userData-checkin">
                <div className="name-checkin">{profileData?.userName}</div>
                <div className="designation-checkin">
                  {profileData?.designation}
                </div>
              </div>
            </div>
          </div>
        </div>
        {data?.overAllOtherAssessment ? (
          <div className="review-comment-card">
            <div
              className="comment-desc"
              dangerouslySetInnerHTML={{
                __html:
                  data?.overAllOtherAssessment?.assessmentComment?.comment,
              }}
            />
            <div className="rating-container-launch">
              <RateComponent
                value={data?.overAllOtherAssessment?.rateScale?.rating}
              />
              <div className="profile-checkin">
                <div className="avatar-container">
                  <AvatarProfile
                    name={otherProfileData?.userName}
                    size={32}
                    profilePic={
                      otherProfileData?.profilePictureImage &&
                      `data:image/png;base64,${otherProfileData?.profilePictureImage}`
                    }
                  />
                </div>
                <div className="userData-checkin">
                  <div className="name-checkin">
                    {otherProfileData?.userName}
                  </div>
                  <div className="designation-checkin">
                    {otherProfileData?.designation}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <WaitingASP />
        )}
      </div>
    </div>
  );
};

export default CompetancyCard;
