import React, { useState, useEffect, useRef } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";
import { setActiveLockedBadgesFilters } from "../../../../../redux/reducers/gamification";
import {
  getBadgesFilter,
  getUserBadges,
  getBadgesDetailFilter,
} from "../../../../../redux/actions/gamification";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import LockedIconDetail from "./LockedIconDetail";
import AccountDropdown from "../LeaderBoardLanding/Dropdown";
import "./LockedBadgesDetail.css";
import "../LeaderBoardLanding/Dropdown.css";
import { BadgesDetailSkeleton } from "./ActiveIconDetail";

const LockedBadgesDetail = () => {
  const dispatch = useAppDispatch();

  const { lockedBadgesFilter, loading } = useAppSelector(
    (state) => state.gamification
  );
  const fetchLockedFilters = () => {
    dispatch(getBadgesDetailFilter({}));
  };
  useEffect(() => {
    dispatch(getUserBadges({}));
    fetchLockedFilters();
  }, []);

  const lockedfilterData = lockedBadgesFilter?.data;
  const activeBadgesFilters = useAppSelector(
    (state) => state?.gamification?.badgesFilterData
  );

  const moduleItems = lockedfilterData?.map((item) => {
    return { key: item, label: item };
  });

  useEffect(() => {
    const closeDropdown = () => {
      setActiveDropdown(null);
    };
    document.body.addEventListener("click", closeDropdown);
    return () => {
      document.body.removeEventListener("click", closeDropdown);
    };
  }, []);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const onClickClearAll = () => {
    dispatch(setActiveLockedBadgesFilters([]));
  };

  const handleDropdownClick = (dropdownName, event) => {
    if (event) {
      event.stopPropagation();
    }
    setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
  };

  if (loading) {
    return <BadgesDetailSkeleton loading={loading} />;
  }

  return (
    <div className="locked-badges_detail-otr">
      <div className="lock_badge-filter">
        <h1>Locked Badges</h1>

        <div className="filter_btn">
          <div
            onClick={(e) => handleDropdownClick("account", e)}
            className={`account_leaderboard ${
              activeBadgesFilters?.length > 0 ? "clicked" : ""
            }`}
          >
            <a className="module_title">
              Module{" "}
              {activeBadgesFilters?.length > 0
                ? `(${activeBadgesFilters?.length})`
                : null}{" "}
              {activeDropdown === "account" ? (
                <UpOutlined style={{ width: "12px", height: "12px" }} />
              ) : (
                <DownOutlined />
              )}
              <div className="dropdown_game">
                {activeDropdown === "account" && (
                  <AccountDropdown
                    data={moduleItems}
                    module={"Locked Badges"}
                    onClick={(e) => handleDropdownClick("account", e)}
                  />
                )}
              </div>
            </a>
          </div>
          <button
            className={
              activeBadgesFilters?.length > 0
                ? "locked_clear_game_active"
                : "locked_clear_game"
            }
            onClick={onClickClearAll}
          >
            Clear All
          </button>
        </div>
      </div>
      <div className="locked-badges_list-otr">
        <LockedIconDetail />
      </div>
    </div>
  );
};

export default LockedBadgesDetail;
