import "./App.css";
import RoutesScreeen from "./components/navigation/Routes";
import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  createBrowserRouter,
  Route,
  Link,
  Routes,
  RouterProvider,
} from "react-router-dom";
import n from "./components/navigation/Route.Names";
import Loading from "./components/common/loading/loading";
const MyWork = lazy(() => import("./components/MyWork/my_work"));
const RootLayout = lazy(() => import("./components/altiverse/main/main"));
const NoMatch = lazy(() => import("./components/common/noMatch/noMatch"));
const Home = lazy(() => import("./components/home/home"));
const Detail = lazy(() => import("./components/Detail"));
const PolicyDetail = lazy(() => import("./components/PolicyHub/PolicyDetail"));
const MySpace = lazy(() => import("./components/my_space/my_space"));
/*const Content = lazy(() => import('./components/my_space/Content'));
const EditPopUp = lazy(() => import('./components/my_space/EditContentPop'));*/

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loading />}>
        <RootLayout />
      </Suspense>
    ),
    errorElement: (
      <Suspense fallback={<Loading />}>
        <NoMatch isCode={true} message="Oops! Page Not Found" />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: `${n.ALTIVERSE}`,
        element: (
          <Suspense fallback={<Loading />}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: `${n.ALTIVERSE}/detail/:id`,
        element: (
          <Suspense fallback={<Loading />}>
            <Detail />
          </Suspense>
        ),
      },
      {
        path: `${n.SEPARATION_URL}`,
        element: (
          <Suspense fallback={<Loading />}>
            <MyWork />
          </Suspense>
        ),
      },
      {
        path: `${n.MY_SPACE}`,
        element: (
          <Suspense fallback={<Loading />}>
            <MySpace />
          </Suspense>
        ),
      },
      {
        path: `${n.MY_SPACE}/detail/`,
        element: (
          <Suspense fallback={<Loading />}>
            <PolicyDetail />
          </Suspense>
        ),
      },
    ],
  },
]);

function App() {
  return <RoutesScreeen />;
}

export default App;
