import { Modal, Spin } from "antd";
import FillButton from "../button/FillButton";
import OutlineButton from "../button/OutlineButton";
import { Link, useLocation } from "react-router-dom";
import { START } from "../../../constants";

const ModalDialog = (props) => {
  const { indexDetails = [], index: currentPage, beforeImageText = "" } = props;
  let location = useLocation();
  const { pathname } = location;
  //   const pageIndex = pathname === `/${START}/altiverse/EbookIntroduction`;
  const pageIndex = 1;
  const indexNavigation = (e, index) => {
    e.preventDefault();
    props.onOk(index);
  };
  return (
    <>
      <Modal
        className={props.modalStyles}
        open={props.openModal}
        footer={null}
        closable={props.closeIcon}
        onOk={props.onOk}
        onCancel={props.onCancel}
        maskClosable={props.maskClosable}
        centered={true}
      >
        <Spin spinning={props?.loading || false}>
          {beforeImageText ? (
            <h5 className={props?.modalSubHeading}>{beforeImageText}</h5>
          ) : (
            ""
          )}
          <img
            className={props.modalImageClasses}
            style={props.modalImageStyles}
            src={props.modalImage}
            alt=""
          />
          {props?.successHeading && (
            <h6 style={props?.modalHeadingStyles}>{props?.successHeading}</h6>
          )}
          {!beforeImageText && (
            <h5 className={props?.modalSubHeading}>{props?.DialogHeading}</h5>
          )}
          {pageIndex && (
            <div style={props?.popupDivider} className="popup-divider"></div>
          )}
          {pageIndex && (
            <div style={props.indexScroll} className="index-scroll">
              {(indexDetails || []).map((i, x) => {
                return (
                  <div
                    className={
                      currentPage == i.startPage
                        ? "index-active index-section"
                        : "index-section"
                    }
                    key={x}
                  >
                    <Link
                      className={"index-link"}
                      to=""
                      onClick={(e) => indexNavigation(e, i.startPage)}
                    >
                      <span className="index-title">{i.title}</span>{" "}
                      <span className="index-title">{i.startPage}</span>
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
          <img
            className={props.modalCenterImgClasses}
            style={props.modalCenterImgStyles}
            src={props.modalCenterImg}
            alt=""
          />
          <p className={props?.modalContent}>{props?.msgContent}</p>
          <div
            className={
              props.isSingleButton
                ? "button-section single-button-section"
                : "button-section"
            }
          >
            {props.isCancel ? (
              ""
            ) : (
              <OutlineButton
                className={props?.displayOutline}
                backText={props?.cancelTxt}
                outlineBtnAction={props?.cancelBtn}
              />
            )}
            <FillButton
              fillBtnTxt={props?.okTxt}
              fillBtnAction={props?.okBtn}
            />
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default ModalDialog;
