import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./PotentialRating.css";
import {
  ASP_SUBMIT_EVALUATION,
  CANCEL,
  PLACEHOLDER_RANK_SELECT,
  PERFORMANCE_URL,
  CONFIRMATION_TITLE,
  SUBMIT,
  CONFIRMATION_TITLE_CANCEL,
  NO,
  YES,
  WARNING,
  NOTIFICATION_DURATION,
  IN_PROGRESS,
  SLIDER_SPLIT_COUNT,
  TEAM_PERFORMANCE,
  START,
  MYWORK_URL,
  ASP_FORM,
  MY_SPACE,
  ASP_CANCEL_DRAFT_TITLE,
  ASP_CANCEL_DRAFT_DESC,
  SAVE,
  DISCARD_BUTTON,
  YEAR_END_DRAFT_TOAST_TITLE,
  YEAR_END_DRAFT_TOAST_MSG_DES,
  YEAR_END_DRAFT_TOAST_MSG_DES_REMAINIG,
  CHARACTERS_COUNT,
  CHARACTER_COUNT,
  COMMENTS_LENGTH_GREATER_TEXT,
  MINIMUM_CHAR_COUNT,
  NO_MIN_CHAR_COUNT,
  COMMENTS_LENGTH_LESSER_TEXT,
  DRAFT,
} from "../../../../../constants";
import "../../../ASPForm/GoalEvalution.css";
import "../../../performance.css";
import FillButton from "../../../../common/button/FillButton";
import {
  Card,
  Col,
  Row,
  Form,
  Input,
  Steps,
  Progress,
  Spin,
  notification,
  Rate,
  Button,
  FormInstance,
} from "antd";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import OutlineButton from "../../../../common/button/OutlineButton";
import LeftArrow from "../../../../../themes/assets/images/performance/svg/LeftArrow.svg";
import RightArrow from "../../../../../themes/assets/images/performance/svg/RightArrow.svg";
import TopArrow from "../../../../../themes/assets/images/performance/svg/TopArrow.svg";
import BottomArrow from "../../../../../themes/assets/images/performance/svg/BottomArrow.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/redux-hooks";
import {
  getAspFormSteps,
  getOverallFormRating,
  performanceToPotentialManager,
  postOverallFormRating,
  submitP2P,
  submitYearEndReview,
} from "../../../../../redux/actions/performance";
import BreadcrumbComponent from "../../../../common/breadcrumb/breadcrumb";
import RichTextEditor from "../../../components/RichTextEditor";
import ErrorComponent from "../../../../errorComponent/ErrorComponent";
import ModalDialog from "../../../../common/modal/modal";
import { notificationAction } from "../../../../../redux/reducers/performance";
import { ToastSuccessIcon } from "../../../../common/svg/svg";
import CommonTable from "../../../../common/CommonTable";
import amplitude from "amplitude-js";
interface SubmitButtonProps {
  form: FormInstance;
}

const CompetencyEvaluation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const param = useParams();
  const location = useLocation();
  const isManager = location.pathname.includes(TEAM_PERFORMANCE);
  const { reporteeId = "", year = "" } = param;

  const [api, contextHolder] = notification.useNotification({
    top: 0,
    maxCount: 1,
  });

  const [draftLoading, setDraftLoading] = useState(false);

  const [initialStepsValue, setInitialStepsValue] = useState<any>([]);
  const [selectedPreformance, setSelectedPerformance] = useState<any>({});
  const [settings, setSettings] = useState<any>({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeValues, setActiveValues] = useState<any>({});
  const [cancelModalOpen, setCancelModal] = useState(false);
  const values = Form.useWatch([], form);

  const {
    getAspFormStepsList,
    performanceToPotentialData,
    submitLoading,
    loadingP2P = false,
    configuration,
    viewGoalRawData,
    overallFormRatingManagerLoading,
    isLoading,
    submitOverallForm,
  } = useAppSelector((state: any) => state.performance);
  const { calculatedRateScale, evaluations, goalDetails } =
    viewGoalRawData || "";

  const { error = false } = configuration;

  const currentStep = initialStepsValue
    ?.map((item: any) => item.current)
    .indexOf(true);

  const firstSet =
    initialStepsValue !== undefined
      ? initialStepsValue.slice(0, SLIDER_SPLIT_COUNT)
      : [];
  const secondSet =
    initialStepsValue !== undefined
      ? initialStepsValue.slice(SLIDER_SPLIT_COUNT)
      : [];

  const handleCancel = () => {
    setIsModalOpen(false);
    setCancelModal(false);
  };

  const handleCancelOkay = () => {
    navigate(`/web/my_work/team_performance/aspform/${reporteeId}`);
  };

  const handleOk = () => {
    amplitude.getInstance().logEvent("Completed Manager Review");
    setIsModalOpen(false);
    dispatch(submitP2P(activeValues))
      ?.unwrap()
      ?.then(() =>
        dispatch(
          postOverallFormRating({
            year: year,
            reporteeId: reporteeId,
            payload: activeValues?.overallRatingObj,
          })
        )
          ?.unwrap()
          ?.then(() => {
            dispatch(
              submitYearEndReview({
                year: year,
                reporteeId: reporteeId,
              })
            )
              ?.unwrap()
              ?.then(() => {
                handleCancelOkay();
              });
          })
      );
  };

  const goBackToASP = () => {
    if (isManager) {
      navigate(
        `/${START}/${MYWORK_URL}/${TEAM_PERFORMANCE}/${ASP_FORM}/${reporteeId}`
      );
    } else {
      navigate(`/${START}/${MY_SPACE}/${ASP_FORM}`);
    }
  };

  const onClickSaveDraft = () => {
    setDraftLoading(true);
    setCancelModal(false);
    let tempData = {
      reporteeId: reporteeId,
      userId: performanceToPotentialData?.userId,
      year: year,
      body: {
        p2PSelected:
          selectedPreformance?.performancePotentialType ||
          values?.rank_selected,
        managerComment: values?.rank_comment,
      },
      overallRatingObj: [
        {
          evaluationName: "Overall Form Rating",
          assessment: {
            title: "Manager Assessment",
            rateScale: {
              title: "Rating Scale",
              rating: values?.rating ? values?.rating : "",
              isMandatory: true,
            },
            assessmentComment: {
              title: "Manager Comment",
              comment: values?.comment,
              isMandatory: false,
            },
            editable: true,
          },
          goalId: "",
        },
      ],
    };
    dispatch(submitP2P(tempData))
      ?.unwrap()
      ?.then(() =>
        dispatch(
          postOverallFormRating({
            year: year,
            reporteeId: reporteeId,
            payload: tempData?.overallRatingObj,
            message: `${YEAR_END_DRAFT_TOAST_MSG_DES} ${year} ${YEAR_END_DRAFT_TOAST_MSG_DES_REMAINIG}`,
            additionalInfo: YEAR_END_DRAFT_TOAST_TITLE,
            status: DRAFT,
          })
        )
          ?.unwrap()
          ?.then(() => {
            goBackToASP();
          })
      );
    setDraftLoading(false);
  };

  const onFinish = (values: any) => {
    setActiveValues({
      reporteeId: reporteeId,
      userId: performanceToPotentialData?.userId,
      year: year,
      body: {
        p2PSelected: selectedPreformance?.performancePotentialType,
        managerComment: values?.rank_comment,
      },
      overallRatingObj: [
        {
          evaluationName: "Overall Form Rating",
          assessment: {
            title: "Manager Assessment",
            rateScale: {
              title: "Rating Scale",
              rating: values?.rating,
              isMandatory: true,
            },
            assessmentComment: {
              title: "Manager Comment",
              comment: values?.comment,
              isMandatory: false,
            },
            editable: true,
          },
          goalId: "",
        },
      ],
    });
    setIsModalOpen(true);
  };

  useEffect(() => {
    form.setFieldsValue({
      comment:
        viewGoalRawData?.overallFormAssessment?.assessmentComment?.comment,
      rating:
        viewGoalRawData?.overallFormAssessment?.rateScale?.rating !== "0"
          ? viewGoalRawData?.overallFormAssessment?.rateScale?.rating
          : null,
      rank_comment: performanceToPotentialData?.managerComment?.sectionValue,
      rank_selected: performanceToPotentialData?.p2pSelected?.sectionValue,
    });
  }, [viewGoalRawData, performanceToPotentialData]);

  const breadbrumbItems = [
    {
      title: (
        <Link to={`/${PERFORMANCE_URL}`} className="link-highlight">
          Performance
        </Link>
      ),
    },
    {
      title: (
        <Link to={`/${PERFORMANCE_URL}`} className="link-highlight">
          ASP Form
        </Link>
      ),
    },
    {
      title: "Year End Evaluation",
    },
  ];

  useEffect(() => {
    dispatch(
      getAspFormSteps({
        reporteeId: reporteeId,
        year: year,
      })
    );
    dispatch(
      getOverallFormRating({
        year: year,
        reporteeId: reporteeId,
      })
    );

    dispatch(
      performanceToPotentialManager({
        year: year,
        reporteeId: reporteeId,
      })
    );
  }, []);

  useEffect(() => {
    const stepsList = getAspFormStepsList?.stepsList || [];
    setInitialStepsValue(getAspFormStepsList?.stepsList);
    const progresStatus = stepsList?.findIndex(
      (item: any) => item.subTitle === IN_PROGRESS
    );
    setSettings({
      ...settings,
      initialSlide: progresStatus > SLIDER_SPLIT_COUNT - 1 ? 1 : 0,
    });
  }, [getAspFormStepsList]);

  useEffect(() => {
    form.setFieldValue(
      "rank_selected",
      selectedPreformance?.performancePotentialType
        ? `${selectedPreformance?.performancePotentialType} : ${selectedPreformance?.performanceType} ${selectedPreformance?.potentialType}`
        : ""
    );
  }, [selectedPreformance]);

  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
      })
    );
  };

  useEffect(() => {
    if (configuration.isShowNotification) {
      const type = configuration.alertMessage.type;
      api[type]({
        message: ``,
        description: configuration.alertMessage.message,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon: <ToastSuccessIcon />,
        className: "custom-toast-area custom-success-toast",
        onClose: onCloseHandler,
      });
    }
  }, [configuration.isShowNotification]);

  const formatPercent = (percent) => {
    return `${percent.toFixed(2)}%`;
  };

  const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({
    form,
    children,
  }) => {
    const [submittable, setSubmittable] = useState<boolean>(false);

    // Watch all values
    const values = Form.useWatch([], form);

    useEffect(() => {
      form
        .validateFields({ validateOnly: true })
        .then((someData) => {
          setSubmittable(true);
        })
        .catch(() => setSubmittable(false));
    }, [form, values]);

    return (
      <Button
        type="primary"
        htmlType="submit"
        className="submit-fill-btn"
        disabled={!submittable}
      >
        {children}
      </Button>
    );
  };

  if (error) {
    return (
      <div className="p2p-container">
        <BreadcrumbComponent items={breadbrumbItems} />
        <div className="loadingP2P">
          <ErrorComponent
            image={require("../../../../../themes/assets/images/something-went-wrong.png")}
            title={"Something Went Wrong"}
            description={
              "We are looking to fix it. Please try again in few minutes."
            }
          />
        </div>
      </div>
    );
  }
  if (loadingP2P || overallFormRatingManagerLoading || draftLoading) {
    return (
      <div className="p2p-container">
        <BreadcrumbComponent items={breadbrumbItems} />
        <div className="loadingP2P">
          <Spin
            spinning={
              loadingP2P || overallFormRatingManagerLoading || draftLoading
            }
          />
        </div>
      </div>
    );
  }

  return (
    <div className="p2p-container layoutContainer">
      {contextHolder}
      <BreadcrumbComponent items={breadbrumbItems} />
      <Spin spinning={submitLoading || isLoading || submitOverallForm}>
        <div className="competency-heading-section p2p-breadcrumb">
          <h1>{`${year} Year End Evaluation`}</h1>
          <div className="progress-section">
            <p>{`Page ${performanceToPotentialData?.progressBarInfo?.currentStep} of ${performanceToPotentialData?.progressBarInfo?.totalSteps}`}</p>
            <Progress
              format={formatPercent}
              percent={
                (performanceToPotentialData?.progressBarInfo?.totalSteps /
                  performanceToPotentialData?.progressBarInfo?.currentStep) *
                100
              }
            />
          </div>
        </div>
        <Slider className="stepper-process p2p-stepper" {...settings}>
          <div className="stepper-section">
            <Steps
              status="process"
              current={currentStep}
              items={firstSet}
              labelPlacement="vertical"
              className="steps"
            />
          </div>
          <div className="stepper-section">
            <Steps
              status="process"
              current={currentStep}
              items={secondSet}
              labelPlacement="vertical"
              className="steps"
            />
          </div>
        </Slider>
        <Form form={form} name="p2pForm" onFinish={onFinish}>
          <Card
            key={"manager-overall-rating"}
            className="compotency-card"
            style={{ width: "100%" }}
          >
            <div className="compotency-main-section">
              <div className="compotency-inner-section">
                <h4>{viewGoalRawData?.title}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: viewGoalRawData?.description,
                  }}
                />
              </div>
              <div className="compotency-inner-section">
                <Form.Item
                  label={
                    viewGoalRawData?.overallFormAssessment?.rateScale?.title
                  }
                  name={["rating"]}
                  rules={[
                    {
                      required:
                        viewGoalRawData?.overallFormAssessment?.rateScale
                          ?.isMandatory,
                      message: "Please rate this goal.",
                    },
                  ]}
                >
                  <Rate className={"custom-rating"} />
                </Form.Item>
              </div>
              <div className="compotency-inner-section text-editor-section">
                <Form.Item
                  label={
                    viewGoalRawData?.overallFormAssessment?.assessmentComment
                      ?.title
                  }
                  name={["comment"]}
                  rules={[
                    {
                      required:
                        viewGoalRawData?.overallFormAssessment
                          ?.assessmentComment?.isMandatory,
                      max: CHARACTER_COUNT,
                      min: viewGoalRawData?.overallFormAssessment
                        ?.assessmentComment?.isMandatory
                        ? MINIMUM_CHAR_COUNT
                        : NO_MIN_CHAR_COUNT,
                      validator(rule, value) {
                        if (
                          !viewGoalRawData?.overallFormAssessment
                            ?.assessmentComment?.isMandatory &&
                          !value
                        ) {
                          return Promise.resolve();
                        }
                        if (
                          viewGoalRawData?.overallFormAssessment
                            ?.assessmentComment?.isMandatory &&
                          value &&
                          value?.length < MINIMUM_CHAR_COUNT
                        ) {
                          return Promise.reject(
                            new Error(COMMENTS_LENGTH_LESSER_TEXT)
                          );
                        } else if (value && value?.length > CHARACTER_COUNT) {
                          return Promise.reject(
                            new Error(COMMENTS_LENGTH_GREATER_TEXT)
                          );
                        } else {
                          if (
                            value &&
                            value?.length > MINIMUM_CHAR_COUNT &&
                            value?.length < CHARACTER_COUNT
                          )
                            return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <RichTextEditor />
                </Form.Item>
              </div>
            </div>
          </Card>
          {isManager && (
            <div className="rating-calculated-container">
              <div className="rating-calculated-title">
                <span className="calculated-title">
                  {calculatedRateScale?.title}
                </span>
                <span className="calculated-rating-btn">
                  {calculatedRateScale?.rating}
                </span>
              </div>
              <div>
                <CommonTable
                  goalDetails={goalDetails}
                  evaluations={evaluations}
                />
              </div>
            </div>
          )}

          <div className="layoutContainer">
            <div className="compotency-inner-section">
              <h4 className="common-short-heading">
                {performanceToPotentialData?.title}
              </h4>
              <p className="potential-rating-note">
                {performanceToPotentialData?.note}
              </p>
            </div>

            <Card className="compotency-card">
              <div className="compotency-main-section">
                <div className="compotency-inner-section">
                  <h4>{performanceToPotentialData?.rankingInfo?.title}</h4>
                  <p>{performanceToPotentialData?.rankingInfo?.description}</p>
                  <div className="cards_container">
                    <div className="x-axis-potential">
                      <img
                        className="top-arrow-x-axis"
                        src={TopArrow}
                        alt="Top Arrow"
                      />
                      <span>
                        {
                          performanceToPotentialData?.rankingInfo
                            ?.yaxisDescription
                        }
                      </span>
                      <img
                        className="bottom-arrow-x-axis"
                        src={BottomArrow}
                        alt="Bottom Arrow"
                      />
                    </div>
                    <Row gutter={16}>
                      {performanceToPotentialData?.rankingInfo?.performancePotentialOption?.map(
                        (card: any, index) => {
                          const showOverlay =
                            selectedPreformance?.performancePotentialType ===
                            card?.performancePotentialType;
                          return (
                            <Col
                              span={8}
                              onClick={() => setSelectedPerformance(card)}
                            >
                              <div
                                className="card_wrapper"
                                style={{ borderColor: card?.colour }}
                              >
                                {showOverlay && (
                                  <div
                                    className="overlay"
                                    style={{ backgroundColor: card?.colour }}
                                  ></div>
                                )}

                                <div className="card_content">
                                  <span
                                    className="potential_indicator"
                                    style={{
                                      background: card?.colour,
                                    }}
                                  ></span>
                                  <div>
                                    <div className="potential_chart_title">
                                      {card?.performancePotentialType}
                                    </div>
                                    <div className="potential_chart_desc">
                                      {`${card?.performanceType} ${card?.potentialType}`}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        }
                      )}
                    </Row>
                  </div>
                </div>
                <div className="y-axis-perfomance">
                  <img
                    className="left-arrow-y-axis"
                    src={LeftArrow}
                    alt="Left Arrow"
                  />
                  <span>
                    {performanceToPotentialData?.rankingInfo?.xaxisDescription}
                  </span>
                  <img
                    className="right-arrow-y-axis"
                    src={RightArrow}
                    alt="Right Arrow"
                  />
                </div>

                <div className="compotency-inner-section text-editor-section">
                  <Form.Item
                    name={"rank_selected"}
                    className="custom-rank"
                    label={
                      performanceToPotentialData?.p2pSelected?.sectionTitle
                    }
                    rules={[
                      {
                        required:
                          performanceToPotentialData?.rankingInfo?.mandatory,
                        message: "This field is mandatory",
                      },
                    ]}
                  >
                    <Input
                      className="rank-selected-input"
                      placeholder={PLACEHOLDER_RANK_SELECT}
                      disabled
                    />
                  </Form.Item>
                </div>
                <div className="compotency-inner-section text-editor-section">
                  <Form.Item
                    label={
                      performanceToPotentialData?.managerComment?.sectionTitle
                    }
                    name={"rank_comment"}
                    rules={[
                      {
                        max: CHARACTER_COUNT,

                        validator(rule, value) {
                          if (value && value?.length > CHARACTER_COUNT) {
                            return Promise.reject(
                              new Error(COMMENTS_LENGTH_GREATER_TEXT)
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <RichTextEditor />
                  </Form.Item>
                </div>
              </div>
            </Card>
            <div className="footer-btn-section">
              <div className="footer-btn-left">
                <OutlineButton
                  outlineBtnStyles={"cancel-outline-btn"}
                  outlineBtnAction={() => {
                    setCancelModal(true);
                  }}
                  backText={CANCEL}
                />
              </div>
              <div className="footer-btn-right">
                <OutlineButton
                  outlineBtnStyles={"previous-outline-btn"}
                  outlineBtnAction={() => {
                    navigate(-1);
                  }}
                  backText="Previous"
                />

                <Form.Item>
                  <SubmitButton form={form}>
                    {ASP_SUBMIT_EVALUATION}
                  </SubmitButton>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </Spin>

      <ModalDialog
        openModal={isModalOpen || cancelModalOpen}
        DialogHeading={
          isModalOpen ? CONFIRMATION_TITLE : ASP_CANCEL_DRAFT_TITLE
        }
        closeIcon={false}
        modalImageStyles={{ display: "none" }}
        modalHeadingStyles={{ display: "none" }}
        popupDivider={{ display: "none" }}
        indexScroll={{ display: "none" }}
        modalCenterImgStyles={{ display: "none" }}
        msgContent={
          isModalOpen
            ? `Submitting ${year} Year End Evaluation`
            : ASP_CANCEL_DRAFT_DESC
        }
        okTxt={isModalOpen ? SUBMIT : SAVE}
        cancelTxt={isModalOpen ? CANCEL : DISCARD_BUTTON}
        cancelBtn={isModalOpen ? handleCancel : goBackToASP}
        okBtn={isModalOpen ? handleOk : onClickSaveDraft}
        onOk={isModalOpen ? handleOk : onClickSaveDraft}
        onCancel={isModalOpen ? handleCancel : goBackToASP}
        modalStyles={"backpopup delete-milestone-popup compotency-popup"}
        maskClosable={false}
      />
    </div>
  );
};

export default CompetencyEvaluation;
