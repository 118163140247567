import { createAsyncThunk } from "@reduxjs/toolkit";
import gamificaition from "../services/gamification";
import ebooks from "../services/ebooks";

//get gamificationlandingpage action
export const getGameLanding = createAsyncThunk(
  "getGameLanding",
  async (args: any, { rejectWithValue }) => {
    const url = `api/v1/game-leaderboard/landingPage`;
    try {
      const response = await gamificaition.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get getLeaderboardList action
export const getLeaderboardList = createAsyncThunk(
  "getLeaderboardList",
  async (data: any, { rejectWithValue }) => {
    const { start, count } = data;
    const url = `api/v1/game-leaderboard/list?start=${start}&count=${count}`;
    try {
      const response = await gamificaition.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get getFilters action
export const getFilters = createAsyncThunk(
  "getFilters",
  async (args: any, { rejectWithValue }) => {
    const url = "api/v1/game-leaderboard/filters";
    try {
      const response = await gamificaition.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get userCardData action
export const getUserCardData = createAsyncThunk(
  "getUserCardData",
  async (args: any, { rejectWithValue }) => {
    const url = "api/v1/game-leaderboard/user-card";
    try {
      const response = await gamificaition.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get userDashboardData action
export const getUserDashboardData = createAsyncThunk(
  "getUserDashboardData",
  async (args: any, { rejectWithValue }) => {
    const url = "/api/v1/user-dashboard/landingPage";
    try {
      const response = await gamificaition.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get userData action
export const getUserProfileData = createAsyncThunk(
  "getUserProfileData",
  async (args: any, { rejectWithValue }) => {
    const url = "/api/v1/user-mgmt/user-profile/my-profile";
    try {
      const response = await ebooks.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//getResumeUploadData
export const getResumeData = createAsyncThunk(
  "getResumeData",
  async (args: any, { rejectWithValue }) => {
    const url = "/api/v1/web/employee-space/get:resume";
    try {
      const response = await ebooks.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get userBadges action
export const getUserBadges = createAsyncThunk(
  "getUserBadges",
  async (args: any, { rejectWithValue }) => {
    const url = "/api/v1/badges/landingPage";
    try {
      const response = await gamificaition.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get pointssummary action
export const getPointsSummaryData = createAsyncThunk(
  "getPointsSummaryData",
  async (arg: any, { rejectWithValue }) => {
    const url = "/api/v1/points/landingPage";
    try {
      const response = await gamificaition.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue;
    }
  }
);

//get pointssummary linechart action
export const getPointsLineChartData = createAsyncThunk(
  "getPointsLineChartData",
  async (arg: any, { rejectWithValue }) => {
    const url = "/api/v1/points/history-summary";
    try {
      const response = await gamificaition.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue;
    }
  }
);

//get points history by month
export const getPointsHistoryByMonth = createAsyncThunk(
  "getPointsHistoryByMonth",
  async (arg: any, { rejectWithValue }) => {
    const url = `/api/v1/points/history-details?month=${arg?.month}&start=${arg?.start}&count=5`;
    try {
      const response = await gamificaition.get(url);
      const result = response.data;
      return { result, month: arg?.month };
    } catch (error) {
      return rejectWithValue;
    }
  }
);

//post points history by month filter
export const getPointsHistoryByMonthFilter = createAsyncThunk(
  "getPointsHistoryByMonthFilter",
  async (arg: any, { rejectWithValue }) => {
    const url = `api/v1/points/filter?start=${arg?.start}&count=5`;
    try {
      const response = await gamificaition.post(url, {
        month: arg?.monthAndYear,
        game: arg?.gameName,
      });
      const result = response.data;
      return { result, month: arg?.month };
    } catch (error) {
      return rejectWithValue;
    }
  }
);

//get badges dropdown
export const getBadgesFilter = createAsyncThunk(
  "getBadgesFilter",
  async (arg: any, { rejectWithValue }) => {
    const url = "/api/v1/points/filter";
    try {
      const response = await gamificaition.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue;
    }
  }
);

//get badges detail dropdown
export const getBadgesDetailFilter = createAsyncThunk(
  "getBadgesDetailFilter",
  async (arg: any, { rejectWithValue }) => {
    const url = "api/v1/badges/filters";
    try {
      const response = await gamificaition.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue;
    }
  }
);

//get activity history filter action
export const getLeaderBoardActivityHistoryFilter = createAsyncThunk(
  "getLeaderBoardActivityHistoryFilter",
  async (arg: any, { rejectWithValue }) => {
    const url = `api/v1/game-leaderboard/list-filter-search?start=${arg?.start}&count=${arg?.count}`;
    try {
      const response = await gamificaition.post(url, arg?.payload);
      const result = response.data;
      return { result, start: arg?.start };
    } catch (error) {
      return rejectWithValue;
    }
  }
);
//get activity history filter action
export const getPointsActivityHistoryFilter = createAsyncThunk(
  "getPointsActivityHistoryFilter",
  async (arg: any, { rejectWithValue }) => {
    const url = "api/v1/points/filter?start=1&count=5";
    try {
      const response = await gamificaition.post(url, arg);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue;
    }
  }
);

//upload resume action
export const uploadResume = createAsyncThunk(
  "uploadResume",
  async (data: any, { rejectWithValue }) => {
    const { request } = data;
    const url = `api/v1/web/employee-space/upload:resume`;
    try {
      const response = await ebooks.post(url, request, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const result = await response.data;
      return result;
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);
