import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ASP_START_NEXT,
  CANCEL,
  CONFIRM_DELETE_LABEL,
  CONFIRM_DELETE_MSG,
  DELETE,
  DEX_EXTENTION,
  EDIT_GOAL_URL,
  MYWORK_URL,
  NOTIFICATION_DURATION,
  PERFORMANCE_URL,
  SF_EXTENTION,
  START,
  TEAM_PERFORMANCE,
  WARNING,
  TEAM_YEAR_END_REVIEW_TITLE,
  TEAMS_ASP_YEAR_END,
  CONFIRMATION_TITLE_CANCEL,
  YES,
  NO,
  MY_YEAR_END_FORM,
  MY_YEAR_END_REVIEW,
  ASP_FLOW,
  ASP_FORM,
  MY_SPACE,
  SLIDER_SPLIT_COUNT,
  IN_PROGRESS,
  CHARACTER_COUNT,
  FAILURE,
  TEXT_LIMIT_REACHED,
  CASCADED_BY,
  CONTRIBUTED_TO,
  SAVE,
  DISCARD_BUTTON,
  ASP_CANCEL_DRAFT_DESC,
  ASP_CANCEL_DRAFT_TITLE,
  YEAR_END_DRAFT_TOAST_TITLE,
  YEAR_END_DRAFT_TOAST_MSG_DES,
  YEAR_END_DRAFT_TOAST_MSG_DES_REMAINIG,
  DRAFT,
  MINIMUM_CHAR_COUNT,
  NO_MIN_CHAR_COUNT,
  OVERALL_COMMENTS,
  COMMENTS_LENGTH_GREATER_TEXT,
  COMMENTS_LENGTH_LESSER_TEXT,
  ADD_ACHIEVEMENTS_DES,
} from "../../../constants";
import FillButton from "../../common/button/FillButton";
import OutlineButton from "../../common/button/OutlineButton";
import n from "../../navigation/Route.Names";
import "../performance.css";
import "./GoalEvalution.css";
import {
  Card,
  Form,
  Rate,
  Progress,
  Steps,
  Spin,
  notification,
  FormInstance,
  Button,
  message,
} from "antd";
import EvaluationCard from "./GoalEvaluationCard";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import RichTextEditor from "../components/RichTextEditor";
import CardEdit from "../../../themes/assets/images/performance/ASPForm/PencilSimple.svg";
import CardDelete from "../../../themes/assets/images/performance/ASPForm/Trash.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import {
  getAspFormEmployeeGoalDetails,
  getAspFormSteps,
  saveAspFormDetails,
  deleteGoal,
} from "../../../redux/actions/performance";
import {
  notificationAction,
  resetGoal,
} from "../../../redux/reducers/performance";
import { GoalsErrorIcon, ToastSuccessIcon } from "../../common/svg/svg";
import { getAllGoalTemplate } from "../../../redux/actions/performance";
import ModalDialog from "../../common/modal/modal";
import DeleteGoalImage from "../../../themes/assets/images/performance/svg/DeleteGoal.svg";
import ManagerRatingCard from "../components/ManagerRatingCard";
import ErrorComponent from "../../errorComponent/ErrorComponent";
import GoalSelection from "../components/GoalSelection";
import GoalPlanSelection from "../components/GoalPlanSelection";
import amplitude from "amplitude-js";
import AchievementsCard from "../components/AchievementsCard";
interface SubmitButtonProps {
  form: FormInstance;
}
const GoalEvaluation = (props: any) => {
  const [api, contextHolder] = notification.useNotification({
    top: 0,
    maxCount: 1,
  });
  const {
    getAspFormEmployeeGoalList,
    getAspFormEmployeeGoalListLoading,
    getAspFormStepsList,
    configuration,
    isLoading,
    postAspFormCommentDataLoading,
    getAspFormEmployeeGoalListError,
  } = useAppSelector((state: any) => state.performance) || {};
  const { ratingStarConfig } = getAspFormEmployeeGoalList;
  const dispatch = useAppDispatch();
  const [initialFormValue, setInitialFormValue] = useState<any>([]);
  const [initialStepsValue, setInitialStepsValue] = useState<any>([]);
  const [settings, setSettings] = useState<any>({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
  });
  const [selectedGoal, setSelectedGoal] = useState<any>(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [cancelPopup, setCancelPopup] = useState<any>(false);
  let navigate = useNavigate();
  const location = useLocation();
  const isManager = location.pathname.includes(TEAM_PERFORMANCE);
  const [form] = Form.useForm();
  const param = useParams();
  const [showGoalSelectionMenu, setShowGoalSelectionMenu] = useState(false);
  const [showGoalPlan, setShowGoalPlan] = useState(false);
  const { appraisalId = "", reporteeId = "", year = "" } = param;

  useEffect(() => {
    if (isManager) {
      dispatch(
        getAspFormSteps({
          reporteeId: reporteeId,
          year: year,
        })
      );
      dispatch(
        getAspFormEmployeeGoalDetails({
          reporteeId: reporteeId,
          year: year,
        })
      );
    } else {
      dispatch(
        getAspFormSteps({
          appraisalId: appraisalId,
          year: year,
        })
      );
      dispatch(
        getAspFormEmployeeGoalDetails({
          appraisalId: appraisalId,
          year: year,
        })
      );
    }
  }, [appraisalId, reporteeId, year]);

  useEffect(() => {
    setInitialFormValue(getAspFormEmployeeGoalList?.consolidatedCategory);
    form.setFieldsValue({
      goal: getAspFormEmployeeGoalList?.consolidatedCategory,
      overallAssessment:
        getAspFormEmployeeGoalList?.overallAssessmentInfo?.comment,
    });
  }, [getAspFormEmployeeGoalList]);

  useEffect(() => {
    const stepsList = getAspFormStepsList?.stepsList || [];
    setInitialStepsValue(getAspFormStepsList?.stepsList);
    const progresStatus = stepsList?.findIndex(
      (item: any) => item.subTitle === IN_PROGRESS
    );
    setSettings({
      ...settings,
      initialSlide: progresStatus > SLIDER_SPLIT_COUNT - 1 ? 1 : 0,
    });
  }, [getAspFormStepsList]);

  const handleFormSubmit = async (values) => {
    const data = form.getFieldValue("goal");
    const goalFormDetails = data?.map((item: any, index) => {
      return {
        goalId: item?.goalId,
        assessment: {
          rateScale: {
            rating: item?.rating ? item?.rating : "",
          },
          assessmentComment: {
            comment: item?.comment,
          },
        },
      };
    });
    const overallAssessmentValue = form.getFieldValue("overallAssessment");
    if (overallAssessmentValue !== "<p><br></p>") {
      goalFormDetails.push({
        goalId: "-1",
        assessment: {
          assessmentComment: { comment: overallAssessmentValue },
        },
      });
    } else {
      goalFormDetails.push({
        goalId: "-1",
        assessment: {
          assessmentComment: { comment: "" },
        },
      });
    }

    if (isManager) {
      dispatch(
        saveAspFormDetails({
          year: year,
          request: goalFormDetails,
          reporteeId: reporteeId,
        })
      )
        .unwrap()
        .then((res) => {
          navigate(
            `/${START}/${MYWORK_URL}/${TEAM_PERFORMANCE}/${n.COMPETENCY_EVALUATION_URL}/${reporteeId}/${year}`
          );
        });
    } else {
      dispatch(
        saveAspFormDetails({
          year: year,
          appraisalId: appraisalId,
          request: goalFormDetails,
        })
      )
        .unwrap()
        .then((res) => {
          navigate(
            `/${n.PERFORMANCE_URL}/${n.COMPETENCY_EVALUATION_URL}/detail/${year}/${appraisalId}`
          );
        });
    }
  };
  const onClickSaveDraft = () => {
    const data = form.getFieldValue("goal");
    const goalFormDetails = data?.map((item: any, index) => {
      return {
        goalId: item?.goalId,
        assessment: {
          rateScale: {
            rating: item?.rating ? item?.rating : "",
          },
          assessmentComment: {
            comment: item?.comment,
          },
        },
      };
    });
    const overallAssessmentValue = form.getFieldValue("overallAssessment");
    if (overallAssessmentValue !== "<p><br></p>") {
      goalFormDetails.push({
        goalId: "-1",
        assessment: {
          assessmentComment: { comment: overallAssessmentValue },
        },
      });
    } else {
      goalFormDetails.push({
        goalId: "-1",
        assessment: {
          assessmentComment: { comment: "" },
        },
      });
    }
    if (isManager) {
      dispatch(
        saveAspFormDetails({
          year: year,
          request: goalFormDetails,
          reporteeId: reporteeId,
          message: `${YEAR_END_DRAFT_TOAST_MSG_DES} ${year} ${YEAR_END_DRAFT_TOAST_MSG_DES_REMAINIG}`,
          additionalInfo: YEAR_END_DRAFT_TOAST_TITLE,
          status: DRAFT,
        })
      )
        .unwrap()
        .then((res) => {
          goBack();
        });
    } else {
      dispatch(
        saveAspFormDetails({
          year: year,
          appraisalId: appraisalId,
          request: goalFormDetails,
          message: `${YEAR_END_DRAFT_TOAST_MSG_DES} ${year} ${YEAR_END_DRAFT_TOAST_MSG_DES_REMAINIG}`,
          additionalInfo: YEAR_END_DRAFT_TOAST_TITLE,
          status: DRAFT,
        })
      )
        .unwrap()
        .then((res) => {
          goBack();
        });
    }
  };

  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
      })
    );
  };
  useEffect(() => {
    if (configuration.isShowNotification) {
      const type = configuration.alertMessage.type;
      api[type]({
        message: ``,
        description: configuration.alertMessage.message,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon: <ToastSuccessIcon />,
        className: "custom-toast-area custom-success-toast",
        onClose: onCloseHandler,
      });
    }
  }, [configuration.isShowNotification]);

  useEffect(() => {
    dispatch(getAllGoalTemplate({}));
  }, []);

  const handleDeleteGoal = () => {
    const queryParams = { year: selectedGoal?.year || 2024 };
    if (selectedGoal?.goalId) {
      queryParams["sfGoalId"] = selectedGoal?.goalId;
      dispatch(deleteGoal(queryParams))
        .unwrap()
        .then(() => {
          dispatch(
            getAspFormEmployeeGoalDetails({
              appraisalId: appraisalId,
              year: year,
            })
          );
        });
      hideModal();
    }
  };
  const hideModal = () => {
    setSelectedGoal(null);
    setDeleteModalVisible(false);
  };
  const showDeleteModal = (goal: any) => {
    setSelectedGoal(goal);
    setDeleteModalVisible(true);
  };

  const editHandler = (goal) => {
    const id = goal?.goalId
      ? `${SF_EXTENTION}-${goal.goalId}`
      : `${SF_EXTENTION}-${goal.sfGoalId}`;
    const year = goal?.year;
    const cascadeStatus = goal?.cascadeOpen;
    const baseUrl = `/${n.PERFORMANCE_URL}/${EDIT_GOAL_URL}/${year}/${id}`;
    const url = cascadeStatus ? `${baseUrl}?cascadedGoal=true` : baseUrl;
    navigate(url);
  };

  const currentStep = initialStepsValue
    ?.map((item: any) => item.current)
    .indexOf(true);

  const firstSet =
    initialStepsValue !== undefined
      ? initialStepsValue.slice(0, SLIDER_SPLIT_COUNT)
      : [];
  const secondSet =
    initialStepsValue !== undefined
      ? initialStepsValue.slice(SLIDER_SPLIT_COUNT)
      : [];

  const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({
    form,
    children,
  }) => {
    const [submittable, setSubmittable] = useState<boolean>(false);

    // Watch all values
    const values = Form.useWatch([], form);

    useEffect(() => {
      form
        .validateFields({ validateOnly: true })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false));
    }, [form, values]);

    return (
      <Button
        className="submit-fill-btn"
        type="primary"
        htmlType="submit"
        disabled={!submittable}
      >
        {children}
      </Button>
    );
  };

  const onChangeHandler = (data: any) => {
    if (data >= CHARACTER_COUNT) {
      const type = FAILURE;
      api[type]({
        message: ``,
        description: TEXT_LIMIT_REACHED,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon: <GoalsErrorIcon />,
        className: "custom-toast-area custom-error-toast",
        // onClose: onCloseHandler,
      });
    }
  };
  const goBack = () => {
    hideCancelPopup();
    if (isManager) {
      navigate(
        `/${START}/${MYWORK_URL}/${TEAM_PERFORMANCE}/${ASP_FORM}/${reporteeId}`
      );
    } else {
      navigate(`/${START}/${MY_SPACE}/${ASP_FORM}`);
    }
  };
  const formatPercent = (percent) => {
    return `${percent.toFixed(2)}%`;
  };

  const onClickGoal = (type) => {
    if (type === "personal") {
      dispatch(resetGoal());
      navigate(`/${n.PERFORMANCE_URL}/${n.NEW_GOAL_URL}/${year}`);
    } else if (type === "archived") {
      navigate(`/${n.PERFORMANCE_URL}/${n.ARCHIVED_GOAL_URL}`);
    } else if (type === "goal") {
    } else if (type === "copyGoal") {
      setShowGoalSelectionMenu(false);
      setShowGoalPlan(true);
    }
  };

  const hideCancelPopup = () => {
    setCancelPopup(false);
  };
  const breadbrumbItems = isManager
    ? [
        {
          title: (
            <Link
              to={`/${n.START}/${n.MYWORK_URL}/${n.TEAM_PERFORMANCE}/asp`}
              className="link-highlight"
            >
              {TEAM_YEAR_END_REVIEW_TITLE}
            </Link>
          ),
        },
        {
          title: (
            <Link
              to={`/${n.START}/${n.MYWORK_URL}/${n.TEAM_PERFORMANCE}/aspform/${
                getAspFormEmployeeGoalList?.reporteeId || reporteeId
              }`}
              className="link-highlight"
            >
              {getAspFormEmployeeGoalList?.reporteeInfo?.userName}
            </Link>
          ),
        },
        {
          title: `${year} ${TEAMS_ASP_YEAR_END}`,
        },
      ]
    : [
        {
          title: (
            <Link
              to={`/${n.START}/${n.MY_SPACE}/AspForm`}
              className="link-highlight"
            >
              {MY_YEAR_END_REVIEW}
            </Link>
          ),
        },
        {
          title: `${MY_YEAR_END_FORM} ${year}`,
        },
      ];

  if (getAspFormEmployeeGoalListError) {
    return (
      <div className="p2p-container">
        <BreadcrumbComponent items={breadbrumbItems} />
        <div className="loadingP2P">
          <ErrorComponent
            image={require("../../../themes/assets/images/something-went-wrong.png")}
            title={"Something Went Wrong"}
            description={
              "We are looking to fix it. Please try again in few minutes."
            }
          />
        </div>
      </div>
    );
  }
  if (isLoading) {
    return (
      <div className="p2p-container">
        <BreadcrumbComponent items={breadbrumbItems} />
        <div className="loadingP2P">
          <Spin spinning={isLoading} />
        </div>
      </div>
    );
  }

  return (
    <div className="goal-evalution-eval">
      {contextHolder}

      <div className="layoutContainer">
        <BreadcrumbComponent items={breadbrumbItems} />
        <Spin spinning={isLoading}>
          <div className="competency-heading-section">
            <h1>{year} Year End Evaluation</h1>
            <div className="progress-section">
              <p>
                Page {getAspFormEmployeeGoalList?.progressBarInfo?.currentStep}{" "}
                of {getAspFormEmployeeGoalList?.progressBarInfo?.totalSteps}
              </p>
              <Progress
                format={formatPercent}
                percent={
                  (getAspFormEmployeeGoalList?.progressBarInfo?.currentStep *
                    100) /
                  getAspFormEmployeeGoalList?.progressBarInfo?.totalSteps
                }
              />
            </div>
          </div>
          <Slider className="stepper-process" {...settings}>
            <div className="stepper-section">
              <Steps
                status="process"
                current={currentStep}
                items={firstSet}
                labelPlacement="vertical"
                className="steps"
              />
            </div>
            <div className="stepper-section">
              <Steps
                status="process"
                current={currentStep}
                items={secondSet}
                labelPlacement="vertical"
                className="steps"
              />
            </div>
          </Slider>
          <div className="goal-evalution-head">
            <div className="goal-evalution-head-title">
              <h1>
                Goal Evaluation
                <span>{getAspFormEmployeeGoalList?.totalWeight}%</span>
              </h1>
              {!isManager && (
                <div className="add-milestone-btn">
                  <OutlineButton
                    outlineBtnStyles="outlineBtnStyles"
                    backText=" + Add Goal"
                    outlineBtnAction={() => {
                      setShowGoalSelectionMenu(true);
                      amplitude.getInstance().logEvent("Clicked Add Goal");
                    }}
                  />
                </div>
              )}
            </div>

            <Form
              form={form}
              layout="vertical"
              name="basic"
              autoComplete="off"
              onFinish={(values) => handleFormSubmit(values)}
            >
              <Form.List name={["goal"]} initialValue={initialFormValue}>
                {(fields, { add, remove }) => (
                  <>
                    {fields?.map(({ key, name, ...restField }, index) => {
                      return (
                        <>
                          <ModalDialog
                            openModal={deleteModalVisible}
                            DialogHeading={CONFIRM_DELETE_LABEL}
                            closeIcon={false}
                            indexScroll={{ display: "none" }}
                            modalHeadingStyles={{ dislay: "none" }}
                            popupDivider={{ display: "none" }}
                            visible={deleteModalVisible}
                            msgContent={<p>{CONFIRM_DELETE_MSG}?</p>}
                            okTxt={DELETE}
                            cancelTxt={CANCEL}
                            cancelBtn={hideModal}
                            okBtn={() => {
                              remove(name);
                              handleDeleteGoal();
                            }}
                            onOk={() => {
                              remove(name);
                              handleDeleteGoal();
                            }}
                            onCancel={hideModal}
                            modalSubHeading={"modalSubHeading"}
                            modalStyles={`backpopup ${
                              deleteModalVisible ? "delete-milestone-popup" : ""
                            }`}
                            modalContent="archive-goal-sub-title"
                            maskClosable={false}
                            modalImageClasses={
                              deleteModalVisible
                                ? "delete-goal"
                                : "archive-goal-popup"
                            }
                            modalImage={DeleteGoalImage}
                            modalCenterImgStyles={{ display: "none" }}
                          />
                          <Card
                            key={key}
                            className="card_list-evalution"
                            style={{ width: "100%" }}
                          >
                            <span className="badge_card">
                              {initialFormValue?.[index]?.category}
                            </span>
                            {!isManager && (
                              <div className="card_edit-sec">
                                <span className="edit_card">
                                  <img
                                    src={CardEdit}
                                    onClick={() =>
                                      editHandler(initialFormValue?.[index])
                                    }
                                  />
                                </span>
                                <span
                                  onClick={() =>
                                    showDeleteModal(initialFormValue?.[index])
                                  }
                                  className="delete_card"
                                >
                                  <img src={CardDelete} />
                                </span>
                              </div>
                            )}
                            <div className="goal-evalution-title">
                              <h2>{initialFormValue[index]?.goalName}</h2>
                              {initialFormValue?.[index]?.cascadedBy && (
                                <div className="goal-cascade">
                                  {`${CASCADED_BY}
                                ${initialFormValue?.[index]?.cascadedBy}`}
                                </div>
                              )}
                              {initialFormValue?.[index]?.ownerName && (
                                <div className="goal-contributed">
                                  {`${CONTRIBUTED_TO} 
                                ${initialFormValue?.[index]?.ownerName}`}
                                </div>
                              )}
                            </div>
                            <div className="compotency-main-section">
                              <div className="compotency-inner-section">
                                <Form.Item
                                  label={initialFormValue?.[index]?.ratingTitle}
                                  name={[name, "rating"]}
                                  rules={[
                                    {
                                      required:
                                        initialFormValue?.[index]
                                          ?.mandatoryRating,
                                      message: "Please rate this goal.",
                                    },
                                  ]}
                                >
                                  <Rate className={"custom-rating"} />
                                </Form.Item>
                              </div>
                              <div className="compotency-inner-section text-editor-section">
                                <Form.Item
                                  label={
                                    initialFormValue?.[index]?.commentTitle
                                  }
                                  name={[name, "comment"]}
                                  rules={[
                                    {
                                      required:
                                        initialFormValue?.[index]
                                          ?.mandatoryComment,

                                      max: CHARACTER_COUNT,
                                      min: initialFormValue?.[index]
                                        ?.mandatoryComment
                                        ? MINIMUM_CHAR_COUNT
                                        : NO_MIN_CHAR_COUNT,
                                      validator(rule, value) {
                                        if (
                                          !initialFormValue?.[index]
                                            ?.mandatoryComment &&
                                          !value
                                        ) {
                                          return Promise.resolve();
                                        }
                                        if (
                                          initialFormValue?.[index]
                                            ?.mandatoryComment &&
                                          value &&
                                          value?.length < MINIMUM_CHAR_COUNT
                                        ) {
                                          return Promise.reject(
                                            new Error(
                                              COMMENTS_LENGTH_LESSER_TEXT
                                            )
                                          );
                                        } else if (
                                          value &&
                                          value?.length > CHARACTER_COUNT
                                        ) {
                                          return Promise.reject(
                                            new Error(
                                              COMMENTS_LENGTH_GREATER_TEXT
                                            )
                                          );
                                        } else {
                                          if (
                                            value &&
                                            value?.length >
                                              MINIMUM_CHAR_COUNT &&
                                            value?.length < CHARACTER_COUNT
                                          )
                                            return Promise.resolve();
                                        }
                                      },
                                    },
                                  ]}
                                >
                                  <RichTextEditor />
                                </Form.Item>
                              </div>
                            </div>

                            {isManager && (
                              <ManagerRatingCard
                                comment={
                                  initialFormValue?.[index]?.otherAssessment
                                    ?.assessmentComment?.comment
                                }
                                rating={
                                  initialFormValue?.[index]?.otherAssessment
                                    ?.rateScale?.rating
                                }
                                managerInfo={
                                  getAspFormEmployeeGoalList?.reporteeInfo
                                }
                              />
                            )}
                            <div className="goal-evalution-list">
                              <div style={{ flex: 1 }}>
                                <EvaluationCard
                                  title={"Goal Name"}
                                  value={initialFormValue?.[index]?.goalName}
                                />
                                <EvaluationCard
                                  title={"Metric"}
                                  value={initialFormValue?.[index]?.metric}
                                />
                                <EvaluationCard
                                  title={"Start Date"}
                                  value={initialFormValue?.[index]?.start_date}
                                />
                                <EvaluationCard
                                  title={"Due Date"}
                                  value={initialFormValue?.[index]?.end_date}
                                />
                                <EvaluationCard
                                  title={"Status"}
                                  value={initialFormValue?.[index]?.goalStatus}
                                />
                                <EvaluationCard
                                  title={"Weight"}
                                  value={
                                    initialFormValue?.[index]?.weight + " %"
                                  }
                                />
                              </div>
                              <div style={{ flex: 1 }}>
                                <EvaluationCard
                                  title={"Goal Description"}
                                  value={
                                    initialFormValue?.[index]?.goalDescription
                                  }
                                />
                              </div>
                            </div>
                          </Card>
                        </>
                      );
                    })}
                  </>
                )}
              </Form.List>

              <Card
                className="card_list-evalution field_label  text-editor-section"
                style={{ width: "100%" }}
              >
                <div className="goal-evalution-title">
                  <h3>{OVERALL_COMMENTS}</h3>
                </div>
                <Form.Item
                  name={"overallAssessment"}
                  rules={[
                    {
                      max: CHARACTER_COUNT,
                      validator(rule, value) {
                        if (value && value?.length > CHARACTER_COUNT) {
                          return Promise.reject(
                            new Error(COMMENTS_LENGTH_GREATER_TEXT)
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <RichTextEditor />
                </Form.Item>
                {isManager && (
                  <ManagerRatingCard
                    comment={
                      getAspFormEmployeeGoalList?.overallOtherAssessmentInfo
                        ?.assessmentComment?.comment
                    }
                    hideRating={true}
                    managerInfo={getAspFormEmployeeGoalList?.reporteeInfo}
                  />
                )}
              </Card>
              <AchievementsCard description={ADD_ACHIEVEMENTS_DES} />
              <div className="goal_outline-btn-sec">
                <OutlineButton
                  outlineBtnStyles={"cancel-outline-btn"}
                  outlineBtnAction={() => {
                    setCancelPopup(true);
                  }}
                  backText="Cancel"
                />
                <div className="goal_fill-inner-btn-sec">
                  <Form.Item>
                    <SubmitButton form={form}>{ASP_START_NEXT}</SubmitButton>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </Spin>
      </div>
      {showGoalSelectionMenu && (
        <GoalSelection
          onClickOpen={showGoalSelectionMenu}
          onClickCancel={() => setShowGoalSelectionMenu(false)}
          onClickGoal={onClickGoal}
        />
      )}
      {showGoalPlan && (
        <GoalPlanSelection
          onClickOpen={showGoalPlan}
          onClickCancel={() => setShowGoalPlan(false)}
        />
      )}
      <ModalDialog
        openModal={cancelPopup}
        DialogHeading={ASP_CANCEL_DRAFT_TITLE}
        closeIcon={false}
        modalImageStyles={{ display: "none" }}
        modalHeadingStyles={{ display: "none" }}
        popupDivider={{ display: "none" }}
        indexScroll={{ display: "none" }}
        modalCenterImgStyles={{ display: "none" }}
        msgContent={ASP_CANCEL_DRAFT_DESC}
        okTxt={SAVE}
        cancelTxt={DISCARD_BUTTON}
        cancelBtn={goBack}
        okBtn={onClickSaveDraft}
        onOk={onClickSaveDraft}
        onCancel={goBack}
        modalStyles={"backpopup delete-milestone-popup compotency-popup"}
        maskClosable={false}
      />
    </div>
  );
};

export default GoalEvaluation;
