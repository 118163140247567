import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../redux/store/redux-hooks";
import {
  typeChangeAction,
  deductionAction,
  remarksChangeAction,
  notificationAction,
  beforeUploadAction,
} from "../../../redux/reducers/details";
import { CardType, Status } from "./ClearanceInterface";
import {
  getClearanceDetail,
  downloadAttachment,
  saveClearanceDetail,
  uploadAttachment,
  deleteAttachment,
} from "../../../redux/actions/details";
import NotificationComponent from "../../common/notifications/notifications";
import routePath from "../../navigation/Route.Names";
import "./Clearance.css";
import {
  WARNING,
  BACK,
  SUBMIT,
  FILE_SUPPORTED_FORMATS,
  THOUSAND_TWENTY_FOUR,
  FILE_MB,
  SUPPORTED_FILE,
  CLEARANCE_SUCCESS_MESSAGE,
  NAME_REPLACE_STRING,
  CLEARANCE_SIZE_ERROR_MESSAGE,
  FILE_NOTSUPPORTED_ERROR_MESSAGE,
  SUCCESS,
  FAILURE,
  APPROVE_NOTATION,
  CONFIRMATION_TITLE,
  CONFIRMATION_SUBTITLE,
  CANCEL,
  TEXTAREA_MAX_LENGTH,
  NO_DETAILS_DATA_FOUND,
  START,
} from "../../../constants";
import Icon, { CloseOutlined } from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import {
  Card,
  Space,
  Input,
  Radio,
  Row,
  Button,
  Alert,
  Upload,
  List,
  InputNumber,
  Spin,
  Modal,
} from "antd";
import type { RadioChangeEvent } from "antd";
import { replaceString } from "../../utils/util";
import { attachmentIcon } from "../../common/svg/svg";
import info from "../../../themes/assets/images/svg/Info.svg";
import { nodatafoundIcon } from "../../common/svg/svg";

const { confirm } = Modal;
const { TextArea } = Input;

const AttachIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={attachmentIcon} {...props} />
);

const Clearance = () => {
  const navigate = useNavigate();
  const { empId, locId } = useParams();
  const clearance = useAppSelector((state: any) => state.cardDetail);
  const { employeeDetail, cardDetails, pageTitle } = clearance.details;
  const { configuration } = clearance;
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getClearanceDetail({ empId, locId }));
  }, []);

  useEffect(() => {
    if (
      configuration.alertMessage.type === SUCCESS &&
      configuration.alertMessage.message !== ""
    ) {
      backHandler();
    }
  }, [configuration.alertMessage.type]);

  const beforeUploadHandler = (file: any, index: number) => {
    const isPNG = SUPPORTED_FILE.indexOf(file.type) > -1;
    const isGt5MB =
      file.size / THOUSAND_TWENTY_FOUR / THOUSAND_TWENTY_FOUR > FILE_MB;
    if (!isPNG) {
      dispatch(
        beforeUploadAction({
          type: FAILURE,
          message: FILE_NOTSUPPORTED_ERROR_MESSAGE,
          isShowNotification: true,
          value: cardDetails,
          index,
        })
      );
      return false;
    } else if (isGt5MB) {
      dispatch(
        beforeUploadAction({
          type: FAILURE,
          message: CLEARANCE_SIZE_ERROR_MESSAGE,
          isShowNotification: true,
          value: cardDetails,
          index,
        })
      );
      return false;
    }
    return true;
  };

  const onRemoveHandler = (attachmentId: string, index: number) => {
    dispatch(deleteAttachment({ index, attachmentId }));
  };

  const onDownloadHandler = (file: any, attachmentId: string) => {
    dispatch(downloadAttachment({ file, attachmentId }));
  };

  const onCustomRequestHandler = async (options: any, index: number) => {
    const { file } = options;
    const { name: fileName } = file;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      const base64 = e.target.result;
      const split = base64.split(",");
      const isGt5MB =
        split[1].length / THOUSAND_TWENTY_FOUR / THOUSAND_TWENTY_FOUR > FILE_MB;
      if (isGt5MB) {
        dispatch(
          beforeUploadAction({
            type: FAILURE,
            message: CLEARANCE_SIZE_ERROR_MESSAGE,
            isShowNotification: true,
            index,
          })
        );
      } else {
        dispatch(uploadAttachment({ fileName, fileContent: split[1], index }));
      }
    };
    reader.readAsDataURL(file);
  };

  const typeChangeHandler = (e: RadioChangeEvent, index: number) => {
    let fc = document.querySelector(
      `#row-${index} .anticon-delete`
    ) as HTMLElement;
    if (fc) {
      fc.click();
    }
    dispatch(typeChangeAction({ status: e.target.value, index }));
  };

  const deductionChangeHandler = (value: number | null, index: number) => {
    dispatch(deductionAction({ value: value ? value.toString() : "", index }));
  };

  const remarksChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    dispatch(remarksChangeAction({ value: e.target.value, index }));
  };

  const destroyAll = () => {
    Modal.destroyAll();
  };

  const showConfirm = () => {
    confirm({
      closeIcon: <CloseOutlined />,
      content: CONFIRMATION_SUBTITLE,
      title: CONFIRMATION_TITLE,
      okText: SUBMIT,
      cancelText: CANCEL,
      centered: true,
      // closable:true,
      icon: null,
      width: "600px",
      onOk() {
        okHandler();
        destroyAll();
      },
      onCancel() {
        destroyAll();
      },
    });
  };

  const submitHandler = () => {
    showConfirm();
  };

  const okHandler = async () => {
    const filtercard = cardDetails.map((item: any) => ({
      clearanceItemCode: item.clearanceItemCode,
      clearanceItemStatus: item.clearanceStatus,
      deductionValue: item.deductionValue,
      attachmentId: item.attachmentId,
      remarksValue: item.remarksValue,
      isEditable: item.isEditable,
    }));
    const custommessage = replaceString(
      CLEARANCE_SUCCESS_MESSAGE,
      NAME_REPLACE_STRING,
      employeeDetail.employeeName
    );
    const request = { filtercard, empId, locId, custommessage };
    await dispatch(saveClearanceDetail(request));
  };

  const backHandler = () => {
    navigate(`/${START}/${routePath.SEPARATION_URL}`);
  };

  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
      })
    );
  };
  const locale = {
    emptyText: !clearance.loading ? (
      <div>
        {nodatafoundIcon()}
        <p className="no-data-found">{NO_DETAILS_DATA_FOUND}</p>
        <Button type="primary" onClick={backHandler}>
          {BACK}
        </Button>
      </div>
    ) : (
      <div></div>
    ),
  };
  return (
    <>
      <div className="clearance-module-section">
        <div className="sub-header">
          {configuration.isShowNotification && (
            <NotificationComponent
              type={configuration.alertMessage.type}
              message={configuration.alertMessage.message}
              onClose={onCloseHandler}
            />
          )}
          {/*<BreadcrumbComponent/>*/}
          <h1 className="page-title">{pageTitle}</h1>
          <div>
            <h3 className="employeeName">{employeeDetail?.employeeName}</h3>
            <h5 className="employeeId">{employeeDetail?.employeeId}</h5>
          </div>
        </div>
        <Spin spinning={clearance.loading} delay={500}>
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
              xxl: 2,
            }}
            locale={locale}
            dataSource={cardDetails}
            renderItem={(element: CardType, index: number) => (
              <List.Item id={`row-${index}`}>
                <Card className="card-section">
                  <h1 data-testid="item-label">{element.clearanceItemLabel}</h1>
                  <div className="marginBottom">
                    <Radio.Group
                      onChange={(event) => typeChangeHandler(event, index)}
                      value={element.clearanceStatus}
                      disabled={!element.isEditable}
                      className={!element.isEditable ? "" : "radio-group"}
                      data-testid="type"
                    >
                      {(element.statusList || []).map(
                        (status: Status, i: number) => (
                          <Radio key={`${index}${i}`} value={status.value}>
                            {status.label}
                          </Radio>
                        )
                      )}
                    </Radio.Group>
                  </div>
                  <div className="inputFlex">
                    <div className="deduction-attachment-space">
                      <InputNumber
                        data-testid="deduction"
                        placeholder={element.deductionLabel}
                        keyboard={true}
                        min={1}
                        disabled={
                          element.clearanceStatus !== APPROVE_NOTATION ||
                          !element.isEditable
                        }
                        className={
                          element.clearanceStatus !== APPROVE_NOTATION ||
                          !element.isEditable
                            ? "deduction control-border"
                            : "deduction control-border placeholder"
                        }
                        value={element.deductionValue}
                        onChange={(event) =>
                          deductionChangeHandler(event, index)
                        }
                      />
                      <Upload
                        beforeUpload={(file) =>
                          beforeUploadHandler(file, index)
                        }
                        onRemove={(file) =>
                          onRemoveHandler(element.attachmentId, index)
                        }
                        onDownload={(file) =>
                          onDownloadHandler(file, element.attachmentId)
                        }
                        maxCount={1}
                        defaultFileList={element.attachmentDetails}
                        fileList={element.attachmentDetails}
                        accept={FILE_SUPPORTED_FORMATS}
                        disabled={
                          (element.attachmentId === "" &&
                            element.attachmentId === null) ||
                          !element.isEditable ||
                          element.clearanceStatus !== APPROVE_NOTATION
                        }
                        showUploadList={
                          element.isValidFile
                            ? {
                                showDownloadIcon:
                                  element.attachmentId !== "" &&
                                  element.attachmentId !== null
                                    ? true
                                    : false,
                                showRemoveIcon: element.isEditable,
                              }
                            : false
                        }
                        customRequest={(file) =>
                          onCustomRequestHandler(file, index)
                        }
                      >
                        {element.attachmentDetails.length === 0 && (
                          <Button
                            data-testid="add-attachment"
                            icon={<AttachIcon />}
                            disabled={
                              element.clearanceStatus !== APPROVE_NOTATION ||
                              !element.isEditable
                            }
                            className={
                              element.clearanceStatus !== APPROVE_NOTATION ||
                              !element.isEditable
                                ? "attachment-disabled add-attachment"
                                : "attachment-enabled add-attachment"
                            }
                          >
                            {element.attachmentLabel}
                          </Button>
                        )}
                      </Upload>
                    </div>
                  </div>
                  <div>
                    <TextArea
                      showCount
                      style={{ height: 80, resize: "none" }}
                      onChange={(event) => remarksChangeHandler(event, index)}
                      placeholder={element.remarksLabel}
                      disabled={
                        element.clearanceStatus !== APPROVE_NOTATION ||
                        !element.isEditable
                      }
                      value={element.remarksValue}
                      maxLength={TEXTAREA_MAX_LENGTH}
                      data-testid="remarks"
                      className={
                        element.clearanceStatus !== APPROVE_NOTATION ||
                        !element.isEditable
                          ? "control-border"
                          : "control-border placeholder"
                      }
                    />
                  </div>
                  <div>
                    <Alert
                      message={element.tooltipInfo}
                      type="info"
                      showIcon
                      className="styleAlert alertText"
                      data-testid="info"
                      icon={<img src={info} />}
                    />
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </Spin>
      </div>
      {cardDetails?.length > 0 && (
        <Card className="card-footer-section">
          <Row className="card-footer-row">
            <Space size={16}>
              <Button
                onClick={backHandler}
                data-testid="back"
                className="back-btn"
              >
                {BACK}
              </Button>
              <Button
                type="primary"
                onClick={submitHandler}
                disabled={configuration.isEnabled}
                data-testid="submit"
                className="submit-btn"
              >
                {SUBMIT}
              </Button>
            </Space>
          </Row>
        </Card>
      )}
    </>
  );
};

export default Clearance;
