import axios from "axios";
import { LOGGEDINUSERDETAILS, USERJWT, baseURL } from "../../constants";
import {
  getLoggedInUserData,
  removeFromLocalStorage,
} from "../../components/utils/util";
//axios.defaults.withCredentials = true;
const defaultOptions = {
  baseURL: baseURL,
};

const cancelToken = axios.CancelToken;
const source = cancelToken.source();
const controller = new AbortController();

let instance = axios.create(defaultOptions);

// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
  let userToken = getLoggedInUserData();
  config.headers.Authorization = userToken
    ? `Bearer ${userToken?.apiToken}`
    : ``;
  config.headers.isEncrypted = true;
  config.cancelToken = source.token;
  config.signal = controller.signal;
  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      removeFromLocalStorage(USERJWT);
      removeFromLocalStorage(LOGGEDINUSERDETAILS);
      window.location.href = "/web";
    }
    return Promise.reject(error.response.data);
  }
);

export const DEX_WEB_URL = process.env.REACT_APP_DEX_WEB_DEV_URL;
export const DIS_URL = process.env.REACT_APP_DIS_URL;
export const PROD_DIS_URL = process.env.REACT_APP_PROD_URL;
export const RESUME_DOWNLOAD_URL = process.env.REACT_APP_DOWNLOAD_RESUME_URL;
export const DEX_URL = process.env.REACT_APP_DEX_URL;

export default instance;
