import React from "react";
import "../my_space/CommonForm.css";
import { Form, Input, Radio, Select, InputNumber } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const EditPopUp = () => {
  return (
    <div className="form-container">
      <form className="main_otr-sec">
        <Form.Item>
          <Select placeholder="Type">
            <Select.Option value="demo">RADIO</Select.Option>
            <Select.Option value="demo">DEMO</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Input placeholder="Question" />
        </Form.Item>
        <Form.Item>
          <InputNumber placeholder="Question ID" />
        </Form.Item>
        <Form.Item label="Is Requird">
          <Radio.Group>
            <Radio value="Yes"> Yes </Radio>
            <Radio value="No"> No </Radio>
          </Radio.Group>
        </Form.Item>
        <Form className="input_table">
          <h1>Survey Option</h1>
          <table>
            <tr>
              <th>Option ID</th>
              <th>Option</th>
              <th>Order</th>
              <th>
                <button>Add</button>
              </th>
            </tr>
            <tr>
              <td>
                <Form.Item>
                  <InputNumber placeholder="Option ID" />
                </Form.Item>
              </td>
              <td>
                <Form.Item>
                  <Input placeholder="Option" />
                </Form.Item>
              </td>
              <td>
                <Form.Item>
                  <InputNumber placeholder="Order" />
                </Form.Item>
              </td>
              <td>
                <DeleteOutlined />
              </td>
            </tr>
          </table>
        </Form>
      </form>
    </div>
  );
};

export default EditPopUp;
