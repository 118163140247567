import * as React from "react"
const EbookRightArrow = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  fill="none"
  {...props}
>
  <path
    fill="#0642B5"
    fillRule="evenodd"
    d="m9.44 4 7.29 7.29a1.002 1.002 0 0 1 0 1.42l-7.36 7.36a1 1 0 0 1-1.42 0H8a1 1 0 0 1 0-1.41L14.61 12 8 5.41A1 1 0 0 1 8 4a1 1 0 0 1 1.44 0Z"
    clipRule="evenodd"
  />
</svg>
)
export default EbookRightArrow
