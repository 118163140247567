import React from "react";
import "./AchievementsCard.css";
import TrophyIcon from "../../../themes/assets/images/performance/achievementsTrophy.png";
import { ACHIEVEMENTS, CONTINIOUS_PERFORMANCE } from "../../../constants";

const AchievementsCard = (props) => {
  const { description = "" } = props;

  const onClickAchievements = () => {
    window.open(
      "https://hcm44.sapsf.com/xi/ui/talentuxr/pages/index.xhtml?bplte_company=altimetrik/achievements/#/achievements",
      "_blank"
    );
  };

  return (
    <div className="achievements-outer-container">
      <div className="achivement-inside-container">
        <img src={TrophyIcon} alt="" className="trophy-img"></img>
        <div className="achievements-title">{ACHIEVEMENTS}</div>
      </div>
      <div className="achivement-inside-container">
        <div className="achivements-description">{`${description}`} </div>
        <span
          style={{ whiteSpace: "pre-wrap" }}
          onClick={onClickAchievements}
        >{` ${CONTINIOUS_PERFORMANCE}`}</span>
      </div>
    </div>
  );
};
export default AchievementsCard;
