import React from "react";
import "./WaitingResponseCard.css";
import EmptyReview from "../../../themes/assets/images/empty-review.png";

const EmptySubmittedReview = () => {
  return (
    <div className="waitingResponseCard">
      <img src={EmptyReview} alt="" className="waitingResImage" />
      <div className="subText">No review comments yet</div>
    </div>
  );
};

export default EmptySubmittedReview;
