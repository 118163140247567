import React, { useEffect, useState } from "react";
import { DatePicker, Input } from "antd";
import "./CustomCalendar.css";
import moment from "moment";

interface CalenderInterface {
  referenceDates: string[];
  setSelectedFilters: (value: any) => {};
  selectedFilters: any;
  visible: boolean;
}

const CalendarWithReferenceDates = ({
  referenceDates,
  selectedFilters,
  setSelectedFilters,
  visible,
}: CalenderInterface) => {
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const [inputValue, setInputValue] = useState<string>(
    selectedFilters?.["employeeLWD"] || ""
  );

  useEffect(() => {
    if (!selectedFilters?.["employeeLWD"]) {
      setInputValue("");
      setSelectedDates([]);
      setSelectedDate(null);
    }
  }, [selectedFilters]);

  const isToday = (date: any) => {
    return moment().format("DD/MM/YYYY") == date;
  };

  const handleDateChange = (date: any) => {
    if (date) {
      const formattedDate = date.format("DD/MM/YYYY");
      if (selectedDates.includes(formattedDate)) {
        // Date already selected, remove it
        const updatedDates = selectedDates.filter((d) => d !== formattedDate);
        setSelectedDates(updatedDates);

        const updatedInput = updatedDates.join(", ");
        setInputValue(updatedInput);
        setSelectedFilters({
          ...selectedFilters,
          employeeLWD: updatedInput,
        });
      } else {
        // Date not selected, add it
        const updatedDates = [...selectedDates, formattedDate];
        setSelectedDates(updatedDates);

        const updatedInput = updatedDates.join(", ");
        setInputValue(updatedInput);
        setSelectedFilters({
          ...selectedFilters,
          employeeLWD: updatedInput,
        });
      }
    }
  };

  const disabledDate = (current: any) => {
    if (current) {
      const formattedDate = current.format("DD/MM/YYYY");
      return !referenceDates.includes(formattedDate);
    }
    return false;
  };

  return (
    <div>
      <Input
        className="ant-input-disabled"
        value={inputValue}
        placeholder="Selected Dates (DD/MM/YYYY, DD/MM/YYYY)"
        onChange={(e) => setInputValue(e.target.value)}
        disabled
      />
      <div className="calendar-container">
        <DatePicker
          className="datepicker"
          placement="bottomLeft"
          onChange={handleDateChange}
          disabledDate={disabledDate}
          format="DD/MM/YYYY"
          value={selectedDate}
          open={visible}
          style={{ visibility: "hidden" }}
          dateRender={(current) => {
            const formattedDate = current.format("DD/MM/YYYY");
            const isTodayDate = isToday(formattedDate);
            return (
              <div
                className={`${
                  selectedDates.includes(formattedDate)
                    ? "container-selected"
                    : "container-not-selected"
                }
                  ${
                    isTodayDate
                      ? "ant-picker-cell ant-picker-cell-in-view ant-picker-cell-today"
                      : ""
                  }`}
              >
                <div
                  className={`${
                    selectedDates.includes(formattedDate)
                      ? "custom-selected-date"
                      : "default-color"
                  } ant-picker-cell-inner`}
                >
                  {current.date()}
                </div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default CalendarWithReferenceDates;
