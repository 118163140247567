import { createSlice } from "@reduxjs/toolkit";
import {
  bookmarkAction,
  bookmarkDelete,
  deleteComments,
  editCommentAction,
  getCardContentDetail,
  getComments,
  getDetailedReaction,
  getReactionCount,
  likeComment,
  postComments,
  reactionAction,
  reactionDelete,
  saveSuervey,
  unlikeComment,
} from "../actions/card_details";

type cleranceDetailState = {
  card_detail: any;
  loading: boolean;
  error: null;
  hasMore: boolean;
  loadMore: boolean;
  minorError: null;
  reactionDetails: Array<Object>;
  detailedReactionLoading: boolean;
  reactionCount: Array<Object>;
  comments: Array<any>;
  commentsLoading: Boolean;
  commentsError: Boolean;
  noData: Boolean;
  activeTab: String;
  thresholdLike: boolean;
  thresholdComment: boolean;
  intervalLike: boolean;
  intervalComment: boolean;
  pointsLike: any;
  pointsComment: any;
};

const initialDetailState: cleranceDetailState = {
  card_detail: {},
  loading: false,
  error: null,
  hasMore: true,
  loadMore: true,
  minorError: null,
  reactionDetails: [],
  detailedReactionLoading: false,
  reactionCount: [],
  comments: [],
  commentsLoading: false,
  commentsError: false,
  noData: false,
  activeTab: "",
  thresholdLike: false,
  intervalLike: false,
  thresholdComment: false,
  intervalComment: false,
  pointsLike: "",
  pointsComment: "",
};

export const card_details: any = createSlice({
  name: "card_details",
  initialState: initialDetailState,
  reducers: {
    cardData: (state, action) => {
      state.card_detail = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action?.payload;
    },
    isCommentShow: (state, action) => {
      const { index } = action.payload;
      const isComment = state.card_detail["details"][index];
      if (isComment && isComment["isComment"]) {
        state.card_detail["details"][index]["isComment"] = !isComment;
      } else {
        state.card_detail["details"][index]["isComment"] = true;
      }
    },
    showComment: (state, action) => {
      const { index } = action.payload;
      state.card_detail["details"][index]["isComment"] = true;
    },
    bookmarkAdd: (state, action) => {
      const { index } = action.payload;
      const isbookMark =
        state.card_detail["details"][index]["bookMarkIndicator"];
      state.card_detail["details"][index]["bookMarkIndicator"] = !isbookMark;
    },
    likesAction: (state, action) => {
      const { index } = action.payload;
      const isLike = state.card_detail["details"][index]["likesCount"];
      state.card_detail["details"][index]["likesCount"] = isLike + 1;
    },
    commentAction: (state, action) => {
      const { index } = action.payload;
      const isComment = state.card_detail["details"][index]["commentsCount"];
      state.card_detail["details"][index]["commentsCount"] = isComment + 1;
    },
    surveySelection: (state, action) => {
      const { response, index } = action.payload;
      state.card_detail["details"][index]["response"] = response;
    },
    addReply: (state, action) => {
      const { response, index } = action.payload;
      if (state?.comments[index]?.children?.length > 0) {
        state.comments[index].children.unshift(response);
      } else {
        state.comments[index].children = [response];
      }
      state.comments[index].commentsCount += 1;
    },
  reactionThresholdLike: (state, action) => {
    state.thresholdLike = false;
    state.intervalLike = false;
    },
    reactionThresholdComments: (state, action) => {
      state.thresholdComment = false;
      state.intervalComment = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCardContentDetail.pending, (state: any, action: any) => {
      state.loading = true;
      state.error = null;
      const { arg } = action?.meta;
      const { isScroll = false } = arg;
      if (!isScroll) {
        state.card_detail = [];
        state.noData = false;
      }
    });
    builder.addCase(getCardContentDetail.fulfilled, (state: any, action) => {
      state.loading = false;
      const { isPagination, ...data } = action?.payload;

      if (data.details.length === 0) {
        state.noData = true;
      }

      if (!isPagination) {
        state.card_detail = data;
      } else {
        const extract_card_detail = state.card_detail.details;
        state.card_detail.details = [...extract_card_detail, ...data.details];
      }
    });
    builder.addCase(getCardContentDetail.rejected, (state: any, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(reactionAction.pending, (state) => {
      state.loading = true;
      state.minorError = null;
    });
    builder.addCase(reactionAction.fulfilled, (state: any, action) => {
      state.loading = false;
      const {thresholdPopup, intervalPopup, points} = action.payload;
      state.thresholdLike = thresholdPopup;
      state.intervalLike = intervalPopup;
      state.pointsLike = points;
    });
    builder.addCase(reactionAction.rejected, (state: any, action) => {
      state.loading = false;
      state.minorError = action.payload;
    });
    builder.addCase(reactionDelete.pending, (state) => {
      state.minorError = null;
      state.loading = true;
    });
    builder.addCase(reactionDelete.fulfilled, (state: any, action) => {
      state.loading = false;
    });
    builder.addCase(reactionDelete.rejected, (state: any, action) => {
      state.loading = false;
      state.minorError = action.payload;
    });
    builder.addCase(bookmarkAction.pending, (state) => {
      state.loading = true;
      state.minorError = null;
    });
    builder.addCase(bookmarkAction.fulfilled, (state: any, action) => {
      state.loading = false;
      const { index } = action.payload;
      if (index !== undefined && index !== null) {
        state.card_detail.details[index].bookMarkIndicator = true;
      }
    });
    builder.addCase(bookmarkAction.rejected, (state: any, action) => {
      state.loading = false;
      state.minorError = action.payload;
    });
    builder.addCase(bookmarkDelete.pending, (state) => {
      state.minorError = null;
      state.loading = true;
    });
    builder.addCase(bookmarkDelete.fulfilled, (state: any, action) => {
      state.loading = false;
      const { isRemoveBookmark, contentId, index } = action.payload;
      if (isRemoveBookmark) {
        const newdata = state.card_detail.details.filter(
          (item: any) => item.contentId != contentId
        );
        state.card_detail.details = newdata;
      } else if (index !== undefined && index !== null) {
        state.card_detail.details[index].bookMarkIndicator = false;
      }
    });
    builder.addCase(bookmarkDelete.rejected, (state: any, action) => {
      state.loading = false;
      state.minorError = action.payload;
    });
    builder.addCase(getDetailedReaction.pending, (state) => {
      state.minorError = null;
      state.detailedReactionLoading = true;
    });
    builder.addCase(getDetailedReaction.fulfilled, (state: any, action) => {
      state.detailedReactionLoading = false;
      state.reactionDetails = action.payload;
    });
    builder.addCase(getDetailedReaction.rejected, (state: any, action) => {
      state.detailedReactionLoading = false;
      state.minorError = action.payload;
    });
    builder.addCase(saveSuervey.pending, (state) => {
      state.minorError = null;
      state.detailedReactionLoading = true;
    });
    builder.addCase(saveSuervey.fulfilled, (state: any, action) => {
      state.detailedReactionLoading = false;
      const { newresult, index } = action.payload;
      state.card_detail["details"][index] = newresult;
    });
    builder.addCase(saveSuervey.rejected, (state: any, action) => {
      state.detailedReactionLoading = false;
      state.minorError = action.payload;
    });
    builder.addCase(getReactionCount.fulfilled, (state: any, action) => {
      state.detailedReactionLoading = false;
      state.reactionCount = action.payload;
    });
    builder.addCase(getReactionCount.rejected, (state: any, action) => {
      state.minorError = action.payload;
      state.reactionCount = [];
    });
    builder.addCase(getComments.pending, (state) => {
      state.commentsLoading = true;
    });
    builder.addCase(getComments.fulfilled, (state: any, action) => {
      state.commentsLoading = false;
      const { index, result } = action.payload;
      if (index !== undefined) {
        state.comments[index].children = result;
      } else state.comments = result;
    });
    builder.addCase(getComments.rejected, (state: any, action) => {
      state.commentsLoading = false;
      state.commentsError = action.payload;
    });
    builder.addCase(postComments.pending, (state) => {
      state.minorError = null;
      state.commentsLoading = true;
    });
    builder.addCase(postComments.fulfilled, (state: any, action) => {
      state.commentsLoading = false;
      const {thresholdPopup, intervalPopup, points} = action.payload;
      if (action?.payload?.level == 1) {
        state.comments = [action?.payload, ...state.comments];
      }
      state.thresholdComment = thresholdPopup;
      state.intervalComment = intervalPopup;
      state.pointsComment = points;
      //add comment count
    });
    builder.addCase(postComments.rejected, (state: any, action) => {
      state.commentsLoading = false;
      state.minorError = action.payload;
    });
    builder.addCase(likeComment.pending, (state) => {
      state.minorError = null;
      state.commentsLoading = true;
    });
    builder.addCase(likeComment.fulfilled, (state: any, action) => {
      state.commentsLoading = false;
      const { index } = action.payload;
      if (
        action?.payload?.parentIndex !== "null" &&
        action?.payload?.parentIndex !== "" &&
        action?.payload?.parentIndex !== undefined &&
        action?.payload?.parentIndex !== false
      ) {
        const likeCount =
          state.comments[action?.payload?.parentIndex].children[index]
            .likesCount;
        state.comments[action?.payload?.parentIndex].children[
          index
        ].likesCount = likeCount + 1;
        state.comments[action?.payload?.parentIndex].children[
          index
        ].likeIndicator = true;
      } else {
        const likeCount = state.comments[index].likesCount;
        state.comments[index].likesCount = likeCount + 1;
        state.comments[index].likeIndicator = true;
      }
    });
    builder.addCase(likeComment.rejected, (state: any, action) => {
      state.commentsLoading = false;
      state.minorError = action.payload;
    });
    builder.addCase(unlikeComment.pending, (state) => {
      state.minorError = null;
      state.commentsLoading = true;
    });
    builder.addCase(unlikeComment.fulfilled, (state: any, action) => {
      state.commentsLoading = false;
      const { index } = action.payload;
      if (
        action?.payload?.parentIndex !== "null" &&
        action?.payload?.parentIndex !== "" &&
        action?.payload?.parentIndex !== undefined &&
        action?.payload?.parentIndex !== false
      ) {
        const likeCount =
          state.comments[action?.payload?.parentIndex].children[index]
            .likesCount;
        state.comments[action?.payload?.parentIndex].children[
          index
        ].likesCount = likeCount - 1;
        state.comments[action?.payload?.parentIndex].children[
          index
        ].likeIndicator = false;
      } else {
        const likeCount = state.comments[index].likesCount;
        state.comments[index].likesCount = likeCount - 1;
        state.comments[index].likeIndicator = false;
      }
    });
    builder.addCase(unlikeComment.rejected, (state: any, action) => {
      state.commentsLoading = false;
      state.minorError = action.payload;
    });
    builder.addCase(deleteComments.pending, (state) => {
      state.minorError = null;
      state.commentsLoading = true;
    });
    builder.addCase(deleteComments.fulfilled, (state: any, action) => {
      state.commentsLoading = false;
      const { index, parentIndex } = action.payload;
      let newData: any;
      if (
        parentIndex !== "null" &&
        parentIndex !== "" &&
        parentIndex !== undefined &&
        parentIndex !== false
      ) {
        newData = state.comments[parentIndex].children.filter(
          (item: any, i: any) => {
            return index !== i;
          }
        );
        state.comments[parentIndex].children = newData;
        state.comments[parentIndex].commentsCount -= 1;
      } else {
        newData = state.comments.filter((item: any, i: any) => {
          return index !== i;
        });
        state.comments = newData;
      }

      //substract comment count
    });
    builder.addCase(deleteComments.rejected, (state: any, action) => {
      state.commentsLoading = false;
      state.minorError = action.payload;
    });
    builder.addCase(editCommentAction.pending, (state) => {
      state.minorError = null;
      state.commentsLoading = true;
    });
    builder.addCase(editCommentAction.fulfilled, (state: any, action) => {
      state.commentsLoading = false;
      const { index, parentIndex, result } = action.payload;
      if (
        parentIndex !== "null" &&
        parentIndex !== "" &&
        parentIndex !== undefined &&
        parentIndex !== false
      ) {
        state.comments[parentIndex].children[index] = result;
      } else {
        state.comments[index] = result;
      }
    });
    builder.addCase(editCommentAction.rejected, (state: any, action) => {
      state.commentsLoading = false;
      state.minorError = action.payload;
    });
  },
});

export default card_details.reducer;

export const {
  cardData,
  isCommentShow,
  commentAction,
  bookmarkAdd,
  likesAction,
  surveySelection,
  addReply,
  showComment,
  setActiveTab,
  reactionThresholdLike,
  reactionThresholdComments
} = card_details.actions;
//export const { typeChangeAction,deductionAction,remarksChangeAction,notificationAction,beforeUploadAction,onChangeAction,onRemoveAction } = detail.actions;
