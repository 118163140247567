import "./index.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, Button, Dropdown, MenuProps, Space, notification } from "antd";
import "./index.css";
import EmployeeList from "./EmployeeList";
import PendingReview from "../ManagerView/PendingReview/index";
import { TabView, TabPanel } from "primereact/tabview";
import "primereact/resources/primereact.css";
import {
  CASCADE_GOAL,
  EMPLOYEE_LIST,
  NOTIFICATION_DURATION,
  SUBMITTED_GOAL,
  TEAMS_GOAL,
  TEAMS_GOALS_FLOW,
  WARNING,
} from "../../../constants";
import { useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import {
  notificationAction,
  setActiveTeamGoalYear,
} from "../../../redux/reducers/performance";
import ExpandMore from "../../../themes/assets/images/svg/Expand_more.svg";
import {
  AnnualYear,
  getDropDownYears,
} from "../../../redux/actions/performance";
import { ToastSuccessIcon } from "../../common/svg/svg";
import amplitude from "amplitude-js";

const TeamPerformance = () => {
  const location = useLocation();
  const { goalsTabKey = 0 } = location?.state || {};

  const [api, contextHolder] = notification?.useNotification({
    top: 0,
    maxCount: 1,
  });
  const [activeIndex, setActiveIndex] = useState(goalsTabKey);
  const navigate = useNavigate();
  const onClickCascadeGoal = () => {
    amplitude.getInstance().logEvent("Clicked Cascade Goals");
    navigate(`/web/my_work/team_performance/cascadegoals`);
  };
  let navigationIndex = Number(location?.state?.goalsTabKey);

  const { yearDropdown, configuration } =
    useAppSelector((state: any) => state.performance) || [];
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(AnnualYear({}));
  }, []);

  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
      })
    );
  };

  useEffect(() => {
    if (configuration.isShowNotification) {
      const type = configuration.alertMessage.type;
      api[type]({
        message: ``,
        description: configuration.alertMessage.message,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon: <ToastSuccessIcon />,
        className: "custom-toast-area custom-success-toast",
        onClose: onCloseHandler,
      });
    }
  }, [configuration.isShowNotification]);
  return (
    <div className="team-performanceContainer">
      {contextHolder}
      <div className="performance-header">
        <div className="left-side">
          <h5>{TEAMS_GOAL}</h5>
        </div>
        <div className="right-side">
          <Button onClick={onClickCascadeGoal}>{CASCADE_GOAL}</Button>
        </div>
      </div>
      <div className="performance-body">
        <Card style={{ width: "100%" }}>
          <TabView
            activeIndex={navigationIndex || activeIndex}
            className="manager-tabs"
            onClick={(e: any) => {
              setActiveIndex(
                e.target?.textContent === "Submitted Goals" ? 0 : 1
              );
            }}
          >
            <TabPanel header={SUBMITTED_GOAL}>
              <PendingReview
                flow={TEAMS_GOALS_FLOW}
                goalsTabKey={activeIndex}
              />
            </TabPanel>
            <TabPanel header={EMPLOYEE_LIST}>
              <EmployeeList flow={TEAMS_GOALS_FLOW} goalsTabKey={activeIndex} />
            </TabPanel>
          </TabView>
        </Card>
      </div>
    </div>
  );
};

export default TeamPerformance;
