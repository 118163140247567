import { createAsyncThunk } from "@reduxjs/toolkit";
import api from '../services/certificate';
import { getLoggedInUserData, skillArrayToobject } from "../../components/utils/util";

//save action
export const saveCertification = createAsyncThunk(
  "saveCertification",
  async (data : any = {}, { rejectWithValue }) => {
    const url = `api/v1/user-mgmt/user-profile/certificates?submitFlag=true`
    let userInfo = getLoggedInUserData();
    const formattedData = data.map((item:any)=>{
      let basic = {"name":item.name,"institution":item.institution,"description":item.description, startDate: item.startDate,
      endDate: item.endDate}
      let skills = {}
      let sfAttachment={}
      if(item.skill && item.skill.length > 0){
        skills = skillArrayToobject(item.skill)
      }
      if(item.fileContent){
        sfAttachment = {
          sfAttachment:{
          "userId": userInfo?.userId,
          "module": "EMPLOYEE_PROFILE",
          "moduleCategory": "HRIS_ATTACHMENT",
          "fileName": item.fileName,
          "fileContent":item.fileContent
          }
        }
      }  
         
      return {...basic,...skills,...sfAttachment}
    })
    const response = await api.post(url,formattedData);
    try {
      const result = response.data;      
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//read action
export const getCertifications = createAsyncThunk(
  "getCertification",
  async (data : any = {}, { rejectWithValue }) => {
    const url = `api/v1/user-mgmt/user-profile/certificates`
    const response = await api.get(url);
    try {
      const result = response.data;    
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//read skill action
export const getSkills = createAsyncThunk(
  "getSkills",
  async (data : any = {}, { rejectWithValue }) => {
    const url = `api/v1/sfactor/getPickList?pickListId=Skills`
    const response = await api.get(url);
    try {
      const result = response.data;   
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


//update action
export const updateCertifications = createAsyncThunk(
  "updateCertifications",
  async (data : any = {}, { rejectWithValue }) => {
    const url = `api/v1/user-mgmt/user-profile/certificates?submitFlag=true`
    let userInfo = getLoggedInUserData();
    const formattedData = data.map((item:any)=>{
      let basic:any = {"name":item.name,"institution":item.institution,"description":item.description,certificateId:item.certificateId}
      if(item.startDate){
        basic['startDate']= item.startDate
      }
      if(item.endDate){
        basic['endDate']= item.endDate
      }
      if(item.skill && item.skill.length > 0){
        const skills = skillArrayToobject(item.skill)
        basic = {...basic,...skills}
      }
      if(item.fileContent){
        const sfAttachment = {
          "userId": userInfo?.userId,
          "module": "EMPLOYEE_PROFILE",
          "moduleCategory": "HRIS_ATTACHMENT",
          "fileName": item.fileName,
          "fileContent":item.fileContent,
          "attachmentId":item.attachmentId
        }
        //mergeObjTwo =sfAttachment 
        basic['sfAttachment']= sfAttachment
      }     
      return basic
    })
    const response = await api.put(url,formattedData);
    try {
      const result = response.data;      
      return {status:result,data};
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//update action
export const deleteCertifications = createAsyncThunk(
  "deleteCertifications",
  async (data : any = {}, { rejectWithValue }) => {
    const url = `api/v1/user-mgmt/user-profile/certificates?certificateId=${data.certification_id}`
    const response = await api.delete(url);
    try {
      const result = response.data;      
      return {status:result,data};
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//download attachment action
export const downloadAttachment = createAsyncThunk(
  "downloadAttachment",
  async (args : any, { rejectWithValue }) => {
    const {attachmentId } = args;
    const url = `api/v1/clearance/getFile?attachmentId=${attachmentId}`    
    try {
    const response = await api.get(url);
    const result = response.data; 
    return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);