import React from "react";
import { removeFromLocalStorage } from "../../components/utils/util";
import {
  LOGGEDINUSERDETAILS,
  USERJWT,
  LOGOUT_TITLE,
  LOGOUT_CONT,
  LOGOUT_ASPIRANT,
  LOGOUT_GO_BUTTON,
  START,
} from "../../constants";
import antiLogo from "../../themes/assets/images/svg/AltiLogoBlack.svg";
import rightArrowGray from "../../themes/assets/images/svg/right-arrow-gray.svg";
import rightArrowWhite from "../../themes/assets/images/svg/right-arrow-white.svg";
import "./Unauthorized.Layout.css";
import { Link } from "react-router-dom";

function UnAuthrorizedScreen(props: any) {
  const onNavigateToLogin = async () => {
    await removeFromLocalStorage(USERJWT);
    await removeFromLocalStorage(LOGGEDINUSERDETAILS);
    window.location.href = `/${START}/`;
  };
  const [submittable, setSubmittable] = React.useState(false);

  const validateEmail = (e: any) => {
    const email = e.target.value;
    if (/@altimetrik.com\s*$/.test(email)) {
      setSubmittable(true);
    } else {
      setSubmittable(false);
    }
  };
  return (
    <div className="landingpage_login">
      <div className="login_card">
        <img src={antiLogo} alt="ant-logo" />
        <div className="head-sec">
          <h1>{LOGOUT_TITLE}</h1>
          <p>{LOGOUT_CONT}</p>
        </div>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="xyz@altimetrik.com"
          onChange={validateEmail}
        />
        <div className="btn-login-sec">
          <Link className="btn-link" to={`/${START}/logout`}>
            {LOGOUT_ASPIRANT}
          </Link>
          <button
            className="go-btn"
            onClick={() => onNavigateToLogin()}
            disabled={!submittable}
          >
            {LOGOUT_GO_BUTTON}
            <img
              className="grayright"
              src={rightArrowGray}
              alt="rightArrowGray"
            />
            <img
              className="whiteright"
              src={rightArrowWhite}
              alt="rightArrowWhite"
            />
          </button>
        </div>
        <p className="copyright">
          By continuing, you agree to the{" "}
          <Link to={`/${START}/logout`}>Terms of Services</Link> and{" "}
          <Link to={`/${START}/logout`}>Privacy Policy</Link>
        </p>
      </div>
    </div>
  );
}

export default UnAuthrorizedScreen;
