import React, { useState, useEffect } from "react";
import "./HolidayCalendar.css";
import holiday_details from "./HolidaCalendarTemplate";
import { getLoggedInUserData } from "../utils/util";
import { Dropdown, Button, Select } from "antd";
import { formatHolidayCalendarDate, formatHolidayCalendarMonth } from "../utils/util";
import { SvgDownIcon } from "../common/svg/SvgDownIcon";
import { Link } from "react-router-dom";

export const CalendarCard = ({ data }) => {
  return (
    <div
      className={
        data?.fixed ? "calendar-card" : "calendar-card calendar-card-fixed"
      }
    >
      <div className="date-container">
        <div
          className={data?.fixed ? "month-text" : "month-text month-text-fixed"}
        >
          {data?.month}
        </div>
        <div
          className={data?.fixed ? "date-text" : "date-text  date-text-fixed"}
        >
          {data?.date}
        </div>
      </div>
      <div className="holiday-details-container">
        <div
          className={
            data?.fixed ? "holiday-title" : "holiday-title holiday-title-fixed "
          }
        >
          {data?.title}
        </div>
        <div className={data?.fixed ? "day-text" : "day-text day-text-fixed"}>
          {data?.day}
        </div>
      </div>
      <div className="img-container">
        <img src={data?.banner} className="holiday-img" />
      </div>
    </div>
  );
};
export const HolidayCalendarCard = ({ data }) => {
  const [fixed, setFixed] = useState(true);
  useEffect(() => {
    if (data?.holidayClass === "Fixed") {
      setFixed(true);
    }
  });
  const holidayDate = formatHolidayCalendarDate(data?.date);
  const holidayMonth = formatHolidayCalendarMonth(data?.date);
  const holidayImageLoading = data?.loading;

  return (
    <div
      className={
        fixed ? "calendar-card" : "calendar-card calendar-card-fixed"
      }
    >
      <div className="date-container">
        <div
          className={fixed ? "month-text" : "month-text month-text-fixed"}
        >
          {holidayMonth}
        </div>
        <div
          className={fixed ? "date-text" : "date-text  date-text-fixed"}
        >
          {holidayDate}
        </div>
      </div>
      <div className="holiday-details-container">
        <div
          className={
            fixed ? "holiday-title" : "holiday-title holiday-title-fixed "
          }
        >
          {data?.name}
        </div>
        <div className={fixed ? "day-text" : "day-text day-text-fixed"}>
          {data?.day}
        </div>
      </div>
      <div className="img-container">
        <img src={data?.referenceUrl?.url} className="holiday-img" />
      </div>
    </div>
  );
};

const HolidayCalendar = () => {
  const getUserCountry = () => {
    return getLoggedInUserData()?.userGroups?.includes("COUNTRY_INDIA")
      ? "INDIA"
      : getLoggedInUserData()?.userGroups?.includes("COUNTRY_MALAYSIA")
        ? "INDIA"
        : getLoggedInUserData()?.userGroups?.includes("COUNTRY_JAPAN")
          ? "INDIA"
          : getLoggedInUserData()?.userGroups?.includes("COUNTRY_USA")
            ? "USA"
            : getLoggedInUserData()?.userGroups?.includes("COUNTRY_MEXICO")
              ? "MEXICO"
              : getLoggedInUserData()?.userGroups?.includes("COUNTRY_SINGAPORE")
                ? "SINGAPORE"
                : getLoggedInUserData()?.userGroups?.includes("COUNTRY_POLAND")
                  ? "POLAND"
                  : getLoggedInUserData()?.userGroups?.includes("COUNTRY_CANADA")
                    ? "CANADA"
                    : getLoggedInUserData()?.userGroups?.includes("COUNTRY_COLOMBIA")
                      ? "COLOMBIA"
                      : "URUGUAY";
  };
  const [location, setLocation] = useState(getUserCountry());
  const getHolidayGetails = () => {
    if (location !== "" && location !== "Location") {
      return holiday_details?.[location];
    }

    const userGrps = getLoggedInUserData()?.userGroups;
    if (
      userGrps?.includes("COUNTRY_INDIA") ||
      userGrps?.includes("COUNTRY_JAPAN") ||
      userGrps?.includes("COUNTRY_MALAYSIA")
    )
      return holiday_details?.INDIA;
    else if (userGrps?.includes("COUNTRY_URUGUAY"))
      return holiday_details?.URUGUAY;
    else if (userGrps?.includes("COUNTRY_MEXICO"))
      return holiday_details?.MEXICO;
    else if (userGrps?.includes("COUNTRY_SINGAPORE"))
      return holiday_details?.SINGAPORE;
    else if (userGrps?.includes("COUNTRY_POLAND"))
      return holiday_details?.POLAND;
    else if (userGrps?.includes("COUNTRY_CANADA"))
      return holiday_details?.CANADA;
    else if (userGrps?.includes("COUNTRY_COLOMBIA"))
      return holiday_details?.COLOMBIA;
    else return holiday_details?.USA;
  };

  const handleChange = (e) => {
    setLocation(e);
  };

  return (
    <div className="holiday-calender-container">
      <div className="header-text">
        <div className="calendar-section-leftside">       
        <div className="title-calendar">Calendar</div>
        <Select
          className="holiday-calendar-dropdown"
          onChange={handleChange}
          placeholder="Location"
          value={location}
          suffixIcon={<SvgDownIcon />}
          options={[
            { value: "USA", label: <span>USA</span> },
            { value: "INDIA", label: <span>India</span> },
            { value: "URUGUAY", label: <span>Uruguay</span> },
            { value: "MEXICO", label: <span>Mexico</span> },
            { value: "SINGAPORE", label: <span>Singapore</span> },
            { value: "POLAND", label: <span>Poland</span> },
            { value: "CANADA", label: <span>Canada</span> },
            { value: "COLOMBIA", label: <span>Colombia</span> },
          ]}
        />
        </div>
        <Link to='/web/holiday_calendar' className="holiday-calendar-link">View All</Link>
      </div>
      <div className="cards-container">
        {getHolidayGetails()?.map((item) => {
          return <CalendarCard data={item} key={item?.id} />;
        })}
      </div>
    </div>
  );
};

export default HolidayCalendar;
