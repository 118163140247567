import React from "react";
import { Button } from "antd";
import n from "../navigation/Route.Names";
import AaccessTime from "../../themes/assets/images/svg/front/access_time.svg";
import EditIcon from "../../themes/assets/images/performance/svg/EditIcon.svg";
import BusinessMilestone from "../../themes/assets/images/svg/BusinessMilestone.svg";
import "./Milestones.css";
import { useNavigate } from "react-router-dom";
import { PREVIEW_GOAL_HEADINGS, PREVIEW_GOAL_EDIT_BTN } from "../../constants";
import { updateMilestoneForm } from "../../redux/reducers/performance";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { dateObjectFormat } from "../utils/util";
import { DownCircleFilled } from "@ant-design/icons";

// Under preview: milestone component
const Milestones = (props: any) => {
  const { isEdit = true, milestones, questionDTO, milestoneHandler, milestoneImage } = props;
  const { question, questionProperties } = questionDTO || {};
  const milestoneData = milestones;
  const milestoneIcons = milestoneImage;

  return (
    <div
      className={
        isEdit
          ? "milestone-section"
          : "milestone-section milestone-section-preview"
      }
    >
      <div className="preview-addgoal-title-btn-main">
        <div>
          <p className="preview-addgoal-title">
            {question}
            <sup className="req-sup">*</sup>
          </p>
          <p>{questionProperties?.fileProperties?.placeHolder1 || ""}</p>
        </div>
        {isEdit && (
          <Button
            className="preview-addgoal-btn"
            onClick={() => {
              milestoneHandler();
            }}
          >
            <img src={EditIcon} alt="" className="edit-icon" />
            <span className="edit-btn-text">{PREVIEW_GOAL_EDIT_BTN}</span>
          </Button>
        )}
      </div>

      {milestones?.length ? (
        milestones.map((item: any, index: any) => {
          return (
            <div className="inner-table-milestone">
              <p className="preview-milestones">
                {item?.milestoneIconUrl ? (<img
                  src={item?.milestoneIconUrl || AaccessTime}
                  alt="AaccessTime"
                  style={{ marginRight: "5px" }}
                />):(
                  <img
                  src={milestoneIcons?.[index] || AaccessTime}
                  alt="AaccessTime"
                  style={{ marginRight: "5px" }}
                />
                )}
                {PREVIEW_GOAL_HEADINGS} {index + 1}
              </p>
              <div className="preview-milestones-name">
                {item.MILESTONE_QUESTION_1 || item.description}
              </div>
              <div className="preview-milestones-dates">
                <span className="preview-milestones-target">
                  Target date :{" "}
                  {item.MILESTONE_QUESTION_2
                    ? dateObjectFormat(item.MILESTONE_QUESTION_2)
                    : item.formattedDueDate}
                </span>{" "}
                <span className="preview-milestones-border"></span>
                <span className="preview-milestones-actual">
                  Actual date :{" "}
                  {item.MILESTONE_QUESTION_3
                    ? dateObjectFormat(item.MILESTONE_QUESTION_3)
                    : item?.formattedActualDate}
                </span>
              </div>
            </div>
          );
        })
      ) : (
        <div className="empty-milestone">
          <img src={BusinessMilestone} alt="" />
          <span className="no-milestone-caption">
            {questionProperties?.fileProperties?.placeHolder2 || ""}
          </span>
        </div>
      )}
    </div>
  );
};

export default Milestones;
