import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type goalsState = {
  goals: any;
  smartGoals: any;
  activeSmartGoal: any;
};
const createData = () => {
  let data: any = [];
  for (let i = 1; i <= 4; i++) {
    data.push({
      key: i,
      category: "Bussiness Category",
      name: {
        title:
          "Backlog Visibility & Readin Backlog Visibility Backlog Visibility ",
        subtitle: "Achieve high sprint velocity and on-time product releases",
      },
      start_date: "10/01/2023",
      due_date: "10/01/2023",
      outcomes: `Delivery Excellence & Innovation`,
      weightage: `${i} 25%`,
      metrics: "1. 90 to 100% accurate loading of engineering team",
      status: {
        status:
          i == 1
            ? "behind"
            : i == 2
            ? "completed"
            : i == 4
            ? "Draft"
            : "on-track",
        text:
          i == 1
            ? "Behind"
            : i == 2
            ? "Completed"
            : i == 4
            ? "Draft"
            : "On Track",
      },
      subtitle: "",
    });
  }
  return data;
};

const initialGoalsState: goalsState = {
  goals: createData(),
  smartGoals: {
    business: [
      {
        key: 1,
        category: "Bussiness Category",
        name: {
          title: "New Business Growth",
          subtitle:
            "Supporting RFP's by providing size and effort estimations for most of the modules or for the entire Proposal",
        },
        start_date: "10/01/2022",
        due_date: "12/31/2022",
        outcomes: `New Business Growth`,
        weightage: `5%`,
        metrics:
          "1. % of deviation accepted in the estimation numbers will be measured by the respective TL",
        status: {
          status: "on-track",
          text: "On Track",
        },
        subtitle: "",
      },
      {
        key: 2,
        category: "Bussiness Category",
        name: {
          title: "Expand Current Account",
          subtitle: `1. Identifying the current challenges and able to provide the solutions along with the proper data\n2. Provide suggestion to upgrade the tech stack by highlighting the benefits.`,
        },
        start_date: "10/01/2022",
        due_date: "12/31/2022",
        outcomes: `Expand Current Account`,
        weightage: `5%`,
        metrics:
          "1. Business Growth measurements to be updated by respective TL",
        status: {
          status: "Completed",
          text: "Completed",
        },
        subtitle: "",
      },
    ],
    technology: [
      {
        key: 5,
        category: "Technology Category",
        name: {
          title: "Delivery Excellence and Innovation",
          subtitle:
            "Responsible for On Time and Quality Delivery\n1. Communicate effectively with the team, participate in scrum meetings, design reviews\n2. Developing configurable, maintainable and reusable code\n3. Transform from Intermediate level to expert level in Agile (Scrum) environment.\n4. Understanding the current challenges and contributing Innovative ideas as solutions\n5. Participate in any one of the following Ideathon/DCULT/Hackathon/Digital Disruptors initiatives",
        },
        start_date: "10/01/2022",
        due_date: "12/31/2022",
        outcomes: `Delivery Excellence and Innovation`,
        weightage: `75%`,
        metrics:
          "Zero escalation and not more than certain number(To be defined by individual Manager) of bugs to be found in production.\na. Attending at least one Think Thursday session every quarter.\nb. Participate in at least two of these every half/one year - Ideathon/DCULT/Hackathon/Digital Disruptors initiatives",
        status: {
          status: "behind",
          text: "Behind",
        },
        subtitle: "",
      },
    ],
    people: [
      {
        key: 9,
        category: "People Category",
        name: {
          title: "Talent Acquisition",
          subtitle: "Being part of NCG recruitments and Playground drives",
        },
        start_date: "10/01/2023",
        due_date: "10/01/2023",
        outcomes: `Talent Acquisition`,
        weightage: `5%`,
        metrics: "1. Measured based on their availability for the events",
        status: {
          status: "notstarted",
          text: "Not Started",
        },
        subtitle: "",
      },
      {
        key: 10,
        category: "People Category",
        name: {
          title: "Talent Transformation and Engagement",
          subtitle:
            "1. Collaboration/ Leading Organizational Initiatives\n2. Contribute to people development initiatives\n3. Upgrade technology and domain skills through external certifications\n4. Continuous upgradation (Cross skill/ Up skill) in the respective technology stream",
        },
        start_date: "10/01/2022",
        due_date: "10/12/2022",
        outcomes: `Talent Transformation and Engagement`,
        weightage: `10%`,
        metrics:
          "1. Number of HR & TA events attended\n2. Number of external certifications in a half year.\n3. Increase in your CC index based on skill upgradation and assessments taken for the respective skill group.",
        status: {
          status: "on-track",
          text: "On Track",
        },
        subtitle: "",
      },
    ],
  },
  activeSmartGoal: {},
};

const goals: any = createSlice({
  name: "goals",
  initialState: initialGoalsState,
  reducers: {
    setGoals: (state, action: PayloadAction<number>) => {
      state.goals = [];
      console.log("setGoals reducer");
    },
    setActiveSmartGoal: (state, action) => {
      state.activeSmartGoal = action?.payload;
    },
  },
  //   this is used to make the api call
  //   extraReducers: (builder) => {
  //     builder.addCase(getGoals, (state) => {
  //       console.log("extra reducer");
  //     });
  //   },
});

export default goals.reducer;

export const { setGoals, setActiveSmartGoal } = goals.actions;
